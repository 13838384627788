import { useEffect, useState } from 'react';
import { Modal } from '../../../PreventiveMaintenance/components/Modal';
import { finishOrderServiceController } from '../../controller';
import styles from './finish-modal.module.css';
import { Repair } from '../../../../common/History/interfaces';

interface Props {
  repair: Repair | null;
  onClose: () => void;
  onFinish: (updatedRepair: Repair) => void;
}

export const FinishModal = ({ repair, onClose, onFinish }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (repair) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [repair]);

  const handleFinishOS = async () => {
    const res = await finishOrderServiceController(repair?.id_repair ?? '');
    if (res) {
      onFinish(res);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Finalizar OS">
      <div className={styles.container}>
        <h3>
          A OS: <b>{repair?.OS || ''}</b> será Finalizada
        </h3>

        <div className={styles.actions}>
          <button type="button" className={styles.cancel} onClick={onClose}>
            Cancelar
          </button>
          <button
            type="button"
            className={styles.finish}
            onClick={async () => {
              await handleFinishOS();
              onClose();
            }}
          >
            Finalizar
          </button>
        </div>
      </div>
    </Modal>
  );
};
