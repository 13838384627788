import { AnswerQuotation } from '../../../../@types/interface';
import api from '../../../../services/api/api';
import { ChangeIsWatchingStatusInput } from './interface';

export const changeIsWatchingStatus = async ({
  id,
  watchState,
}: ChangeIsWatchingStatusInput) => {
  const { data } = await api.put<AnswerQuotation>(
    `/quotation/answer/watching/${id}?watch_state=${watchState}`,
  );

  return data;
};
