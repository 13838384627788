export const dateOffset = (localDate: Date) => {
  const dateString = localDate.toISOString();

  if (dateString.endsWith('Z')) {
    return localDate;
  }

  const localOffset = new Date().getTimezoneOffset();
  return new Date(localDate.getTime() - localOffset * 60 * 1000);
};

export const formatDate = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day <= 9 ? `0${day}` : day}/${month <= 9 ? `0${month}` : month}/${
    year <= 9 ? `0${year}` : year
  }`;
};

export const formatDateUTC = (date: Date) => {
  const day = date.getUTCDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day <= 9 ? `0${day}` : day}/${month <= 9 ? `0${month}` : month}/${
    year <= 9 ? `0${year}` : year
  }`;
};

export const formatTime = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours <= 9 ? `0${hours}` : hours}:${
    minutes <= 9 ? `0${minutes}` : minutes
  }`;
};
export const formatDateString = (date: string) => {
  if (!date) return '';
  return date.split('-').reverse().join('/');
};

export const monthsOptions = [
  { name: 'Janeiro', value: '1' },
  { name: 'Fevereiro', value: '2' },
  { name: 'Março', value: '3' },
  { name: 'Abril', value: '4' },
  { name: 'Maio', value: '5' },
  { name: 'Junho', value: '6' },
  { name: 'Julho', value: '7' },
  { name: 'Agosto', value: '8' },
  { name: 'Setembro', value: '9' },
  { name: 'Outubro', value: '10' },
  { name: 'Novembro', value: '11' },
  { name: 'Dezembro', value: '12' },
];

export const formatDateToMobile = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day <= 9 ? `0${day}` : day}/${
    month <= 9 ? `0${month}` : month
  }/${year.toString().slice(-2)}`;
};
