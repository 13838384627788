import React, { ComponentProps } from 'react';
import styles from './root.module.css';

type Props = ComponentProps<'div'>;

export const Root = ({ children, ...props }: Props) => {
  return (
    <div className={styles.container} {...props}>
      {children}
    </div>
  );
};
