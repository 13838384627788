import Initial from './Initial/Initial';
import { Container } from '../Components/Container/Container';

export const LandingPage = () => {
  return (
    <Container style={{ backgroundColor: '#FFFFFF', paddingTop: '30px' }}>
      <Initial />
    </Container>
  );
};
