import { AlertMessage } from '../../../../../../../../shared/AlertMessage';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  container: Element | DocumentFragment;
  message: string;
}

export const ErrorModal = ({ container, isOpen, message, onClose }: Props) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      container={container}
      disableScroll
      title="Menssagem de erro"
    >
      <AlertMessage.Body>{message}</AlertMessage.Body>
    </AlertMessage.Root>
  );
};
