import {
  Box,
  Button,
  Divider,
  IconButton,
  Row,
  Stack,
  Text,
} from 'native-base';
import { ArrowClockwise, CaretRight } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setBudget } from '../../../../../../features/budget/budget-slice';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import api from '../../../../../../services/api/api';
import {
  maskCpfCnpj,
  maskLicensePlate,
  maskPhone,
} from '../../../../../../services/helpers/mask';
import pixel from '../../../../../../styles/size';
import { InputControl } from '../../../../../workshop/Budget/components/InputControl';
import { CustomerFormProps, FormValues } from './interfaces';
import { formatVehicle } from '../../../../../../utils/formatters/vehicleFormat';
import { fetchVehicleDataByLicensePlateController } from '../../../../../../services/dataFetchers/VehicleDataFetcher/VehicleDataFetcher';
import { CustomerFormController } from './controller';
import { COLORS } from '../../../../../../styles/colors';
import { MainComponent } from '../../../../../../shared/MainComponent/MainComponent';
import { Header } from '../../../../../../shared/Header';
import { ProgressBar } from '../../../../../../shared/ProgressBar';
import styles from './customer-form.module.css';

export const CustomerForm = ({ handleClick, page }: CustomerFormProps) => {
  const budget = useAppSelector(state => state.budget.budget);

  const dispatch = useDispatch();
  const { control, handleSubmit, getValues, reset } = useForm<FormValues>();
  const [isLoadingVehicle, setIsLoadingVehicle] = useState(false);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);

  const customerFormController = new CustomerFormController(budget, dispatch);

  useEffect(() => {
    reset({
      document: budget.document,
      name: budget.name,
      license_plate: budget.plate,
      phone: budget.phone,
      vehicle: budget.vehicle,
    });
  }, [
    budget.document,
    budget.name,
    budget.phone,
    budget.plate,
    budget.vehicle,
    reset,
  ]);

  const onSubmit = (data: FormValues) => {
    customerFormController.submitCustomer(data, handleClick);
  };
  const handleDocument = async (document: string) => {
    setIsLoadingCustomer(true);
    try {
      await api
        .get('/users', {
          params: {
            document,
          },
        })
        .then(res => {
          const formValues = getValues();
          const apiValues = {
            ...formValues,
            document: maskCpfCnpj(res.data.document),
            name: res.data.name,
            phone: maskPhone(res.data.phone),
          };
          reset(apiValues);
          dispatch(setBudget({ ...budget, ...apiValues }));
          setIsLoadingCustomer(false);
        });
    } catch (err) {
      console.error(err);
      setIsLoadingCustomer(false);
    }
  };

  const handleVehicle = async (licensePlate: string) => {
    try {
      setIsLoadingVehicle(true);

      const vehicleResponse = await fetchVehicleDataByLicensePlateController(
        licensePlate,
      );

      if (vehicleResponse) {
        const { chassi } = vehicleResponse;
        const formValues = getValues();
        const responseValues = {
          license_plate: licensePlate,
          vehicle: formatVehicle(vehicleResponse),
        } as FormValues;
        if (chassi) {
          responseValues.chassi = chassi;
        }
        if (vehicleResponse.user) {
          // retorno com cliente
          const { document, name, phone } = vehicleResponse.user;
          if (document) {
            responseValues.document = document;
          }
          if (name) {
            responseValues.name = name;
          }
          if (phone) {
            responseValues.phone = phone;
          }
        }
        reset({
          ...formValues,
          ...responseValues,
        });
        dispatch(
          setBudget({ ...budget, ...responseValues, plate: licensePlate }),
        );
      }
      setIsLoadingVehicle(false);
    } catch (err) {
      console.error(err);
      setIsLoadingVehicle(false);
    }
  };
  const clearClientFields = () => {
    customerFormController.resetClientFields(getValues(), reset);
  };

  return (
    <MainComponent>
      <Header title="Orçamento" />
      <div className={styles.content}>
        <ProgressBar currentPage={0} pages={['Cliente', 'Orçamento']} />

        <Stack
          alignItems="start"
          w={{ base: pixel(645, 800), xl: pixel(520, 1366) }}
        >
          <Box w="100%">
            <Box my="1rem">
              <Text color={COLORS.GREEN} fontSize="1.5rem" fontWeight="600">
                Veículo
              </Text>
              <Divider bg={COLORS.GREEN} />
            </Box>
            <form>
              <InputControl
                label="Placa"
                name="license_plate"
                control={control}
                defaultValue={budget.plate}
                type="plate"
                onChange={(newValue: string) => {
                  if (newValue.length >= 7) {
                    handleVehicle(newValue.replace('-', '').trim());
                  }
                }}
                mask={maskLicensePlate}
                isLoading={isLoadingVehicle}
              />
              <InputControl
                label="Veículo"
                name="vehicle"
                control={control}
                defaultValue={budget.vehicle}
              />
              <InputControl
                label="Chassi"
                name="chassi"
                control={control}
                defaultValue={budget.chassi}
              />
              <InputControl
                label="Quilometragem"
                name="status"
                control={control}
                defaultValue={budget.status}
              />
            </form>
          </Box>
          <Box w="100%">
            <Box my="1rem">
              <Row justifyContent="space-between">
                <Text color={COLORS.GREEN} fontSize="1.5rem" fontWeight="600">
                  Cliente
                </Text>

                <Button variant="unstyled" onPress={clearClientFields}>
                  <Box flexDir="row" alignItems="center">
                    <IconButton
                      variant="unstyled"
                      onPress={clearClientFields}
                      icon={
                        <ArrowClockwise size="1.5rem" color={COLORS.GREEN} />
                      }
                    />
                    <Text color={COLORS.GREEN} textDecorationLine="underline">
                      Limpar campos
                    </Text>
                  </Box>
                </Button>
              </Row>
              <Divider bg={COLORS.GREEN} />
            </Box>

            <form>
              <InputControl
                label="CPF/CNPJ"
                name="document"
                control={control}
                defaultValue={budget.document}
                onChange={(newValue: string) => {
                  if (newValue.length >= 11) {
                    handleDocument(newValue);
                  }
                }}
                mask={maskCpfCnpj}
                isLoading={isLoadingCustomer}
              />
              <InputControl
                required
                label="Nome"
                name="name"
                control={control}
                defaultValue={budget.name}
              />
              <InputControl
                required
                label="Telefone"
                name="phone"
                control={control}
                defaultValue={budget.phone}
                mask={maskPhone}
              />
            </form>
          </Box>

          <Button
            bg={COLORS.GREEN}
            my="1.5rem"
            py=".5rem"
            w={{ base: pixel(135, 800), xl: pixel(110, 1366) }}
            borderRadius="10px"
            alignSelf="end"
            onPress={handleSubmit(onSubmit)}
          >
            <CaretRight size="1.8rem" color={COLORS.WHITE} />
          </Button>
        </Stack>
      </div>
    </MainComponent>
  );
};
