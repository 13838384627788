import { useCallback, useEffect, useState } from 'react';
import { Quotation } from '../../../../../../../@types/interface';
import { getMyQuotationsController } from '../../../controller';
import { QuotationFilterOptions } from '../../../interface';
import { useQuotation } from '../../../../../../../contexts/quotationContext';
import useSelectedQuotation from '../../../hooks/useSelectedQuotation';

const LIMIT = 80;

export const useFinishedQuotation = () => {
  const { setQuotationState } = useQuotation();
  const [page, setPage] = useState(1);

  const [searchedQuotations, setSearchedQuotations] = useState<Quotation[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  useState<Quotation | null>(null);
  const [isViewQuotationModalOpen, setIsViewQuotationModalOpen] =
    useState(false);
  const [filterType, setFilterType] = useState<QuotationFilterOptions>('code');
  const [filterValue, setFilterValue] = useState('');

  const selectedQuotation = useSelectedQuotation();

  const fetchFinishedQuotations = useCallback(
    async (pageRequest: number) => {
      setIsLoading(true);
      if (!noMoreData) {
        const res = await getMyQuotationsController({
          limit: LIMIT,
          page: pageRequest,
          option: 'finished',
        });

        if (res && res.length > 0) {
          setQuotationState(previous => {
            const newQuotations = res.filter(
              quotation =>
                !previous.finishedQuotationList.some(
                  existingQuotation =>
                    quotation.id_quotation === existingQuotation.id_quotation,
                ),
            );
            return {
              ...previous,
              finishedQuotationList: [
                ...previous.finishedQuotationList,
                ...newQuotations,
              ],
            };
          });
        } else {
          setNoMoreData(true);
        }
      }
      setIsLoading(false);
    },
    [noMoreData, setQuotationState],
  );

  const loadMoreData = useCallback(
    (previousPage = 1) => {
      if (!noMoreData) {
        fetchFinishedQuotations(previousPage);
        setPage(previousPage + 1);
      }
    },
    [fetchFinishedQuotations, noMoreData],
  );

  useEffect(() => {
    loadMoreData();
  }, [loadMoreData]);

  const handleRowClick = (quotation: Quotation) => {
    const { answers_quotation: selectedAnswers } =
      selectedQuotation.quotation || {};
    const { answers_quotation: currentAnswers } = quotation || {};

    const isDifferentQuotation =
      selectedAnswers &&
      currentAnswers &&
      selectedAnswers[0].id_answer_quotation !==
        currentAnswers[0].id_answer_quotation;

    if (!selectedQuotation.quotation || isDifferentQuotation) {
      selectedQuotation.onSelect(quotation);
    } else {
      selectedQuotation.onClear();
    }
  };

  const fetchQuotationsByFilter = useCallback(async () => {
    if (filterValue.trim().length > 0) {
      setIsSearching(true);
      const res = await getMyQuotationsController({
        option: 'finished',
        filter: filterType,
        filter_value: filterValue,
      });

      if (res) {
        setSearchedQuotations(res);
      } else {
        setSearchedQuotations([]);
      }
    } else {
      setSearchedQuotations([]);
    }
    setIsSearching(false);
  }, [filterType, filterValue]);

  const handleDateSearch = (period: string[]) => {
    // clear search state for invalid period
    if (period.length !== 2) {
      setFilterValue('');
    } else {
      const [initialDate, finalDate] = period;
      if (initialDate.trim().length > 0 && finalDate.trim().length > 0) {
        setFilterValue(`["${initialDate}", "${finalDate}"]`);
      }
    }
  };
  const handleSearch = (query: string) => {
    setFilterValue(filterType === 'plate' ? query.toUpperCase() : query);
  };

  useEffect(() => {
    if (filterValue.trim().length > 0) {
      fetchQuotationsByFilter();
    }
  }, [fetchQuotationsByFilter, filterValue]);

  const resetStates = useCallback(async () => {
    // reset search controllers
    setNoMoreData(false);
    setPage(0);
  }, []);

  return {
    page,
    isLoading,
    noMoreData,
    loadMoreData,
    handleRowClick,
    isViewQuotationModalOpen,
    setIsViewQuotationModalOpen,
    filterType,
    filterValue,
    handleSearch,
    handleDateSearch,
    searchedQuotations,
    setFilterType,
    resetStates,
    isSearching,
    // searchedQuotationAnswer,
    // fetchAnswerQuotationById,
  };
};
