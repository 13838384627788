import { Header } from '../Header';
import { RefreshModal } from '../RefreshModal';
import { Main, Container } from './_mainComponent';

interface Props {
  children: React.ReactNode;
  title?: string;
  styleContainer?: React.CSSProperties;
}

export const MainComponent = ({ children, title, styleContainer }: Props) => {
  return (
    <Container>
      <Main style={styleContainer}>
        {title && <Header title={title} />}
        {children}
      </Main>
      <RefreshModal />
    </Container>
  );
};
