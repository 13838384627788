interface Props {
  items: {
    name: string;
    quantity: number;
    unit: number;
  }[];
  discount: number;
}

export const ServiceTable = ({ items, discount }: Props) => {
  let subtotal = 0;
  const formatValue = (value: number) => {
    return value.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    });
  };
  return `
  <style>
    .table-container {
      max-width: 100%;
      overflow: auto;
    }
    .service-table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 1rem;
    }
    .service-table tr th {
      color: #4b4b4b;
    }
    .service-table thead tr {
      border-bottom: 1.5px solid #6bb56a;
    }

    .service-table thead tr th {
      font-weight: 600;
    }

    .service-table tbody tr:nth-child(even) {
      background: #e9e9e9;
    }
    .service-table tbody tr th {
      font-weight: 400;
    }

    .service-table tbody tr:last-child {
      border-bottom: 1.5px solid #6bb56a;
    }
    .item,
    .quantity,
    .unit,
    .subtotal {
      width: calc(75% / 4);
      padding: 1rem;
    }
    .product {
      width: 25%;
      padding: 1rem;
    }
    .item {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .subtotal {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .summary {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .summary p {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 0 0.5rem;
      margin-bottom: 1rem;
      font-weight: 700;
      padding-right: 2rem;
    }
    .summary .discount span:first-child {
      font-weight: 600;
    }
    .summary .discount span:last-child {
      font-weight: 400;
    }
    .summary .total {
      color: #6bb56a;
    }
  </style>

  <div class="table-container">
  <table class="service-table">
    <thead>
      <tr>
        <th class="item">Item</th>
        <th class="product">Produto/Serviço</th>
        <th class="quantity">Qtd.</th>
        <th class="unit">Unit.</th>
        <th class="subtotal">Subtotal</th>
      </tr>
    </thead>
    <tbody>
      ${items
        .map((item, index) => {
          const totalValue = item.quantity * item.unit;
          subtotal += totalValue;
          return `
          <tr>
            <th class="item">${index + 1}</th>
            <th class="product">${item.name}</th>
            <th class="quantity">${item.quantity}</th>
            <th class="unit">${formatValue(item.unit)}</th>
            <th class="subtotal">R$ ${formatValue(totalValue)}</th>
          </tr>
          `;
        })
        .join('')}
    </tbody>
  </table>
</div>

  <div class="summary">
    <p><span>Subtotal:</span> <span>R$ ${formatValue(subtotal)}</span></p>
    <p class="discount">
      <span>Desconto (R$):</span> <span>-R$ ${formatValue(discount)}</span>
    </p>
    <p>
      <span>Total:</span>
      <span class="total">R$ ${formatValue(subtotal - discount)}</span>
    </p>
  </div>

  `;
};
