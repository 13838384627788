/* eslint-disable no-alert */
import { useState } from 'react';
import { InstagramLogo, WhatsappLogo } from '@phosphor-icons/react';
import styles from './page-contact.module.css';
import callImage from '../../../../../assets/img/LandingPage/landig-page-contact-call-image.svg';
import layer from '../../../../../assets/img/LandingPage/landing-page-layer.svg';
import { Container } from '../../Components/Container/Container';
import { textOnly } from '../../../../../services/helpers/mask';
import { messageWrong } from '../Register/index';
import message from '../../../../../utils/message';
import { Input } from '../../../../../shared/Input';
import { Button } from '../../../../../shared/Button';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [messageText, setMessageText] = useState('');

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (name.length < 2) {
      return alert(`Parece que o campo nome não foi preenchido corretamente!`);
    }
    if (email.length < 6) {
      return alert(`Parece que o campo email não foi preenchido corretamente!`);
    }
    if (messageText.length <= 0) {
      return alert(
        `Parece que o campo mensagem não foi preenchido corretamente!`,
      );
    }

    fetch('https://api.sheetmonkey.io/form/w4Yoq1PGmqm7H6Z6mNjPeq', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        phone: '',
        email,
        cpf: '',
        message: messageText,
        date: 'x-sheetmonkey-current-date-time',
        ip: 'x-sheetmonkey-ip-address',
      }),
    })
      .then(res => {
        if (res.status === 200) {
          setName('');
          setEmail('');
          setMessageText('');
          return message('Entraremos em contato em breve!', 'success');
        }
        message(messageWrong(res.status), 'error');
      })
      .catch(error => {
        return message(error.message, 'error');
      });
  };
  return (
    <Container>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.containerCall}>
            <h1>Fale conosco</h1>
            <div>
              <p>
                Nossa equipe está à disposição para responder e orientar você.
              </p>
              <img src={callImage} alt="Fale conosco" />
            </div>
          </div>
          <div className={styles['right-column']}>
            <div className={styles.socialTop}>
              <Button
                handleClick={() =>
                  window.open(
                    'https://www.instagram.com/appautocenter/',
                    '_blank',
                  )
                }
                variant="register"
                style={{
                  paddingInline: '1.85rem',
                }}
              >
                <InstagramLogo size={32} color="#fff" />
                Instagram
              </Button>
              <Button
                handleClick={() =>
                  window.open('https://wa.me/5587991080605', '_blank')
                }
                variant="register"
                style={{
                  paddingInline: '1.85rem',
                }}
              >
                <WhatsappLogo size={32} color="#fff" />
                Whatsapp
              </Button>
            </div>

            <form onSubmit={onSubmit} className={styles.form}>
              <Input
                placeholder="Nome"
                name="data[name]"
                label="Nome"
                value={name}
                handleChange={e => setName(textOnly(e.target.value))}
                style={{
                  border: 'none',
                }}
              />
              <Input
                placeholder="minhaconta@email.com"
                name="data[email]"
                label="Email"
                value={email}
                handleChange={e => setEmail(e.target.value)}
                style={{
                  border: 'none',
                }}
              />

              <div>
                <h2>Mensagem</h2>
                <textarea
                  className={styles.formInputTextArea}
                  name="data[message]"
                  value={messageText}
                  onChange={e => setMessageText(e.target.value)}
                />
              </div>

              <div>
                <button type="submit" className={styles.formBtn}>
                  Enviar
                </button>
              </div>
            </form>
          </div>
          <div
            style={{ backgroundImage: `url("${layer}")` }}
            className={styles.layer}
          />
        </div>
      </div>
    </Container>
  );
};

export default Contact;
