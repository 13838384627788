import { create } from 'zustand';

interface FinishModalStore {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const useFinishModal = create<FinishModalStore>(set => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}));

export default useFinishModal;
