import { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';
// import { visitorId } from '../../../../../routes/routes';
import { BudgetProps } from '../../../../../features/budget/interfaces';
import { sendWhatsappMessage } from '../../../../../utils/sendWhatsappMessage';
import { numberOnly } from '../../../../../services/helpers/mask';
import { setBudget } from '../../../../../features/budget/budget-slice';
import {
  initialOrderServiceState,
  resetOS,
} from '../../../../../features/orderService/orderService-slice';
import { Repair } from '../../../../common/History/interfaces';
import { saveObjectLocally } from '../../../../../utils/localStorage';
import { Workshop } from '../../../../../@types/interface';
import { useAuth } from '../../../../../hooks/useAuth';

interface Props {
  dispatch: Dispatch<AnyAction>;
  navigate: NavigateFunction;
}

export const useBudgetActions = ({ dispatch, navigate }: Props) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [budgetId, setBudgetId] = useState('');

  const [selectedRow, setSelectedRow] = useState<null | number>(null);
  const { workshop } = useAuth();

  const handleRowPress = (index: number) => {
    setSelectedRow(index === selectedRow ? null : index);
  };

  const handleWhatsappMessage = (budget: BudgetProps) => {
    // if (userLocal && userLocal.id_user !== visitorId) {

    const budgetUserName = budget.user ? budget.user.name : budget.name;

    const linkText = `Olá, ${encodeURIComponent(
      budgetUserName,
    )}! Segue o orçamento da *${encodeURIComponent(
      workshop?.fantasy_name ?? '',
    )}*%0A%0Ahttps://oficinas.autocenterapp.com/budget-report?budgetId=${
      budget.id_budget
    }%0A%0A🛞Veículo: ${
      encodeURIComponent(budget.vehicle) || 'não informado'
    } %0A🚘Placa: ${encodeURIComponent(
      budget.plate,
    )}%0A%0A%0ABaixe nosso app Autocenter!%0A%0Ahttps://onelink.to/629pmh
    %0A%0A%0ACaso o link não esteja clicável, responda essa mensagem, ou, copie o link e insira em um navegador de sua preferência para acessar o conteúdo.
    `;

    sendWhatsappMessage(
      linkText,
      budget.user ? budget.user.phone : `+55${numberOnly(budget.phone)}`,
    );
    // }
  };

  const handleDeleteBudget = (budget: BudgetProps) => {
    // if (userLocal && userLocal.id_user !== visitorId) {
    if (budget.id_budget) setBudgetId(budget.id_budget);
    setIsDeleteModalOpen(true);
    // }
  };
  const handleEdit = (budget: BudgetProps) => {
    dispatch(
      setBudget({
        ...budget,
        name: budget.user ? budget.user.name : budget.name,
        document: budget.user ? budget.user.document : budget.document,
        phone: budget.user ? budget.user.phone : budget.phone,
        status: /^[0-9]+$/.test(budget.status) ? budget.status : '',
        user: {
          document: budget.user ? budget.user.document : budget.document,
          name: budget.user ? budget.user.name : budget.name,
          phone: budget.user ? budget.user.phone : budget.phone,
        },
        checklist_id: budget.checklist_id || '',
        chassi: budget.chassi || '',
        checklist: budget.checklist || null,
      }),
    );
    navigate('/create-budget');
  };

  const handleCreateOS = (budget: BudgetProps) => {
    dispatch(resetOS);
    let localOrderService: Repair = initialOrderServiceState;

    localOrderService = {
      ...localOrderService,
      comment: budget.comment ?? '',
      defect: budget.defect ?? '',
      discount: budget?.discount ?? 0,
      child_services: budget.child_services ?? [],
      name: budget.name ?? '',
      phone: budget.phone ?? '',
      km: /^[0-9]+$/.test(budget.status)
        ? parseFloat(budget.status || '')
        : null,
      vehicle: {
        ...localOrderService.vehicle,
        model: budget.vehicle ?? '',
        license_plate: budget.plate ?? '',
      },
      user_workshop: {
        ...localOrderService.user_workshop,
        user: {
          ...localOrderService.user_workshop.user,
          name: budget.name ?? '',
          document: budget.document ?? '',
          phone: budget.phone ?? '',
        },
      },
      checklist_id: budget.checklist_id || '',
      checklist: budget.checklist || null,
    };
    saveObjectLocally('@local-order-service', localOrderService);
    navigate(`/create-order-service?budgetId=${budget.id_budget}`);
  };

  return {
    handleCreateOS,
    handleEdit,
    handleDeleteBudget,
    handleWhatsappMessage,
    handleRowPress,
    budgetId,
    setBudgetId,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    selectedRow,
  };
};
