import React, { ReactNode, useState } from 'react';
import styles from './tool-tip.module.css';

interface Props {
  text: string;
  children?: ReactNode;
}

const Tooltip = ({ text, children }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      className={styles.container}
    >
      {children}
      {showTooltip && <span className={styles.tooltip}>{text}</span>}
    </div>
  );
};

export default Tooltip;
