import { Dispatch, SetStateAction, useEffect } from 'react';
import { Modal } from '../Modal';
import styles from './alert-modal.module.css';
import { Button } from '../Button';

interface AlertModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm?: () => void;
  onCancel?: () => void;
  title?: string;
  content: string;
  advice?: boolean;
}

export const AlertModal = ({
  isOpen,
  setIsOpen,
  title = 'Tem Certeza?',
  content,
  onConfirm,
  onCancel,
  advice,
}: AlertModalProps) => {
  const handleConfirmation = () => {
    if (onConfirm) onConfirm();
    setIsOpen(false);
  };
  const handleCancel = () => {
    if (onCancel) onCancel();
    setIsOpen(false);
  };
  useEffect(() => {
    document.body.style.overflow = 'auto';
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0, 0);
    }
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={advice ? 'Atenção' : title}
      footer={
        <div className={styles.actions}>
          <Button variant="ghost" handleClick={handleCancel}>
            Cancelar
          </Button>

          <Button variant="register" handleClick={handleConfirmation}>
            sim
          </Button>
        </div>
      }
    >
      <div className={styles.container}>{content}</div>
    </Modal>
  );
};
