import goal from '../../../../../assets/img/LandingPage/landing-page-about-goal.svg';
import mission from '../../../../../assets/img/LandingPage/landing-page-about-mission.svg';
import values from '../../../../../assets/img/LandingPage/landing-page-about-values.svg';

export const textos = {
  nossa_missao: {
    title: 'Nossa Missão',
    text: [
      'Em um mundo cada vez mais conectado, nossa missão no AutoCenter é prover soluções tecnológicas e integrações para o setor automotivo em geral, em termos de gestão de veículos, postos de combustíveis, frotas e oficinas mecânicas.',
      'Buscamos simplificar e otimizar o dia a dia além de melhorar a experiência no setor automotivo para os usuários. Nosso objetivo é ser o elo digital que conecta necessidades e soluções, promovendo um ecossistema de qualidade e fidelização, sendo mais eficiente e sustentável.',
    ],
    image: mission,
  },
  nossos_objetivos: {
    title: 'Nossos Objetivos',
    image: goal,
    text: [
      {
        title: 'Integrar Serviços',
        description: `Criar uma rede conectada que facilite o acesso a serviços
        veiculares, desde o abastecimento até a manutenção.`,
      },
      {
        title: 'Simplificar a Gestão',
        description: `Oferecer ferramentas que tornem a administração de frotas e
        oficinas mais ágil e menos custosa.`,
      },
      {
        title: 'Facilitar o Cotidiano',
        description: `Proporcionar aos usuários uma experiência mais fluída e
        prática no gerenciamento de suas necessidades veiculares.`,
      },
      {
        title: 'Inovar Constantemente',
        description: `Manter nossa plataforma sempre à frente, incorporando novas
        tecnologias e funcionalidades que atendam as demandas de um
        mercado em constante evolução.`,
      },
    ],
  },
  nossa_historia: {
    title: 'Nossa História',
    image: history,
    text: 'A AutoCenter nasceu em formato de startup, com a missão de auxiliar novos empreendedores do ramo de oficina a se organizarem melhor para obterem sucesso. Essa visão partiu de um experiente dono de oficina que compreendeu profundamente as dificuldades e obstáculos enfrentados por empresários e onsumidores no setor automotivo. Reconhecendo a necessidade de uma solução que pudesse integrar os diversos aspectos da gestão veicular, a AutoCenter foi desenvolvida para ser não apenas uma ferramenta, mas um parceiro estratégico para o crescimento e a eficiência dos negócios automotivos, através de uma melhor relação com os clientes.',
  },
  nossos_valores: {
    title: 'Nossos Valores',
    image: values,
    text: [
      {
        title: 'Comprometimento com a Qualidade',
        description: `A satisfação do cliente é nossa prioridade máxima.`,
      },
      {
        title: 'Inovação Constante',
        description: `Buscamos sempre evoluir acompanhando as novas tecnologias e
        superar expectativas adaptando-se as necessidades dos
        usuários.`,
      },
    ],
    describe: `
    Com a AutoCenter, você tem mais do que uma plataforma, tem um
    parceiro comprometido com o sucesso e a evolução do seu negócio.
    Junte-se a nós e faça parte desta revolução no universo
    automotivo.
    `,
  },
};
