/* eslint-disable react/button-has-type */
import { Trash } from 'phosphor-react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IService } from '../../@types/interface';

interface ChildServiceProps extends IService {
  unit: number | null;
}

interface EditableRowProps extends IService {
  unit: number | null;
  services: ChildServiceProps[];
  setChildServices: Dispatch<SetStateAction<ChildServiceProps[]>>;
  handleRemove: (id: string) => void;
}

export const EditableRow = ({
  name,
  cod_prod = '',
  description,
  quantity = 0,
  total,
  unit,
  services,
  setChildServices,
  handleRemove,
}: EditableRowProps) => {
  const [serviceName, setServiceName] = useState<string>(name || '');
  const [serviceQuantity, setServiceQuantity] = useState<number>(quantity);
  const [serviceUnit, setServiceUnit] = useState<number>(unit || 0);
  const handleServices = (itemId: string) => {
    const editedData = services.map(service =>
      service.cod_prod === itemId
        ? {
            ...service,
            name: serviceName || '',
            description: serviceName,
            quantity: serviceQuantity,
            unit: serviceUnit,
            total:
              serviceUnit && serviceQuantity
                ? serviceUnit * serviceQuantity
                : 0,
          }
        : { ...service },
    );
    setChildServices(editedData);
  };
  useEffect(() => {
    handleServices(cod_prod);
  }, [serviceUnit, serviceQuantity, serviceName]);
  useEffect(() => {
    setServiceName(name);
    setServiceQuantity(quantity);
    setServiceUnit(unit || 0);
  }, [services.length]);
  return (
    <tr>
      <th className="id">{cod_prod}</th>
      <th>
        <input
          type="text"
          name="name"
          value={serviceName || ''}
          placeholder="serviço"
          onChange={(e: any) => setServiceName(e.target.value)}
          style={{
            fontSize: '1rem',
          }}
        />
      </th>
      <th>
        <input
          type="number"
          name="quantity"
          value={serviceQuantity || ''}
          placeholder="qtd"
          onChange={(e: any) => setServiceQuantity(e.target.value)}
          style={{
            fontSize: '1rem',
          }}
        />
      </th>
      <th>
        <input
          type="text"
          pattern="[0-9]*"
          name="unit"
          value={!serviceUnit ? '' : serviceUnit}
          inputMode="numeric"
          placeholder="valor"
          onChange={(e: any) =>
            setServiceUnit(
              e.target.value.replace(',', '.').replace(/[^0-9.]/g, ''),
            )
          }
          style={{
            fontSize: '1rem',
          }}
        />
      </th>
      <th className="total">
        <input
          type="text"
          value={
            serviceUnit && serviceQuantity ? serviceUnit * serviceQuantity : 0
          }
          style={{
            fontSize: '1rem',
          }}
        />
      </th>
      <th className="removeBtn">
        <button
          onClick={() => {
            handleRemove(cod_prod);
          }}
        >
          <Trash size="1.2rem" />
        </button>
      </th>
    </tr>
  );
};
