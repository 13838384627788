import { useMemo, useRef } from 'react';
import { PageLayout } from '../../../../../../../../shared/PageLayout';
import styles from './view-replies-modal.module.css';
import { useViewReplies } from '../../hooks/useViewReplies';
import { Quotation, User } from '../../../../../../../../@types/interface';
import { Overlay } from '../../../../../../../../shared/Overlay';
import { ConfirmAnswerModal } from '../../../../../workshop/pages/AnsweredQuotations/components/ConfirmAnswerModal';
import { ErrorModal } from '../../../../../workshop/pages/AnsweredQuotations/components/ErrorModal';
import { QuotationDescription } from '../QuotationDescription';
import { AnswersList } from '../../../../../workshop/pages/AnsweredQuotations/components/AnswersList';
import { ApproveModal } from '../../../../../workshop/pages/AnsweredQuotations/components/ApproveModal';
import useSelectedQuotation from '../../../../../workshop/hooks/useSelectedQuotation';
import { ResumeModal } from '../../../../../workshop/pages/AnsweredQuotations/components/ResumeModal';
import {
  ModalContent,
  ModalTitle,
} from '../../../../../../Finance/components/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  reply: Quotation | null;
  idParam?: string;
}

const AgentModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => (
  <ModalContent open={open} onClose={onClose}>
    <ModalTitle>Cotação Vinculada a outro Vendedor</ModalTitle>
    <p>Ops! Parece que essa cotação pertence a outro vendedor.</p>
  </ModalContent>
);

export const ViewRepliesModal = ({
  isOpen,
  onClose,
  reply = null,
  idParam = '',
}: Props) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const {
    // quotationReplies,
    handleApproveQuotation,
    handleCloseModal,
    isApprovingModalOpen,
    modalStatus,
    handleConfirmModal,
    isConfirmModalOpen,
    closeErrorModal,
    errorModalMessage,
    isTrembleWarning,
    handleInvalid,
    handleWhatsappMessage,
    handleApproveAnswerById,
    handleRejectAnswerById,
    isLoading,
    approvedQuotation,
    hasInvalidAnswers,
  } = useViewReplies({
    // selectedQuotation: reply,
    quotationId: idParam,
    isOpen,
    onClose,
  });

  const container = overlayRef.current || document.body;
  const selectedQuotation = useSelectedQuotation();

  const quotationAgentId = useMemo(() => {
    return selectedQuotation?.quotation?.agent?.user?.id_user || null;
  }, [selectedQuotation?.quotation?.agent?.user?.id_user]);

  const showAgentModal = useMemo(() => {
    const storedUser = localStorage.getItem('@AutoCenter: user');
    let user: User | null = null;

    try {
      user = storedUser ? JSON.parse(storedUser) : null;
    } catch {
      console.error('Erro ao parsear o usuário do localStorage');
    }

    // if (user?.role_name !== 'agent') return false;

    const agentId = user?.id_user ?? '';

    return agentId !== quotationAgentId;
  }, [quotationAgentId]);

  if (isOpen)
    return (
      <Overlay ref={overlayRef}>
        <AgentModal open={showAgentModal} onClose={onClose} />

        <ResumeModal />
        <ErrorModal
          container={container}
          isOpen={errorModalMessage.trim().length !== 0}
          message={errorModalMessage}
          onClose={closeErrorModal}
        />
        <ApproveModal
          status={modalStatus}
          isOpen={isApprovingModalOpen}
          onClose={() => {
            handleCloseModal();
            if (modalStatus === 'success') {
              onClose();
            }
          }}
          container={container}
        />
        <ConfirmAnswerModal
          isOpen={isConfirmModalOpen}
          onClose={() => handleConfirmModal('close')}
          onConfirm={handleApproveQuotation}
          onCancel={() => handleConfirmModal('close')}
          container={container}
          emptyApproval={hasInvalidAnswers(
            approvedQuotation?.list_answers_approved ?? [],
          )}
        />

        <PageLayout.Root
          title="Avaliar Propostas"
          sectionStyle={{
            background: 'var(--white)',
          }}
          handleNavigation={onClose}
        >
          <div className={styles.content}>
            <div>
              <QuotationDescription
                quotation={selectedQuotation.quotation}
                onClose={onClose}
                container={container}
                isLoading={isLoading}
              />
              <AnswersList
                quotation={selectedQuotation.quotation}
                isTrembleWarning={isTrembleWarning}
                setInvalid={invalid => handleInvalid(invalid)}
                handleWhatsappMessage={handleWhatsappMessage}
                handleApproveAnswer={handleApproveAnswerById}
                handleRejectAnswer={handleRejectAnswerById}
                isLoading={isLoading}
              />
            </div>
          </div>
        </PageLayout.Root>
      </Overlay>
    );
  return null;
};
