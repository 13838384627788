import Cookies from 'js-cookie';

export const isAuthenticated = () => {
  // Verifica se o token está no localStorage ou no cookie
  const localStorageToken = localStorage.getItem('@AutoCenter: accessToken');
  const cookieToken = Cookies.get('@AutoCenter: accessToken');

  // Se o token existir apenas no localStorage, replica para o cookie
  if (localStorageToken && !cookieToken) {
    Cookies.set('@AutoCenter: accessToken', `${localStorageToken}`, {
      expires: 365, // Validade de 365 dias
      secure: true, // Garante que o cookie seja enviado apenas em conexões HTTPS
      sameSite: 'Strict', // Evita o uso de cookies em contextos de terceiros
    });
  }

  // Se o token existir apenas no cookie, replica para o localStorage
  if (cookieToken && !localStorageToken) {
    localStorage.setItem('@AutoCenter: accessToken', cookieToken);
  }

  // Retorna verdadeiro se o token existir em qualquer um dos dois lugares
  const accessToken = localStorageToken || cookieToken;

  return accessToken && accessToken.length > 0;
};
