import { handleError } from '../../../utils/handleError';
import { GetQuotationNotificationInput } from './interface';
import { getQuotationNotification, updateQuotationNotification } from './model';

export const getQuotationNotificationController = ({
  limit,
  page,
}: GetQuotationNotificationInput) => {
  try {
    const params: GetQuotationNotificationInput = {};
    if (limit) params.limit = limit;
    if (page) params.page = page;

    const res = getQuotationNotification(params);

    return res;
  } catch (error) {
    handleError(error);
  }
};
export const updateQuotationNotificationController = (
  notificationId: string,
) => {
  try {
    const res = updateQuotationNotification(notificationId);

    return res;
  } catch (error) {
    handleError(error);
  }
};
