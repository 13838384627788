import {
  Check,
  Eye,
  PaperPlaneRight,
  PencilSimpleLine,
  Trash,
} from '@phosphor-icons/react';
import { Button } from '../../../../../shared/Button';

interface Props {
  handleEdit: () => void;
  handleView: () => void;
  handleDeleteBudget: () => void;
  handleCreateOS: () => void;
  handleWhatsappMessage: () => void;
}

export const BudgetActions = ({
  handleEdit,
  handleView,
  handleCreateOS,
  handleWhatsappMessage,
  handleDeleteBudget,
}: Props) => {
  return (
    <>
      <Button
        icon={<PencilSimpleLine size="1.2rem" />}
        handleClick={handleEdit}
      >
        Editar
      </Button>
      <Button icon={<Eye size="1.2rem" />} handleClick={handleView}>
        Visualizar
      </Button>
      <Button icon={<Trash size="1.2rem" />} handleClick={handleDeleteBudget}>
        Excluir
      </Button>
      <Button
        icon={<Check size="1.2rem" weight="bold" />}
        handleClick={handleCreateOS}
      >
        Iniciar OS
      </Button>
      <Button
        icon={<PaperPlaneRight size="1.2rem" />}
        handleClick={handleWhatsappMessage}
      >
        Enviar
      </Button>
    </>
  );
};
