import { Check, Eye, PencilSimple, X } from 'phosphor-react';
import { Button } from '../../../../../shared/Button';
import { COLORS } from '../../../../../styles/colors';
import history from '../../../../../assets/icons/history.svg';

interface Props {
  handleViewOS: () => void;
  handleDeleteOS: () => void;
  handleUpdateOS: () => void;
  handleHistory?: () => void;
  handleFinishOS?: () => void;
}

export const RepairActions = ({
  handleDeleteOS,
  handleHistory,
  handleUpdateOS,
  handleViewOS,
  handleFinishOS,
}: Props) => {
  return (
    <>
      <Button handleClick={handleViewOS}>
        <Eye size={20} color={COLORS.WHITE} />
        Visualizar
      </Button>
      <Button handleClick={handleDeleteOS}>
        <X size={20} color={COLORS.WHITE} weight="bold" />
        Excluir
      </Button>
      <Button handleClick={handleUpdateOS}>
        <PencilSimple size={20} color={COLORS.WHITE} />
        Editar
      </Button>
      {handleFinishOS && (
        <Button handleClick={handleFinishOS}>
          <Check size={20} color={COLORS.WHITE} />
          Finalizar OS
        </Button>
      )}
      {handleHistory && (
        <Button handleClick={handleHistory}>
          <img
            src={history}
            alt="histórico"
            style={{
              width: '20px',
              height: '20px',
            }}
          />
          Histórico
        </Button>
      )}
    </>
  );
};
