import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArchiveBox, CheckSquare, CopySimple } from 'phosphor-react';
import { PageLayout } from '../../../../shared/PageLayout';
import { NotAnsweredQuotations } from './pages/NotAnsweredQuotations';
import { ApprovedQuotations } from './pages/ApprovedQuotations';
import { FinishedQuotations } from './pages/FinishedQuotations';
import { useResponsive } from '../../../../hooks/useResponsive';
import { Circle } from '../../../../shared/Circle';
import { MobileHeaderAnswerQuotation } from '../autoshop/components/MobileHeaderAnswerQuotation';
import useAnswerModal from './hooks/useAnswerModal';
import useConfirmModal from './hooks/useConfirmModal';
import useSelectedAnswer from '../autoshop/hooks/useSelectedAnswer';
import useViewModal from './hooks/useViewModal';
import { useAnswerQuotation } from '../../../../contexts/answerQuotationContext';

export const AutoshopAgentQuotation = () => {
  const navigate = useNavigate();
  const { selectedPage, setSelectedPage, answerQuotationState } =
    useAnswerQuotation();
  const { sizeMobile } = useResponsive();

  const answerModal = useAnswerModal();
  const confirmModal = useConfirmModal();
  const selectedAnswer = useSelectedAnswer();
  const viewModal = useViewModal();

  const notAnsweredQuotationCounter = useMemo(
    () =>
      answerQuotationState.receivedQuotationList.filter(
        answer => !answer.visualized,
      ).length,
    [answerQuotationState.receivedQuotationList],
  );
  const notApprovedQuotationCounter = useMemo(
    () =>
      answerQuotationState.pendingQuotationList.filter(
        answer => !answer.visualized,
      ).length,
    [answerQuotationState.pendingQuotationList],
  );

  const renderSelectedPage = useMemo(() => {
    switch (selectedPage) {
      case 'notAnswered':
        return <NotAnsweredQuotations />;
      case 'approved':
        return <ApprovedQuotations />;
      case 'finished':
        return <FinishedQuotations />;
      default:
        return <NotAnsweredQuotations />;
    }
  }, [selectedPage]);

  useEffect(() => {
    return () => {
      answerModal.onClose();
      confirmModal.onClose();
      selectedAnswer.onClear();
      viewModal.onClose();
    };
  }, []);

  const handleSwitchComponent = () => {
    if (!sizeMobile) {
      return (
        <>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'notAnswered'}
            onClick={() => setSelectedPage('notAnswered')}
          >
            <ArchiveBox size={32} weight="fill" />
            {notAnsweredQuotationCounter > 0 &&
              `(${notAnsweredQuotationCounter}) `}
            Recebidas
            {notAnsweredQuotationCounter > 0 && (
              <Circle animation="pulse" variant="notification" />
            )}
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'approved'}
            onClick={() => setSelectedPage('approved')}
          >
            {notApprovedQuotationCounter > 0 &&
              `(${notApprovedQuotationCounter})`}
            <CheckSquare size={32} weight="fill" />

            {notApprovedQuotationCounter > 0
              ? 'Aguardando Confirmar Pedido'
              : 'Confirmar Pedido'}

            {notApprovedQuotationCounter > 0 && (
              <Circle animation="pulse" variant="notification" />
            )}
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'finished'}
            onClick={() => setSelectedPage('finished')}
          >
            <CopySimple size={32} weight="fill" />
            Finalizadas
          </PageLayout.TopMenuButton>
        </>
      );
    }
    return (
      <MobileHeaderAnswerQuotation
        notAnsweredCounter={notAnsweredQuotationCounter}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />
    );
  };

  const handleSwitchNavigate = () => {
    if (selectedPage === 'notAnswered') return navigate('/home');
    if (selectedPage === 'approved') return setSelectedPage('notAnswered');
    if (selectedPage === 'finished') return setSelectedPage('notAnswered');
  };

  const handleSelectedTitle = useMemo(() => {
    switch (selectedPage) {
      case 'notAnswered':
        return 'Responder Cotação';
      case 'approved':
        return 'cotações aprovadas';
      case 'finished':
        return 'cotações finalizadas';
      default:
        return 'Responder Cotação';
    }
  }, [selectedPage]);

  return (
    <PageLayout.Root
      title={handleSelectedTitle}
      topMenu={handleSwitchComponent()}
      handleNavigation={() => {
        if (sizeMobile) {
          handleSwitchNavigate();
        } else {
          navigate('/home');
        }
        navigate('/home');
      }}
    >
      {renderSelectedPage}
    </PageLayout.Root>
  );
};
