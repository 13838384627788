import { ComponentProps } from 'react';
import styles from './table-cell.module.css';

type TableCellProps = {
  value?: string;
  alignStart?: boolean;
} & ComponentProps<'td'>;

export const TableCell = ({
  value,
  children,
  alignStart = false,
  ...props
}: TableCellProps) => {
  return (
    <td
      {...props}
      className={`${styles.cell} ${props.className} ${
        alignStart ? styles['align-start'] : ''
      }`}
    >
      {children}
    </td>
  );
};

/**
 * <td {...props} className={`${styles.cell} ${props.className}`}>
      <div
        className={`${styles['cell-content']} ${
          alignStart ? styles['align-start'] : ''
        }`}
      >
        {children}
        {value ? <span>{value}</span> : ''}
      </div>
    </td>
 */
