import { QRCodeSVG } from 'qrcode.react';
import logo from '../../assets/icons/autocenter_logo.svg';

export const QRCode = (url: string) => {
  return (
    <div
      id="qrcode"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        gap: '1rem',
      }}
    >
      <span
        style={{
          fontWeight: 700,
          fontSize: '1.2rem',
        }}
      >
        Acesse as imagens do checklist pelo QR CODE ou link abaixo:{' '}
      </span>
      <QRCodeSVG
        value={url}
        fgColor="#023704"
        size={200}
        imageSettings={{
          src: logo,
          height: 24,
          width: 24,
          excavate: true,
        }}
      />
      <a href={url}>{url}</a>

      <br />
    </div>
  );
};
