import api from '../../../services/api/api';
import { User } from '../../common/Notifications/model';
import { CustomersResponse, DashboardResponse } from './interfaces';

export const getNewCustomers = async (year: string, month: string) => {
  const { data } = await api.get<User[]>(
    `/recommendations/${year}/${month}?page=opcional%20%7C%20default%20%3D%201&limit=opcional%20%7C%20default%20%3D%2010`,
  );
  return data;
};

function getDate(): string {
  const currentYear = new Date().getFullYear();
  return `${currentYear}-01-01`;
}
// export const getDashboard = async (
//   workshop_id: string,
//   initialDate: string,
//   finalDate: string,
// ) => {
//   const { data } = await api.get<DashboardResponse>('dashboard/workshop', {
//     params: {
//       id: workshop_id,
//       initialDate: getDate(),
//       finalDate,
//     },
//   });
//   return data;
// };

export const getDashboard = async () => {
  const { data } = await api.get<DashboardResponse>('/home');
  return data;
};

export const getCustomers = async (
  workshop_id: string,
  initialDate: string,
  finalDate: string,
) => {
  const { data } = await api.get<CustomersResponse>(
    'dashboard/workshop/customers',
    {
      params: {
        workshop_id,
        initialDate,
        finalDate,
      },
    },
  );
  return data;
};
