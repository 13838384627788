/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, forwardRef, ComponentPropsWithoutRef } from 'react';
import imagePlaceholder from '../../assets/img/image-placeholder.svg';
import styles from './image.module.css';
import { LoadingSpinner } from '../LoadingSpinner';
import { ImageModal } from '../ImageModal';

type Props = {
  src?: string;
  width?: number;
  height?: number;
  placeholder?: string;
  alt?: string;
  loadingImage?: boolean;
} & ComponentPropsWithoutRef<'img'>;

const Image = forwardRef<HTMLImageElement, Props>(
  (
    {
      src,
      width = 100,
      height = 100,
      placeholder = imagePlaceholder,
      alt = 'Image',
      loadingImage = false,
      ...rest
    },
    ref,
  ) => {
    const [imageSrc, setImageSrc] = useState(src || placeholder); // Estado para controlar a imagem atual
    const [loading, setLoading] = useState(loadingImage);
    const [showImageModal, setShowImageModal] = useState(false);

    const handleError = () => {
      setImageSrc(placeholder);
    };

    return (
      <div className={styles['image-container']} style={{ width, height }}>
        {loading && (
          <div className={styles.loader}>{/* <LoadingSpinner /> */}</div>
        )}
        {!loading && (
          <img
            ref={ref}
            src={imageSrc}
            alt={alt}
            className={styles.image}
            style={{ width, height }}
            onError={handleError}
            onLoad={() => setLoading(false)}
            onClick={() => setShowImageModal(true)}
            {...rest}
          />
        )}
        <ImageModal
          isOpen={showImageModal}
          onClose={() => {
            setShowImageModal(false);
          }}
          imgUrl={imageSrc}
        />
      </div>
    );
  },
);

export default Image;
