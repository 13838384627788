import * as yup from 'yup';
import {
  validateCNPJ,
  validateCPF,
} from '../../../../../../utils/validations/documentValidation';
import { ERROR_MESSAGES } from '../../../../../../utils/validations/errorMessages';
import { Address } from '../../../../../common/History/interfaces';
import message from '../../../../../../utils/message';
import { FormValues } from './interfaces';
import { maskPhone } from '../../../../../../services/helpers/mask';

interface CustomerProps {
  name: string;
  document: string;
  phone: string;
  email: string;
}

export const validateCustomer = async ({
  // name,
  // document,
  phone,
  email,
}: CustomerProps) => {
  try {
    const schema = await yup
      .object()
      .shape({
        name: yup
          .string()
          .required(ERROR_MESSAGES.requiredName)
          .min(1, ERROR_MESSAGES.minLength),
        document: yup
          .string()
          .required(ERROR_MESSAGES.requiredDocument)
          .test('document', ERROR_MESSAGES.invalidDocument, value => {
            if (!value) return false;
            const formattedDocument = value.replace(/[^\d]+/g, '');
            if (formattedDocument.length === 11) {
              return validateCPF(formattedDocument);
            }
            if (formattedDocument.length === 14) {
              return validateCNPJ(formattedDocument);
            }
            return false;
          }),
        phone: yup
          .string()
          .nullable()
          .test('phone', ERROR_MESSAGES.invalidPhone, value => {
            if (value && value.length < 10) return false;
            return true;
          }),
        email: yup.string().nullable().email(ERROR_MESSAGES.invalidEmail),
      })
      .validate({ name, document, phone, email }, { abortEarly: false });
    return schema as CustomerProps;
  } catch (err) {
    if (err instanceof yup.ValidationError) message(err.errors[0], 'warning');
    else console.error(err);
    throw err;
  }
};

export const validateAddress = async ({
  street,
  uf,
  city,
  zip,
  district,
  number = 'S/N',
}: Address) => {
  try {
    const schema = await yup
      .object()
      .shape({
        zip: yup
          .string()
          .required(ERROR_MESSAGES.required)
          .length(8, ERROR_MESSAGES.invalidCEP),
        street: yup
          .string()
          .required(ERROR_MESSAGES.required)
          .min(1, ERROR_MESSAGES.invalidAddress),
        uf: yup
          .string()
          .required(ERROR_MESSAGES.required)
          .length(2, ERROR_MESSAGES.invalidUF),
        city: yup
          .string()
          .required(ERROR_MESSAGES.required)
          .min(1, ERROR_MESSAGES.invalidCity),
        district: yup
          .string()
          .required(ERROR_MESSAGES.required)
          .min(1, ERROR_MESSAGES.invalidAddress),
        number: yup.string(),
        // .required(ERROR_MESSAGES.required)
        // .min(1, ERROR_MESSAGES.invalidAddress),
      })
      .validate(
        { zip: zip.replace(/[^\d]+/g, ''), street, uf, city, district, number },
        { abortEarly: false },
      );
    return schema as Address;
  } catch (err) {
    if (err instanceof yup.ValidationError) message(err.errors[0], 'warning');
    else console.error(err);
    throw err;
  }
};

export const validateForm = async ({ email, phone }: FormValues) => {
  const schema = yup.object().shape({
    phone: yup
      .string()
      .nullable()
      .test('phone', ERROR_MESSAGES.invalidPhone, value => {
        if (value) {
          const numericValue = maskPhone(value).replace(/\D/g, '');
          if (numericValue.length >= 10 && numericValue.length <= 11)
            return true;
          return false;
        }
        return true;
      }),
    email: yup.string().nullable().email(ERROR_MESSAGES.invalidEmail),
  });
  try {
    await schema.validate({ email, phone });
    return true;
  } catch (err) {
    if (err instanceof yup.ValidationError) message(err.errors[0], 'warning');
    else message(err, 'warning');
  }
};
