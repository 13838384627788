import { useState } from 'react';
import { OptionList, SelectComponent } from '../../../shared/SelectComponent';

export const Test = () => {
  const [options, setOptions] = useState([
    {
      selected: false,
      option: {
        name: 'Opção 1: Escolha um item de exemplo',
        value: '1',
      },
    },
    {
      selected: false,
      option: {
        name: 'Opção 2: Outra escolha para testar',
        value: '2',
      },
    },
    {
      selected: false,
      option: {
        name: 'Opção 3: Mais uma opção para visualizar',
        value: '3',
      },
    },
    {
      selected: false,
      option: {
        name: 'Opção 4: Opção adicional para teste',
        value: '4',
      },
    },
  ]);

  const handleSelect = (selectedOption: OptionList) => {
    setOptions(prevOptions =>
      prevOptions.map(opt =>
        opt.option.value === selectedOption.option.value
          ? { ...opt, selected: !opt.selected }
          : opt,
      ),
    );
  };

  return (
    <div style={{ width: '350px' }}>
      {/* <SelectComponent options={options} onSelect={handleSelect} /> */}
    </div>
  );
};
