import { useCallback, useEffect, useState } from 'react';
import { AlertMessage } from '../../../../../../../../shared/AlertMessage';
import { Quotation } from '../../../../../../../../@types/interface';
import { getQuotationByIdController } from '../../../../controller';
import styles from './finish-modal.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onFinish: () => void;
  quotation?: Quotation | null;
}

export const FinishModal = ({
  isOpen,
  onClose,
  isLoading = false,
  onFinish,
  quotation = null,
}: Props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [hasPendingApproval, setHasPendingApproval] = useState(false);

  const fetchQuotationById = useCallback(async () => {
    const res = await getQuotationByIdController(quotation?.id_quotation ?? '');
    if (res) {
      const numberOfApprovals = res?.confirmed_offers ?? 0;
      const numberOfAnswersApproved = (res?.answers_quotation ?? [])?.filter(
        answer => answer.approved,
      ).length;

      if (numberOfAnswersApproved > numberOfApprovals) {
        setHasPendingApproval(true);
      }
    }
  }, [quotation?.id_quotation]);

  useEffect(() => {
    if (isOpen) {
      fetchQuotationById();
    }
    return () => {
      setHasPendingApproval(false);
      setShowAlert(false);
    };
  }, [fetchQuotationById, isOpen]);

  const handleFinish = useCallback(() => {
    if (hasPendingApproval && !showAlert) {
      setShowAlert(true);
    } else {
      onFinish();
    }
  }, [hasPendingApproval, onFinish, showAlert]);

  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      title={!isLoading ? 'Confirmar Finalização' : 'Finalizando cotação'}
      footer={
        <AlertMessage.Footer>
          {!isLoading && (
            <>
              <AlertMessage.CancelButton onClick={onClose} id="cancel-finish">
                Revisar
              </AlertMessage.CancelButton>
              <AlertMessage.ConfirmButton
                onClick={handleFinish}
                id="confirm-finish"
              >
                Finalizar
              </AlertMessage.ConfirmButton>
            </>
          )}
        </AlertMessage.Footer>
      }
    >
      <AlertMessage.Body className={styles['alert-message-body']}>
        <p>
          Deseja finalizar a cotação{' '}
          <b>{quotation && `N° ${quotation?.QUOTATION ?? ''}`}</b>?
        </p>
        <span className={showAlert ? styles.alert : ''}>
          {showAlert
            ? `Sua cotação tem propostas aguardando confirmação, deseja finalizar mesmo assim?`
            : `Ela não poderá mais receber nenhuma resposta!`}
        </span>
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
