import { useCallback, useEffect, useState } from 'react';
import { getMyAnswersController } from '../controller';
import { AnswerQuotation } from '../../../../../@types/interface';
import { GetMyAnswerFilters, GetMyAnswerOptions } from '../interface';

interface Props {
  option: GetMyAnswerOptions;
}

export const useFilteredAnswers = ({ option }: Props) => {
  const [filterType, setFilterType] = useState<GetMyAnswerFilters>('code');
  const [filterValue, setFilterValue] = useState('');
  const [searchedAnswers, setSearchedAnswers] = useState<AnswerQuotation[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAnswersByFilter = useCallback(async () => {
    if (filterValue.trim().length > 0) {
      setIsLoading(true);
      const res = await getMyAnswersController({
        option,
        filter: filterType,
        filter_value: filterValue,
      });

      if (res && res.length > 0) {
        setSearchedAnswers(res);
      } else {
        setSearchedAnswers([]);
      }
    } else {
      setSearchedAnswers([]);
    }
    setIsLoading(false);
  }, [filterType, filterValue, option, setIsLoading]);

  const handleDateSearch = (period: string[]) => {
    // clear search state for invalid period
    if (period.length !== 2) {
      setFilterValue('');
    } else {
      const [initialDate, finalDate] = period;
      if (initialDate.trim().length > 0 && finalDate.trim().length > 0) {
        setFilterValue(`["${initialDate}", "${finalDate}"]`);
      }
    }
  };
  const handleSearch = (query: string) => {
    setFilterValue(query);
  };

  useEffect(() => {
    if (filterValue.trim().length > 0) {
      fetchAnswersByFilter();
    }
  }, [fetchAnswersByFilter, filterValue]);

  return {
    filterType,
    filterValue,
    handleSearch,
    handleDateSearch,
    searchedAnswers,
    setFilterType,
    isLoading,
  };
};
