/* eslint-disable no-useless-escape */
/* eslint-disable class-methods-use-this */
import { AnyAction, Dispatch } from 'redux';
import { BudgetProps } from '../../../../../../features/budget/interfaces';
import { setBudget } from '../../../../../../features/budget/budget-slice';
import message from '../../../../../../utils/message';
import { schema } from './schema';
import { FormValues } from './interfaces';
import { validateSchema } from '../../../../../../utils/validations/validateSchema';

export class CustomerFormController {
  private budget: BudgetProps;

  private dispatch: Dispatch<AnyAction>;

  constructor(budget: BudgetProps, dispatch: Dispatch<AnyAction>) {
    this.budget = budget;
    this.dispatch = dispatch;
  }

  public submitCustomer(data: FormValues, handleClick: () => void) {
    const validation = validateSchema(schema, data);
    if (validation && typeof validation === 'boolean') {
      this.dispatch(
        setBudget({
          ...this.budget,
          ...data,
          plate: data.license_plate,
        }),
      );
      handleClick();
    } else if (typeof validation === 'string') message(validation, 'warning');
  }

  public resetClientFields(
    values: FormValues,
    reset: (values: FormValues) => void,
  ) {
    const { name, phone, document, ...formContent } = values;
    reset({
      name: '',
      phone: '',
      document: '',
      ...formContent,
    });
  }
}
