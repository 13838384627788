/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { ChangeEvent } from 'react';
import { PlusSquare, XCircle } from '@phosphor-icons/react';
import imagePlaceholder from '../../../../assets/img/image-placeholder.svg';
import styles from './image-picker.module.css';
import { Button } from '../../../Button/index';
import { useResponsive } from '../../../../hooks/useResponsive';

interface Props {
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  handleFilesChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  max_images: number;
  selectedImageUrls: { url: string; name: string }[];
  removeImage?: (index: number) => void;
}

export const ImagePicker = ({
  inputRef,
  handleFilesChange,
  max_images,
  selectedImageUrls,
  removeImage,
}: Props) => {
  const { sizeMobile } = useResponsive();
  return (
    <div className={styles['image-picker-container']}>
      <div className={styles['input-image-control']}>
        <label htmlFor="image">Enviar Imagem:</label>
        <input
          type="file"
          name="image"
          id="image"
          ref={inputRef}
          onChange={handleFilesChange}
        />
      </div>

      <div className={styles['preview-wrapper']}>
        {max_images > selectedImageUrls.length && (
          <button
            type="button"
            className={styles['img-field']}
            onClick={() => {
              inputRef?.current?.click();
            }}
          >
            <PlusSquare size={32} color="var(--primary)" weight="fill" />
          </button>
        )}
        {[...Array(max_images)].map((_, index) => (
          <div className={styles.containerImage} key={index}>
            <button
              type="button"
              className={styles['image-item']}
              onClick={() => {
                inputRef?.current?.click();
              }}
              key={index}
            >
              <img
                src={
                  index < selectedImageUrls.length
                    ? selectedImageUrls[index].url
                    : imagePlaceholder
                }
                alt="visualizar imagem"
                className={styles.preview}
              />
            </button>
            {removeImage && index < selectedImageUrls.length && (
              <Button
                variant="cancel"
                handleClick={() => removeImage(index)}
                icon={sizeMobile ? <XCircle size={25} weight="fill" /> : null}
              >
                {sizeMobile ? null : 'remover'}
              </Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
