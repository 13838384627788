import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  resetBudget,
  resetChecklist,
} from '../../../features/budget/budget-slice';
import { resetOS } from '../../../features/orderService/orderService-slice';
import { BudgetList } from './components/BudgetList';
import { HeaderComponent } from '../../../shared/HeaderComponent';
import styles from './budget.module.css';

export const Budget = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetOS());
    dispatch(resetBudget());
    dispatch(resetChecklist());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <HeaderComponent title="Orçamentos" />
      <BudgetList />
    </div>
  );
};
