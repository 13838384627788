import { useMemo } from 'react';
import { Quotation } from '../../../../../../@types/interface';
import { QuotationList } from '../QuotationList';
import styles from './quotation-items.module.css';

interface Props {
  quotation: Quotation | null;
}

export const QuotationItems = ({ quotation = null }: Props) => {
  const itemsQuotation = useMemo(() => {
    return quotation?.items_quotation ?? [];
  }, [quotation?.items_quotation]);

  return (
    <div className={styles.container}>
      <h1>Itens para cotação</h1>
      <QuotationList.Root>
        <div className={styles['quotation-list-content']}>
          <QuotationList.Header />
          {itemsQuotation.length > 0 &&
            itemsQuotation.map((item, index) => (
              <QuotationList.Item
                item={item}
                index={index + 1}
                key={item.id_item_quotation}
              />
            ))}
        </div>
      </QuotationList.Root>
    </div>
  );
};
