import { useState } from 'react';
import { MagnifyingGlass } from 'phosphor-react';
import { VehicleModal } from '../../../shared/VehicleModal';
import { PageLayout } from '../../../shared/PageLayout';
import styles from './search-vehicle.module.css';

export const SearchVehicle = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [plate, setPlate] = useState('');

  const handleSearch = () => {
    setIsOpen(true);
  };

  const maskPlate = (text: string) => {
    if (!text) return '';
    const value = text.replace(/\W/g, '').substring(0, 7);
    const { length } = value;
    if (length >= 3 && length <= 7) {
      if (length > 3 && length <= 6) {
        return value.replace(/(\w{3})(\w{1,2})/, '$1$2').toUpperCase();
      }
      return value.replace(/(\w{3})(\w{4})/, '$1$2').toUpperCase();
    }
    return text;
  };

  return (
    <PageLayout.Root title="Consulta de veículos">
      <div className={styles.container}>
        <div className={styles['input-control']}>
          <input
            type="search"
            placeholder="Buscar por placa"
            value={plate}
            onChange={e => {
              const { value } = e.target;

              setPlate(maskPlate(value.toLocaleUpperCase()));
              if (value.length === 7) {
                handleSearch();
              }
            }}
            maxLength={7}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
          <MagnifyingGlass size={32} color="#ccc" weight="bold" />
        </div>
        <VehicleModal
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
            setPlate('');
          }}
          licensePlate={plate}
        />

        <div className={styles['empty-wrapper']}>
          <img
            width="100%"
            src="./img/backgroundEP.png"
            alt="plano de fundo de carro"
          />

          <span className={styles['empty-message']}>
            Consulte informações do veículo pela tabela FIPE
          </span>
        </div>
      </div>
    </PageLayout.Root>
  );
};
