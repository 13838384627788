import { useDispatch } from 'react-redux';
import {
  CheckCircle,
  WhatsappLogo,
  ChatText,
  Check,
  X,
  Hourglass,
  Truck,
} from 'phosphor-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ProductTable } from '../../../../../../../../shared/ProductTable';
import { Textarea } from '../../../../../../../../shared/Textarea';
import styles from './littleAnswer.module.css';
import {
  AnswerQuotation,
  ItemQuotation,
} from '../../../../../../../../@types/interface';
import { editAnswerRebuttal } from '../../../../../../../../features/quotation/quotation-reply-slice';
import { useAppSelector } from '../../../../../../../../hooks/useAppSelector';
import { Button } from '../../../../../../../../shared/Button';
import { ConfirmRejectAnswerModal } from '../ConfirmRejectAnswerModal';
import { ApproveAnswerModal } from '../ApproveAnswerModal';
import { MobileQuotationItem } from '../MobileQuotationItem';
import { ApprovedAnswer } from '../../../../../../../../features/quotation/interfaces';
import { useResponsive } from '../../../../../../../../hooks/useResponsive';

interface Props {
  quotationItems: ItemQuotation[];
  answer: AnswerQuotation;
  isTrembleWarning: boolean;
  handleWhatsappMessage: (phone: string) => void;
  isAnswered: boolean;
  handleApproveAnswer: (id: string) => void;
  handleRejectAnswer: (id: string) => void;
}
export const MobileAnswerItem = ({
  answer,
  quotationItems,
  isTrembleWarning,
  handleWhatsappMessage,
  isAnswered,
  handleApproveAnswer,
  handleRejectAnswer,
}: Props) => {
  const dispatch = useDispatch();
  const { approvedQuotation } = useAppSelector(state => state.quotationReducer);

  const approvedItems = (approvedQuotation?.list_answers_approved || []).find(
    approvedItem =>
      approvedItem.answer_quotation_id === answer.id_answer_quotation,
  );

  const [subtotal, setSubtotal] = useState(0);
  const [isConfirmRejectionModalOpen, setIsConfirmRejectionModalOpen] =
    useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);

  const calculateNotApprovedSubtotal = useCallback(() => {
    const newSubTotal = quotationItems?.reduce(
      (previousValue, quotationItem) => {
        const currentAnswer = (answer?.items_answer_quotation || []).find(
          answerItem =>
            answerItem.item_quotation_id === quotationItem.id_item_quotation,
        );

        if (currentAnswer) {
          const quantityAsked = parseFloat(`${quotationItem.quantity}`);
          const quantity = Math.min(
            quantityAsked,
            currentAnswer.quantity_available,
          );
          return previousValue + currentAnswer.total * quantity;
        }
        return previousValue;
      },
      0,
    );
    setSubtotal(newSubTotal);
  }, [answer?.items_answer_quotation, quotationItems]);

  const calculateApprovedSubtotal = useCallback(() => {
    const newSubtotal =
      (approvedItems &&
        approvedItems.items.reduce(
          (previousValue, currentValue) =>
            previousValue + currentValue.quantity_asked * currentValue.value,
          0,
        )) ||
      0;
    setSubtotal(newSubtotal);
  }, [approvedItems]);

  const calculateAnsweredSubtotal = useCallback(() => {
    const newSubtotal = answer?.items_answer_quotation
      ?.filter(item => item.quantity_asked)
      ?.reduce(
        (previousValue, currentValue) =>
          previousValue +
          (currentValue?.quantity_asked ?? 0) * currentValue.total,
        0,
      );
    setSubtotal(newSubtotal);
  }, [answer?.items_answer_quotation]);

  useEffect(() => {
    if (isAnswered) {
      calculateAnsweredSubtotal();
    } else if (approvedItems?.items.length === 0) {
      // if nothing is approved
      calculateNotApprovedSubtotal();
    } else {
      // if approve any item
      calculateApprovedSubtotal();
    }
  }, [
    approvedItems?.items?.length,
    calculateAnsweredSubtotal,
    calculateApprovedSubtotal,
    calculateNotApprovedSubtotal,
    isAnswered,
  ]);
  const { sizeMobile } = useResponsive();
  const containerRef = useRef<HTMLDivElement>(null);
  const handleAnswerStatus = useCallback(() => {
    if (answer?.rejected) {
      return (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.25rem',
          }}
        >
          <X size={24} weight="duotone" color="var(--error)" />
          Rejeitado
        </span>
      );
    }
    if (answer?.finished) {
      return (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.25rem',
          }}
        >
          <CheckCircle size={24} weight="duotone" color="var(--primary)" />
          Envio Confirmado
        </span>
      );
    }
    if (answer?.confirmed) {
      return (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.25rem',
          }}
        >
          <Hourglass size={24} weight="duotone" color="var(--primary)" />
          Aguardando Envio
        </span>
      );
    }
    return '';
  }, [answer?.confirmed, answer?.finished, answer?.rejected]);

  const hasInvalidAnswers = useCallback(
    (answers: ApprovedAnswer[]) => {
      return answers
        ?.map(approvedAnswer => ({
          ...answer,
          items:
            approvedAnswer?.items?.filter(item => item.quantity_asked === 0) ??
            [],
        }))
        ?.some(approvedAnswer => approvedAnswer.items.length > 0);
    },
    [answer],
  );

  const whatsapp = useMemo(() => {
    return answer?.workshop?.whatsapp || null;
  }, [answer?.workshop?.whatsapp]);

  const agent = useMemo(() => answer?.agent ?? null, [answer?.agent]);

  const workshopFantasyName = useMemo(
    () => answer?.workshop?.fantasy_name ?? '',
    [answer?.workshop?.fantasy_name],
  );

  const getAnswerItems = useCallback(
    (quotationItem: ItemQuotation) => {
      return (
        answer?.items_answer_quotation?.filter(
          item => item.item_quotation_id === quotationItem.id_item_quotation,
        ) || []
      );
    },
    [answer?.items_answer_quotation],
  );

  const answerComment = useMemo(() => {
    return answer?.comment ?? '';
  }, [answer?.comment]);

  const rebuttal = useMemo(() => {
    return answer?.rebuttal ?? '';
  }, [answer?.rebuttal]);

  const taxValue = useMemo(() => {
    return answer?.tax_value
      ? answer.tax_value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : 'Não informado';
  }, [answer?.tax_value]);

  return (
    <>
      <ConfirmRejectAnswerModal
        isOpen={isConfirmRejectionModalOpen}
        onClose={() => setIsConfirmRejectionModalOpen(false)}
        onConfirm={() => {
          handleRejectAnswer(answer?.id_answer_quotation ?? '');
          setIsConfirmRejectionModalOpen(false);
        }}
        onCancel={() => setIsConfirmRejectionModalOpen(false)}
        container={containerRef.current || document.body}
      />
      <ApproveAnswerModal
        isOpen={isApproveModalOpen}
        onClose={() => setIsApproveModalOpen(false)}
        onApprove={() => {
          handleApproveAnswer(answer?.id_answer_quotation ?? '');
          setIsApproveModalOpen(false);
        }}
        answer={answer}
        hasNoApprovedItems={approvedItems?.items.length === 0}
        emptyApproval={hasInvalidAnswers([approvedItems as ApprovedAnswer])}
      />
      <div className={styles.container} ref={containerRef}>
        <ProductTable.Root
          heading={
            <div className={styles.headingWrapper}>
              <h3>{workshopFantasyName}</h3>
              {agent && <sub>vendedor: {agent?.user?.name || ''}</sub>}
              <ul className={styles.info}>
                <li style={{ width: '100%' }}>
                  {whatsapp && sizeMobile ? (
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      <p>Contato:</p>
                    </div>
                  ) : null}
                  {whatsapp && sizeMobile ? (
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'flex-end',
                        display: 'flex',
                      }}
                    >
                      <Button
                        handleClick={() =>
                          handleWhatsappMessage(whatsapp ?? '')
                        }
                        style={{
                          height: '1.5rem',
                          backgroundColor: '#007a37',
                        }}
                      >
                        <WhatsappLogo size={24} weight="fill" />
                        Whatsapp
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'end',
                      }}
                    >
                      Nenhum Contato Disponível
                    </div>
                  )}
                </li>
                {handleAnswerStatus()}
              </ul>
            </div>
          }
          style={{
            gap: '0',
          }}
        >
          <ProductTable.Header
            style={{
              gridTemplateColumns: '1fr 2fr',
              justifyItems: 'center',
              alignContent: 'center',
              alignItems: 'center',
              padding: '0.5rem 1rem',
              backgroundColor: '#81c784',
            }}
          >
            <span
              style={{
                justifySelf: 'stretch',
                fontSize: '1rem',
              }}
            >
              Item
            </span>
            <span
              style={{
                fontSize: '1rem',
              }}
            >
              Descrição
            </span>
            <span />
          </ProductTable.Header>
          <ProductTable.Body>
            <div>
              {quotationItems.map((quotationItem, index) => {
                const answerItems = getAnswerItems(quotationItem);

                return (
                  <>
                    <div className={styles['item-description']}>
                      <span>{index + 1}</span>
                      <span>{quotationItem?.description ?? ''}</span>
                    </div>
                    <ul className={styles['items-list']}>
                      <div className={styles['list-header']}>
                        <span>Confirmação</span>
                        <span>Valor Unit.</span>
                      </div>

                      {answerItems.map(answerSubItem => (
                        <MobileQuotationItem
                          quotationItem={quotationItem}
                          answer={answerSubItem}
                          key={quotationItem.id_item_quotation}
                          isTrembleWarning={isTrembleWarning}
                          isAnswered={isAnswered}
                        />
                      ))}
                    </ul>
                  </>
                );
              })}
            </div>

            <div className={styles.subtotal}>
              {isAnswered ||
              (approvedItems &&
                approvedItems.items &&
                approvedItems.items.length > 0) ? (
                <b>Total</b>
              ) : (
                <div className={styles['subtotal-info']}>
                  <b>Sub-Total</b>
                  <span>(Cálculo com os menores preços)</span>
                </div>
              )}
              <span>
                {subtotal.toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}
              </span>
            </div>
          </ProductTable.Body>

          {answerComment.trim().length > 0 && (
            <div className={styles.observation}>
              <b>
                <ChatText size={24} color="var(--primary)" /> Comentário do
                Fornecedor:
              </b>
              <p>{answerComment}</p>
            </div>
          )}

          <div className={styles.comment}>
            <b>
              <Truck size={24} color="var(--primary)" /> Taxa de Entrega:
            </b>
            <p> {taxValue}</p>
          </div>

          <div className={styles.observation}>
            <h1>
              {!isAnswered ? (
                <>
                  Adicionar Observação <span>(Opcional)</span>
                </>
              ) : (
                `${(answer?.rebuttal ?? '').length > 0 ? 'Observação' : ''}`
              )}
            </h1>

            {!isAnswered ? (
              <Textarea
                handleChange={e => {
                  dispatch(
                    editAnswerRebuttal({
                      answer_quotation_id: answer.id_answer_quotation,
                      updatedValue: e.target.value,
                    }),
                  );
                }}
                name="Observação"
                disabled={isAnswered}
                style={{
                  height: '5rem',
                  fontWeight: '500',
                  color: '#000000',
                }}
              />
            ) : (
              <p>{rebuttal}</p>
            )}
          </div>
          {!isAnswered && (
            <div className={styles.actions}>
              <Button
                handleClick={() => setIsConfirmRejectionModalOpen(true)}
                style={{
                  borderRadius: '4px',
                  background: '#E53835',
                  width: '120px',
                  padding: '.5rem',
                  textAlign: 'center',
                }}
              >
                <X weight="fill" color="var(--white)" size={18} /> Rejeitar
                cotação
              </Button>

              <Button
                handleClick={() => setIsApproveModalOpen(true)}
                style={{
                  borderRadius: '4px',
                  background: 'var(--shade_1)',
                  width: '120px',
                  padding: '.5rem',
                  textAlign: 'center',
                }}
              >
                <Check weight="fill" color="var(--white)" size={18} /> Aprovar
                selecionados
              </Button>
            </div>
          )}
        </ProductTable.Root>
      </div>
    </>
  );
};
