import * as yup from 'yup';
import { getBudgetData } from './model';
import message from '../../message';

export const getBudgetDataController = async (budgetId: string) => {
  try {
    await yup.string().required().validate(budgetId);
    const res = await getBudgetData(budgetId);
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    }
    message(error, 'error');
  }
};
