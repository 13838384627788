import styles from './input-text.module.css';
import { CommonInputProps } from '../..';

interface Props {
  label?: string;
  commonInputAttributes: CommonInputProps;
  name: string;
  error?: string;
  isLoading?: boolean;
}

export const InputText = ({
  commonInputAttributes,
  name,
  error,
  isLoading,
  label,
}: Props) => {
  return (
    <div className={styles['input-control']}>
      <span>
        {label && <label htmlFor={name}>{label}</label>}
        {isLoading && (
          <div className={styles['lds-ring']}>
            <div />
            <div />
            <div />
            <div />
          </div>
        )}
      </span>
      <input type="text" {...commonInputAttributes} />
      {error && <span className={styles['error-text']}>{error}</span>}
    </div>
  );
};
