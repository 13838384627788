import { useRef } from 'react';
import { PageLayout } from '../../../../../../../../shared/PageLayout';
import styles from './view-replies-modal.module.css';
import { useViewReplies } from '../../hooks/useViewReplies';
import { Quotation } from '../../../../../../../../@types/interface';
import { Overlay } from '../../../../../../../../shared/Overlay';
import { ConfirmAnswerModal } from '../ConfirmAnswerModal';
import { ErrorModal } from '../ErrorModal';
import { QuotationDescription } from '../QuotationDescription';
import { AnswersList } from '../AnswersList';
import { ApproveModal } from '../ApproveModal';
import useSelectedQuotation from '../../../../hooks/useSelectedQuotation';
import { ResumeModal } from '../ResumeModal';
import {
  ModalContent,
  ModalTitle,
} from '../../../../../../Finance/components/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  reply: Quotation | null;
  idParam?: string;
}

export const ViewRepliesModal = ({
  isOpen,
  onClose,
  reply = null,
  idParam = '',
}: Props) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const {
    // quotationReplies,
    handleApproveQuotation,
    handleCloseModal,
    isApprovingModalOpen,
    modalStatus,
    handleConfirmModal,
    isConfirmModalOpen,
    closeErrorModal,
    errorModalMessage,
    isTrembleWarning,
    handleInvalid,
    handleWhatsappMessage,
    handleApproveAnswerById,
    handleRejectAnswerById,
    isLoading,
    approvedQuotation,
    hasInvalidAnswers,
    isQuotationWatching,
  } = useViewReplies({
    // selectedQuotation: reply,
    quotationId: idParam,
    isOpen,
    onClose,
  });

  const container = overlayRef.current || document.body;
  const selectedQuotation = useSelectedQuotation();

  if (isOpen)
    return (
      <Overlay ref={overlayRef}>
        <ModalContent open={isQuotationWatching} onClose={onClose}>
          <ModalTitle>Propostas Sendo Respondidas</ModalTitle>
          <p>
            Ops! Parece que as propostas dessa cotação já estão sendo
            respondidas por outro vendedor
          </p>
        </ModalContent>
        <ResumeModal />
        <ErrorModal
          container={container}
          isOpen={errorModalMessage.trim().length !== 0}
          message={errorModalMessage}
          onClose={closeErrorModal}
        />
        <ApproveModal
          status={modalStatus}
          isOpen={isApprovingModalOpen}
          onClose={() => {
            handleCloseModal();
            if (modalStatus === 'success') {
              onClose();
            }
          }}
          container={container}
        />
        <ConfirmAnswerModal
          isOpen={isConfirmModalOpen}
          onClose={() => handleConfirmModal('close')}
          onConfirm={handleApproveQuotation}
          onCancel={() => handleConfirmModal('close')}
          container={container}
          emptyApproval={hasInvalidAnswers(
            approvedQuotation?.list_answers_approved ?? [],
          )}
        />

        <PageLayout.Root
          title="Avaliar Propostas"
          sectionStyle={{
            background: 'var(--white)',
          }}
          handleNavigation={onClose}
        >
          <div className={styles.content}>
            <div>
              <QuotationDescription
                quotation={selectedQuotation.quotation}
                onClose={onClose}
                isLoading={isLoading}
              />
              <AnswersList
                quotation={selectedQuotation.quotation}
                isTrembleWarning={isTrembleWarning}
                setInvalid={invalid => handleInvalid(invalid)}
                handleWhatsappMessage={handleWhatsappMessage}
                handleApproveAnswer={handleApproveAnswerById}
                handleRejectAnswer={handleRejectAnswerById}
                isLoading={isLoading}
              />
            </div>
          </div>
        </PageLayout.Root>
      </Overlay>
    );
  return null;
};
