import * as yup from 'yup';

const schema = yup.object({
  validity: yup.string(),
  defect: yup.string(),
  comment: yup.string(),
  name: yup.string(),
  phone: yup.string(),
  document: yup.string(),
  child_services: yup
    .array()
    .min(1, 'Informe pelo menos um serviço')
    .of(
      yup.object({
        name: yup.string().required('O nome do serviço é obrigatório'),
        description: yup.string(),
        total: yup.number().required(),
        codProd: yup.string(),
        quantity: yup
          .number()
          .required()
          .min(1, 'Informe uma quantidade válida'),
      }),
    ),
  discount: yup.number(),
  total: yup.number(),
  plate: yup.string(),
  vehicle: yup.string().nullable(),
  status: yup.string(),
});

export const validateForm = (data: any) => {
  try {
    schema.validateSync(data, { abortEarly: false });
    return { isValid: true };
  } catch (err: any) {
    const validationErrors = err.inner.map(
      (innerError: any) => innerError.message,
    );
    return { isValid: false, errors: validationErrors };
  }
};
