import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import api from '../../../../../services/api/api';
import message from '../../../../../utils/message';
import { removeBudget } from '../../../../../features/budget/budget-slice';
import { Modal } from '../../../../../shared/Modal';
import styles from './delete-modal.module.css';
import { Button } from '../../../../../shared/Button';

interface ConfirmModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  budgetId: string;
  setBudgetId: Dispatch<SetStateAction<string>>;
}
export const DeleteModal = ({
  isOpen,
  setIsOpen,
  budgetId,
  setBudgetId,
}: ConfirmModalProps) => {
  const dispatch = useDispatch();

  const handleDeleteBudget = async () => {
    try {
      await api.delete(`/budget/${budgetId}`).then(() => {
        dispatch(removeBudget(budgetId));
        setIsOpen(false);
        setBudgetId('');
      });
    } catch (error) {
      message('não foi possível excluir o orçamento selecionado', 'error');
    }
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Tem certeza?"
      footer={
        <div className={styles.actions}>
          <Button variant="register" handleClick={() => setIsOpen(false)}>
            Cancelar
          </Button>
          <Button
            handleClick={handleDeleteBudget}
            variant="register"
            theme="delete"
          >
            Excluir
          </Button>
        </div>
      }
    >
      <p className={styles.message}>Você excluirá esse Orçamento permanente</p>
    </Modal>
  );
};
