import * as yup from 'yup';
import { handleError } from '../../../../utils/handleError';

import {
  AnswerQuotationInput,
  GetMyAnswerProps,
  RejectQuotationInput,
  WatchStateOptions,
} from './interface';
import {
  answerQuotation,
  confirmQuotation,
  getAllMyAnswers,
  getAnswerQuotationById,
  getMyAnswers,
  rejectQuotation,
  updateAnswerQuotationWatchingState,
} from './model';
import { handleErrorMessage } from '../../../../utils/handleErrorMessage';

export const getMyAnswersController = async (props: GetMyAnswerProps) => {
  try {
    const res = await getMyAnswers({ ...props });

    return res;
  } catch (err) {
    handleError(err);
  }
};
export const getAllMyAnswersController = async (
  props: Omit<GetMyAnswerProps, 'option'>,
) => {
  try {
    const res = await getAllMyAnswers({ ...props });

    return res;
  } catch (err) {
    handleError(err);
  }
};

export const answerQuotationController = async (
  props: AnswerQuotationInput,
) => {
  try {
    const normalizedAnswerItems = props.items.map(item => ({
      item_quotation_id: item?.item_quotation_id ?? '',
      sub_items: item.sub_items.map(subItem => ({
        total: subItem?.total,
        quantity_available: subItem?.quantity_available ?? '',
        brand: subItem?.brand ?? '',
        reference: subItem?.reference ?? '',
      })),
    }));

    const normalizedAnswer = {
      quotation_id: props.quotation_id,
      comment: props?.comment ?? '',
      items: normalizedAnswerItems,
      tax_value: props.tax_value,
    };

    const schema = yup.object().shape({
      quotation_id: yup.string().required(),
      comment: yup.string(),
      items: yup
        .array()
        .of(
          yup.object().shape({
            item_quotation_id: yup.string().required(),
            sub_items: yup.array().of(
              yup.object().shape({
                total: yup.number(),
                quantity_available: yup.string(),
                brand: yup.string(),
                reference: yup.string(),
              }),
            ),
          }),
        )
        .min(
          1,
          'Verifique os itens da cotação. Os itens não podem ser enviados com quantidade disponível 0',
        ),
    });
    await schema.validate(normalizedAnswer, {
      abortEarly: false,
    });
    const res = await answerQuotation(normalizedAnswer);

    return { status: 'success', data: res };
  } catch (err) {
    const message = handleErrorMessage(err);
    return { status: 'error', data: message };
  }
};

export const confirmQuotationController = (id: string) => {
  try {
    const res = confirmQuotation(id);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const getAnswerQuotationByIdController = async (id: string) => {
  try {
    const res = await getAnswerQuotationById(id);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateAnswerQuotationWatchingStateController = async (
  answerQuotationId: string,
  state: WatchStateOptions,
) => {
  try {
    const res = await updateAnswerQuotationWatchingState(
      answerQuotationId,
      state,
    );
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const rejectQuotationController = async (
  props: RejectQuotationInput,
) => {
  try {
    const res = await rejectQuotation(props.id, props.comment);
    return res;
  } catch (err) {
    handleError(err);
  }
};
