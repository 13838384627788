import { useEffect, useState } from 'react';
import styles from './refresh-modal.module.css';
import { getCurrentVersion } from './model';

export const RefreshModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [newVersion, setNewVersion] = useState('');

  const fetchVersion = async () => {
    const localVersion = localStorage.getItem('@Autocenter: version');
    const res = await getCurrentVersion();
    if (res) {
      if (res.version !== localVersion) {
        if (!localVersion) {
          localStorage.setItem('@Autocenter: version', res.version);
        } else {
          setIsVisible(true);
          setNewVersion(res.version);
        }
      }
    }
  };
  const refreshPage = () => {
    localStorage.setItem('@Autocenter: version', newVersion);
    window.location.reload();
  };

  useEffect(() => {
    fetchVersion();
  }, []);

  return (
    <button
      type="button"
      className={`${styles.container} ${isVisible && `${styles.visible}`}`}
      onClick={refreshPage}
    >
      Uma nova versão foi detectada <span>Clique para recarregar</span>{' '}
    </button>
  );
};
