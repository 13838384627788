import { useState } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import { FormValues, SubItem, formItemQuotation } from './useAnswerQuotation';

interface Props {
  formQuotationItems: formItemQuotation[];
}

export const useQuotationValidation = ({ formQuotationItems }: Props) => {
  const [confirmModalItems, setConfirmModalItems] = useState<number[]>([]);

  const isSubItemInvalid = (subitem: SubItem) => {
    const quantityAvailable = parseFloat(subitem?.quantity_available ?? '0');
    const total = parseFloat(subitem?.total ?? '0');
    const brand = subitem?.brand ?? '';

    return (
      isNaN(quantityAvailable) ||
      isNaN(total) ||
      quantityAvailable <= 0 ||
      total <= 0 ||
      brand.trim().length <= 0
    );
  };
  const checkFormItems = () => {
    return formQuotationItems
      .map((item, itemIndex) =>
        item.sub_items.every(isSubItemInvalid) ? itemIndex + 1 : -1,
      )
      .filter(index => index !== -1); // returns only valid index
  };

  const validateQuotationAnswer = (handleAnswer: () => void) => {
    const invalidItemsIndex = checkFormItems();
    if (invalidItemsIndex.length === 0) {
      handleAnswer();
    } else {
      setConfirmModalItems(invalidItemsIndex);
    }
  };

  const isQuotationItemAnswered = (
    item: FieldArrayWithId<FormValues, 'items', 'id'>,
  ) => {
    return formQuotationItems
      ?.filter(
        formItem =>
          formItem &&
          formItem.sub_items.filter(subItem => !isSubItemInvalid(subItem))
            .length > 0,
      )
      ?.some(formItem => formItem.item_quotation_id === item.item_quotation_id);
  };

  return {
    validateQuotationAnswer,
    confirmModalItems,
    setConfirmModalItems,
    isQuotationItemAnswered,
  };
};
