import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './not-answered-quotations.module.css';
import answeredQuotationsIcon from '../../../../../../assets/icons/answered-quotations.svg';
import { useNotAnsweredQuotations } from './hooks/useNotAnsweredQuotations';
import { FilterComponent } from '../../../../../../shared/FilterComponent';
import { useFilteredAnswers } from '../../hooks/useFilteredAnswers';
import { GetMyAnswerFilters } from '../../interface';
import {
  getObjectFromLocal,
  removeObjectFromLocal,
} from '../../../../../../utils/localStorage';

import { AnswerQuotationModal } from './components/AnswerQuotationModal';
import { useAnswerQuotation } from '../../../../../../contexts/answerQuotationContext';
import { ViewAnswerQuotationModal } from '../../../workshop/components/ViewAnswerQuotationModal';
import { AnswerList } from './components/QuotationListItem';
import { AnswerQuotation } from '../../../../../../@types/interface';
import useViewModal from '../../hooks/useViewModal';
import useAnswerModal from '../../hooks/useAnswerModal';
import useSelectedAnswer from '../../hooks/useSelectedAnswer';
import { usePageVisibility } from '../../../../../../hooks/usePageVisibility';

export const NotAnsweredQuotations = () => {
  const {
    isLoading,
    loadMoreData,
    page,
    setIsAnswerQuotationModalOpen,
    handleRowClick,
    fetchQuotationAnswerById,
    resetStates,
  } = useNotAnsweredQuotations();
  const [searchParams, setSearchParams] = useSearchParams();
  const { updateAnswerQuotations } = useAnswerQuotation();

  const {
    filterType,
    filterValue,
    handleDateSearch,
    handleSearch,
    searchedAnswers,
    setFilterType,
    isLoading: isSearching,
  } = useFilteredAnswers({
    option: 'recieved',
  });

  const localAnswerId = getObjectFromLocal('answerId');

  useEffect(() => {
    if (localAnswerId && localAnswerId.length > 0) {
      removeObjectFromLocal('answerId');
      setIsAnswerQuotationModalOpen(true);
    }
  }, [localAnswerId, setIsAnswerQuotationModalOpen]);

  const { answerQuotationState } = useAnswerQuotation();

  const viewModal = useViewModal();
  const answerModal = useAnswerModal();
  const selectedAnswer = useSelectedAnswer();

  const handleGetQuotationByIdParam = useCallback(async () => {
    const answerId = searchParams.get('id');
    if (answerId) {
      await fetchQuotationAnswerById(answerId);
      if (selectedAnswer.answer) {
        if (selectedAnswer.answer.answered) {
          // alert('viu');
          viewModal.onOpen();
        } else {
          // setIsAnswerQuotationModalOpen(true);
          // alert('aqrui');
          answerModal.onOpen();
        }
      }
      setSearchParams({});
    }
  }, [
    answerModal,
    fetchQuotationAnswerById,
    searchParams,
    selectedAnswer.answer,
    setSearchParams,
    viewModal,
  ]);

  useEffect(() => {
    handleGetQuotationByIdParam();
  }, [handleGetQuotationByIdParam]);

  const isEmpty = useMemo(() => {
    return (
      !isLoading && answerQuotationState.receivedQuotationList.length === 0
    );
  }, [answerQuotationState.receivedQuotationList.length, isLoading]);

  const notFound = useMemo(() => {
    return filterValue.trim().length > 0 && searchedAnswers.length === 0;
  }, [filterValue, searchedAnswers.length]);

  usePageVisibility({
    onVisible: resetStates,
  });

  return (
    <>
      <ViewAnswerQuotationModal
        answer={selectedAnswer.answer}
        isOpen={viewModal.isOpen}
        onClose={() => {
          selectedAnswer.onClear();
          viewModal.onClose();
        }}
        onUpdate={updateAnswerQuotations}
      />
      <AnswerQuotationModal
        localAnswerId={localAnswerId || ''}
        isOpen={answerModal.isOpen}
        onClose={() => {
          selectedAnswer.onClear();
          answerModal.onClose();
        }}
        answer={selectedAnswer.answer}
        onUpdate={updateAnswerQuotations}
      />
      <div className={styles.heading}>
        <div className={styles.describe}>
          <img src={answeredQuotationsIcon} alt="responder cotações" />
          <p>Cotações recebidas</p>
        </div>
        <div className={styles.actions}>
          <FilterComponent
            handleDateSearch={handleDateSearch}
            handleSearch={handleSearch}
            isLoading={isSearching}
            handleFilterChange={(filter: string) =>
              setFilterType(filter as GetMyAnswerFilters)
            }
            defaultValue="code"
            variant={filterType === 'date_recieved' ? 'date' : 'searchbar'}
            onlyNumber={filterType === 'code'}
            options={[
              {
                label: 'Número',
                value: 'code',
              },
              {
                label: 'Data',
                value: 'date_recieved',
              },
              {
                label: 'Placa',
                value: 'plate',
              },
              {
                label: 'Origem',
                value: 'origin',
              },
            ]}
          />
        </div>
      </div>
      <AnswerList
        answerList={
          filterValue.trim().length > 0
            ? searchedAnswers
            : answerQuotationState.receivedQuotationList
        }
        handleRowClick={(answer: AnswerQuotation) => handleRowClick(answer)}
        onScroll={() => loadMoreData(page)}
        isLoading={isLoading}
        isEmpty={isEmpty}
        notFound={notFound}
      />
    </>
  );
};
