import styles from './header.module.css';

export const Header = () => {
  return (
    <div className={styles.header}>
      <span>Item</span>
      <span>Descrição</span>
      <span>Qtd. Solicitada</span>
      <span>Referência</span>
    </div>
  );
};
