import { IconProps } from 'phosphor-react';
import React from 'react';

import { IconWeight } from '@phosphor-icons/react';
import styles from './button.module.css';
import { LoadingSpinner } from '../../../../../../../shared/LoadingSpinner';

type ButtonVariant = 'default' | 'outline';
type ButtonStyle = '' | 'danger' | 'success';
type VariantSize = 'sm' | 'md' | 'lg';

type ButtonRootProps = React.ComponentPropsWithoutRef<'button'> & {
  variant?: ButtonVariant;
  ButtonClassName?: string;
  size?: VariantSize;
  variantStyle?: ButtonStyle;
};

export const ButtonRoot = React.forwardRef<HTMLButtonElement, ButtonRootProps>(
  (
    {
      children,
      variant = 'default',
      variantStyle = '',
      size = 'md',
      ButtonClassName = '',
      ...props
    },
    ref,
  ) => {
    return (
      // eslint-disable-next-line react/button-has-type
      <button
        {...props}
        ref={ref}
        className={`${styles.button} ${styles[variant]} ${ButtonClassName} ${styles[size]} ${styles[variantStyle]}`}
      >
        {children}
      </button>
    );
  },
);

type ButtonTextProps = React.ComponentPropsWithoutRef<'span'>;

export const ButtonText = ({ children, ...props }: ButtonTextProps) => {
  return <span {...props}>{children}</span>;
};

type ButtonIconProps = {
  Icon: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
} & IconProps;

export const ButtonIcon = ({ Icon, ...props }: ButtonIconProps) => {
  return (
    <div className={styles.icon}>
      <Icon {...props} />
    </div>
  );
};

type ButtonComponentProps = React.ComponentPropsWithoutRef<'button'> & {
  text?: string;
  iconLeft?: boolean;
  buttonClassname?: string;
  variant?: ButtonVariant;
  variantStyle?: ButtonStyle;
  Icon?: React.ForwardRefExoticComponent<
    IconProps & React.RefAttributes<SVGSVGElement>
  >;
  iconSize?: number;
  iconWeight?: IconWeight;
  isLoading?: boolean;
};

export const ButtonComponent = React.forwardRef<
  HTMLButtonElement,
  ButtonComponentProps
>(
  (
    {
      text,
      Icon,
      iconSize = 20,
      iconWeight = 'regular',
      iconLeft = false,
      buttonClassname,
      variant,
      variantStyle,
      isLoading = false,
      ...props
    },
    ref,
  ) => {
    return (
      <ButtonRoot
        {...props}
        ref={ref}
        ButtonClassName={`${buttonClassname} ${iconLeft && styles.left}`}
        variant={variant}
        variantStyle={variantStyle}
        disabled={isLoading}
      >
        {!isLoading && (
          <>
            <ButtonText>{text}</ButtonText>
            {Icon && (
              <ButtonIcon Icon={Icon} size={iconSize} weight={iconWeight} />
            )}
          </>
        )}
        {isLoading && <LoadingSpinner />}
      </ButtonRoot>
    );
  },
);
