import { NotificationCard } from '../../../shared/NotificationCard';
import { PageLayout } from '../../../shared/PageLayout';
import { useQuotationNotification } from './useQuotationNotification';
import styles from './quotation-notification.module.css';
import { QuotationNotificationResponse } from './interface';
import { Loading } from '../../../shared/Loading';
import { useNotification } from '../../../contexts/notificationContext';

export const QuotationNotification = () => {
  const { setSelectedNotification, isLoading } = useQuotationNotification();

  const { quotationNotifications } = useNotification();

  const handleRedirect = (notification: QuotationNotificationResponse) => {
    setSelectedNotification(notification);
  };

  return (
    <>
      {/* <ReadNotificationModal
        isOpen={!!selectedNotification}
        onClose={() => setSelectedNotification(null)}
        title="Atualização da cotação"
        onView={() => navigate('/new-quotation')}
      > */}
      {/* <p>{selectedNotification?.message ?? ''}</p>
      </ReadNotificationModal> */}
      <PageLayout.Root title="Notificações das Cotações">
        <ul className={styles['notifications-list']}>
          {isLoading && quotationNotifications.length === 0 && (
            <>
              <Loading
                style={{
                  height: '8rem',
                }}
              />
              <Loading
                style={{
                  height: '8rem',
                }}
              />
              <Loading
                style={{
                  height: '8rem',
                }}
              />
              <Loading
                style={{
                  height: '8rem',
                }}
              />
            </>
          )}
          {quotationNotifications.map(notification => (
            <NotificationCard.Root
              key={notification.id_notification}
              datetime={notification.created_at}
              title={notification?.title ?? 'Atualização da cotação'}
              message={notification.message}
              variant={notification.type}
              onClick={() => handleRedirect(notification)}
            />
          ))}
        </ul>
      </PageLayout.Root>
    </>
  );
};
