import React, { CSSProperties } from 'react';
import { Button } from '../../../Button';

interface Props {
  children?: React.ReactNode;
  onClick: () => void;
  style?: CSSProperties;
  id?: string;
}

export const CancelButton = ({
  children = 'Cancelar',
  onClick,
  style,
  id,
}: Props) => {
  return (
    <Button handleClick={onClick} variant="cancel" style={style} id={id}>
      {children}
    </Button>
  );
};
