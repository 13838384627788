/* eslint-disable jsx-a11y/alt-text */
import { useDispatch } from 'react-redux';
import styles from './initial.module.css';

import mainFigure from '../../../../../assets/img/LandingPage/mainFigure.svg';
import checkIcon from '../../../../../assets/img/LandingPage/checkSymbol.svg';

import starIcon from '../../../../../assets/img/LandingPage/star.svg';
import lightIcon from '../../../../../assets/img/LandingPage/light.svg';
import gearIcon from '../../../../../assets/img/LandingPage/gear.svg';

import { QuickTipCard } from '../../Components/QuickTipCard/QuickTipCard';
import backgroundPatternRight from '../../../../../assets/img/LandingPage/backgroundPattern.svg';
import backgroundPatternLeft from '../../../../../assets/img/LandingPage/backgroundPattern2.svg';
import backgroundPatternLeftSmall from '../../../../../assets/img/LandingPage/backgroundPatternSmall.svg';
import backgroundPatternRightSmall from '../../../../../assets/img/LandingPage/backgroundPatternSmall2.svg';

import { setOpen } from '../../../../../features/modalLanginPage/openModal';
import { Button } from '../../../../../shared/Button';

const Initial = () => {
  const dispatch = useDispatch();
  const handleClick = (uri: string) => {
    if (uri === 'register') {
      dispatch(setOpen({ show: true }));
    }
  };
  return (
    <main className={styles.main}>
      <section className={styles.presentation}>
        <img className={styles.mainFigure} src={mainFigure} alt="mechanic" />
        <section className={styles.mainCard}>
          <header>
            <h1>PENSADO PARA A SUA OFICINA</h1>
            <img src={checkIcon} alt="" />
          </header>
          <p>
            Desenvolvido para ser uma parceira indispensável o crescimento da
            sua oficina e aumentar a satisfação de seus clientes
          </p>
        </section>
      </section>

      <section className={styles.information}>
        <header>
          <strong>Revolucione Seu Negócio com o Autocenter</strong>
          <p>
            Eficiência, organização e resultados ao alcance de um toque. Sua
            oficina nunca mais será a mesma!
          </p>
        </header>

        <div className={styles.tipCards}>
          <QuickTipCard
            icon={starIcon}
            title="Fácil de usar"
            text="Com um visual amigável é fácil utilizar as ferramentas e os recursos do aplicativo."
          />
          <QuickTipCard
            icon={lightIcon}
            title="Agilidade"
            text="Economize seu tempo automatizando vários serviços."
          />
          <QuickTipCard
            icon={gearIcon}
            title="Suporte Rápido"
            text="Nossa equipe está sempre disponível para ajudar."
          />
        </div>

        <img
          className={styles.backgroundPattern1}
          src={backgroundPatternRight}
        />

        <img
          className={styles.backgroundPatternSmall1}
          src={backgroundPatternLeftSmall}
        />
      </section>

      <section className={styles.invitation}>
        <img
          className={styles.backgroundPattern2}
          src={backgroundPatternLeft}
        />

        <img
          className={styles.backgroundPatternSmall2}
          src={backgroundPatternRightSmall}
        />

        <strong>
          Falta pouco para você transformar a organização da sua oficina
        </strong>

        <Button
          handleClick={() => handleClick('register')}
          variant="register"
          theme="pulse"
          style={{
            paddingInline: '1rem',
            fontSize: '1.6rem',
          }}
        >
          teste gratuitamente
        </Button>
      </section>
    </main>
  );
};

export default Initial;
