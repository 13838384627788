import { useState, useEffect, useCallback, useRef } from 'react';
import { getOrderServiceController } from './controller';
import { debounce } from '../../../utils/debounce';
import { Repair } from '../../common/History/interfaces';

const LIMIT_REPAIRS = 80;
const LIMIT_FILTERED_REPAIRS = 999;

export const useRepairData = () => {
  const [repairs, setRepairs] = useState<Repair[]>([]);
  const [filteredRepairs, setFilteredRepairs] = useState<Repair[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noMoreData, setNoMoreData] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const cache = useRef<{ [key: string]: Repair[] }>({});

  const debouncedFilterOrderServicesRef = useRef(
    debounce(async (query: string) => {
      if (cache.current[query] && cache.current[query].length > 0) {
        setFilteredRepairs(cache.current[query]);
        return;
      }
      setIsLoading(true);
      const res = await getOrderServiceController({
        query,
        page: 1,
        limit: LIMIT_FILTERED_REPAIRS,
      });
      if (res && res.repairs) {
        cache.current[query] = res.repairs;
        setFilteredRepairs(res.repairs);
      } else {
        setFilteredRepairs([]);
      }
      setIsLoading(false);
    }, 300),
  );

  const fetchData = useCallback(async (pageRequest: number) => {
    setIsLoading(true);
    const res = await getOrderServiceController({
      limit: LIMIT_REPAIRS,
      page: pageRequest,
    });
    if (res && res.repairs) {
      const requestRepairs = res.repairs;
      if (requestRepairs.length === 0) setNoMoreData(true);
      setRepairs(prev => {
        const newRepairs = requestRepairs.filter(newRepair => {
          return !prev.some(
            existingRepair => existingRepair.id_repair === newRepair.id_repair,
          );
        });
        return [...prev, ...newRepairs];
      });
    } else {
      setNoMoreData(true);
    }
    setIsLoading(false);
  }, []);

  const loadMoreData = useCallback(
    (previousPage = 1) => {
      if (!noMoreData && searchQuery.trim().length === 0) {
        fetchData(previousPage);
        setPage(previousPage + 1);
      }
    },
    [noMoreData, searchQuery, fetchData],
  );

  useEffect(() => {
    if (searchQuery.trim().length > 0) {
      debouncedFilterOrderServicesRef.current(searchQuery);
    }
  }, [searchQuery]);

  useEffect(() => {
    loadMoreData();
    const lastSearch = localStorage.getItem('@autocenter: search') || '';
    if (lastSearch.trim().length > 0) {
      setSearchQuery(lastSearch);
      localStorage.removeItem('@autocenter: search');
    }
  }, [loadMoreData]);

  return {
    repairs,
    filteredRepairs,
    isLoading,
    searchQuery,
    setSearchQuery,
    loadMoreData,
    page,
    setRepairs,
    setFilteredRepairs,
  };
};
