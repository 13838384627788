import { IService } from '../../@types/interface';

export const formatServiceTable = (services: IService[]) => {
  // const filteredServices = services.filter(
  //   service => service.name !== '' && service.quantity > 0 && service.total > 0,
  // );
  return services.map((service, index) => {
    const { total, quantity, name, description } = service;
    return {
      total,
      quantity,
      name,
      description,
      codProd: `${index + 1}`,
    };
  });
};
