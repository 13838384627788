import { CopySimple, FileText, GridFour } from '@phosphor-icons/react';
import { Dispatch, SetStateAction } from 'react';
import { Circle } from '../../../../../../shared/Circle';
import { PageLayout } from '../../../../../../shared/PageLayout';
import styles from './mobile-workshop-quotation-header.module.css';
import { QuotationPages } from '../../../../../../contexts/quotationContext';

type Props = {
  newOffers: number;
  selectedPage: QuotationPages;
  setSelectedPage: Dispatch<SetStateAction<QuotationPages>>;
};

export const MobileWorkshopQuotationHeader = ({
  newOffers,
  selectedPage,
  setSelectedPage,
}: Props) => {
  const handleNameButton = () => {
    if (selectedPage === 'created') {
      return ['Criadas', <GridFour size={32} weight="fill" />];
    }
    if (selectedPage === 'replies') {
      return ['Respostas', <FileText size={32} weight="fill" />];
    }

    return ['Finalizadas', <CopySimple size={32} weight="fill" />];
  };

  const handleHideButton = () => {
    if (selectedPage === 'created') {
      return 'created';
    }
    if (selectedPage === 'replies') {
      return 'replies';
    }

    return 'completed';
  };

  return (
    <div className={styles.contentButton}>
      <PageLayout.TopMenuButton onClick={() => console.log('')} isSelected>
        {handleNameButton()[1]}
        {handleNameButton()[0]}
        {selectedPage === 'replies' && newOffers > 0 && ` (${newOffers})`}{' '}
        {selectedPage === 'replies' && newOffers > 0 && (
          <Circle animation="pulse" variant="notification" />
        )}
      </PageLayout.TopMenuButton>

      <div
        className={`${styles.containerDropDownOpen} ${
          styles[handleHideButton()]
        }`}
      >
        <PageLayout.TopMenuButton
          isSelected={selectedPage === 'created'}
          onClick={() => setSelectedPage('created')}
        >
          <GridFour size={32} weight="fill" />
          Criadas
        </PageLayout.TopMenuButton>
        <PageLayout.TopMenuButton
          isSelected={selectedPage === 'replies'}
          onClick={() => {
            setSelectedPage('replies');
          }}
        >
          <FileText size={32} weight="fill" />
          Respostas
          {newOffers > 0 && ` (${newOffers})`}{' '}
          {newOffers > 0 && <Circle animation="pulse" variant="notification" />}
        </PageLayout.TopMenuButton>
        <PageLayout.TopMenuButton
          isSelected={selectedPage === 'completed'}
          onClick={() => setSelectedPage('completed')}
        >
          <CopySimple size={32} weight="fill" />
          Finalizadas
        </PageLayout.TopMenuButton>
      </div>
    </div>
  );
};
