import { Eye } from '@phosphor-icons/react';
import { useState } from 'react';
import { ToggleSwitch } from '../../../../../shared/ToggleSwitch';
import { Button } from '../../../../../shared/Button';
import styles from './agents-list-item.module.css';
import { ListTable } from '../../../../../shared/ListTable';
import { AgentViewModal } from '../AgentViewModal';
import { UpdateAgent, AgentResponse } from '../../interface';
import { maskCpfCnpj } from '../../../../../services/helpers/mask';
import { updateAgentController } from '../../controller';

interface Props {
  data: AgentResponse;
}

export const AgentsListItem = ({ data }: Props) => {
  const [status, setStatus] = useState(data.actived);
  const [isAgentViewModalOpen, setIsAgentViewModalOpen] = useState(false);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <ListTable.Row
      style={{
        gridTemplateColumns: '.35fr repeat(3, 1fr) .15fr',
        background: status ? 'var(--tint_4)' : '#89939E30',
        height: '8rem',
        marginBottom: '1rem',
        borderRadius: '8px',
        color: status ? '#000' : 'var(--d_grey)',
      }}
    >
      <AgentViewModal
        isOpen={isAgentViewModalOpen}
        onClose={() => setIsAgentViewModalOpen(false)}
        data={data}
      />

      <ToggleSwitch
        value={status}
        onChange={() => {
          setStatus(prev => !prev);
          updateAgentController({
            agent_id: data.id_agent,
            actived: !status,
          } as UpdateAgent);
        }}
      />

      <span className={styles.name}>{data?.user?.name ?? ''}</span>
      <span>{maskCpfCnpj(data?.user?.document ?? '')}</span>
      <span>{formatDate(data?.created_at ?? new Date())}</span>

      <div className={styles.actions}>
        <Button
          variant="icon"
          handleClick={() => setIsAgentViewModalOpen(true)}
          style={{
            background: 'var(--secondary)',
            borderRadius: '4px',
            height: '1rem',
            width: '1rem',
          }}
          icon={<Eye size={18} weight="fill" color="var(--white)" />}
        />

        {/*
        Aguardar verificação se esse botão é necessário
        <Button
          variant="icon"
          handleClick={() => console.log('')}
          style={{
            background: 'var(--secondary)',
            borderRadius: '4px',
            height: '1rem',
            width: '1rem',
          }}
          icon={<PencilSimple size={18} weight="fill" color="var(--white)" />}
        /> */}
      </div>
    </ListTable.Row>
  );
};
