import { ReactNode } from 'react';
import styles from './info-row.module.css';

interface Props {
  icon: ReactNode;
  name: string;
  value?: string;
}

export const InfoRow = ({ icon, name, value = 'não informado' }: Props) => {
  return (
    <div className={styles.row}>
      {icon}
      <span className={styles.label}>{name}:</span>
      <span className={styles.text}>
        <strong>{value}</strong>
      </span>
    </div>
  );
};
