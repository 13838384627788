/* eslint-disable react/no-array-index-key */
import styles from './describe-card.module.css';

interface Props {
  title: string;
  paragraphs: string[];
  img_url: string;
}

export const DescribeCard = ({ paragraphs, title, img_url }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>{title}</h1>
        {paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      <img src={img_url} alt={title} />
    </div>
  );
};
