import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BudgetDataFetcher } from '../../../../../services/dataFetchers/BudgetDataFetcher/BudgetDataFetcher';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { setBudgets } from '../../../../../features/budget/budget-slice';

const LIMIT_SIZE = 80;

export const useBudgetData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const budgetDataFetcher = useMemo(() => new BudgetDataFetcher(), []);

  const budgetsFromState = useAppSelector(state => state.budget.budgets);
  const dispatch = useDispatch();

  const fetchData = useCallback(
    async (page: number) => {
      if (!hasMoreData || isLoading) {
        return;
      }
      setIsLoading(true);
      const budgetsResponse = await budgetDataFetcher.fetchOrderServices(
        LIMIT_SIZE,
        page,
      );
      if (budgetsResponse) {
        const newBudgets = budgetsResponse.filter(budget => {
          return !budgetsFromState.some(
            existingBudget => budget.id_budget === existingBudget.id_budget,
          );
        });
        if (newBudgets.length > 0) {
          dispatch(
            setBudgets(
              [...budgetsFromState, ...newBudgets].sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime(),
              ),
            ),
          );
        } else {
          setHasMoreData(false);
        }
      }
      setIsLoading(false);
    },
    [budgetDataFetcher, budgetsFromState, dispatch, hasMoreData, isLoading],
  );

  const loadMoreData = () => {
    if (!isLoading) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, fetchData]);

  return {
    budgetsFromState,
    isLoading,
    hasMoreData,
    loadMoreData,
    setCurrentPage,
  };
};
