import { ArrowClockwise } from 'phosphor-react';
import { Button } from '../../../Button';

interface Props {
  onClick: () => void;
  text?: string;
}

export const EditButton = ({ onClick, text = 'Editar' }: Props) => {
  return (
    <Button
      handleClick={onClick}
      style={{
        background: 'var(--b_grey)',
      }}
    >
      <ArrowClockwise
        size={20}
        style={{
          transform: 'rotateY(180deg)',
        }}
      />
      <span>{text}</span>
    </Button>
  );
};
