/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  ChangeEvent,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CaretDown, CaretUp, Trash } from '@phosphor-icons/react';
import styles from './interactable-span.module.css';
import { Button } from '../Button';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  alignment?: 'start' | 'end' | 'center';
  isSelected?: boolean;
  editable?: boolean;
  inputType?: 'default' | 'amount';
  onRemove?: () => void;
  maxValue?: number;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  isInvalid?: boolean;
  isMobile?: boolean;
}

export const InteractableSpan = ({
  defaultValue,
  alignment = 'center',
  isSelected = false,
  inputType = 'default',
  maxValue = 999,
  isInvalid = false,
  editable = true,
  onRemove,
  handleChange,
  isMobile,
  ...rest
}: Props) => {
  const [isEditing, setIsEditing] = useState(isSelected);
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(`${(defaultValue || rest.value) ?? ''}`);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.focus();
    }
    return () => {
      setIsFocused(false);
    };
  }, [isFocused]);

  useEffect(() => {
    setIsEditing(isSelected);
    return () => {
      setIsEditing(false);
    };
  }, [isSelected]);

  useEffect(() => {
    if (rest.value) {
      setValue(`${(defaultValue || rest.value) ?? ''}`);
    }
  }, [defaultValue, rest.value]);

  const handleAmount = (action: 'decrease' | 'increase') => {
    setValue(previousValue => {
      let parsedValue = parseFloat(previousValue) || 0;
      if (action === 'increase' && parsedValue < maxValue) {
        parsedValue += 1;
      } else if (action === 'decrease') {
        parsedValue = Math.max(0, parsedValue - 1);
      }
      return `${parsedValue}`;
    });
  };

  // const handleSpanClick = () => {
  //   if (!isEditing && editable) {
  //     setIsFocused(true);
  //     setIsEditing(true);
  //   }
  // };

  return (
    <div className={`${styles.container} ${isInvalid ? styles.invalid : ''}`}>
      {/* {!isEditing && inputType !== 'amount' && (
        <div className={styles.actions}>
          <span
            onClick={handleSpanClick}
            style={{
              textAlign: alignment,
              color: value.length > 0 ? '#000' : '#767676',
              paddingLeft: 0,
            }}
          >
            {value.length > 0 ? value : rest.placeholder}
          </span>
        </div>
      )} */}

      {/* {editable && (isEditing || inputType === 'amount') && ( */}
      <div className={styles['input-control']}>
        {inputType === 'amount' && (
          <div className={styles['top-btn']}>
            <Button
              handleClick={() => {
                handleAmount('increase');
              }}
              icon={
                <CaretUp
                  size={18}
                  weight="fill"
                  color={
                    parseFloat(value) === maxValue ? 'var(--grey)' : '#000'
                  }
                />
              }
              variant="icon"
              style={{
                height: 'auto',
                width: 'auto',
              }}
            />
          </div>
        )}

        <input
          ref={inputRef}
          value={value}
          list="value"
          onChange={e => {
            if (handleChange) handleChange(e);
            // setValue(e.target.value);
          }}
          onBlur={() => setIsEditing(false)}
          max={maxValue}
          type={inputType === 'amount' ? 'number' : 'text'}
          {...rest}
          style={{
            textAlign: alignment,
          }}
        />
        {inputType === 'amount' && (
          <div className={styles['bottom-btn']}>
            <Button
              handleClick={() => handleAmount('decrease')}
              icon={
                <CaretDown
                  size={18}
                  weight="fill"
                  color={parseFloat(value) === 0 ? 'var(--grey)' : '#000'}
                />
              }
              variant="icon"
              style={{
                height: 'auto',
                width: 'auto',
              }}
            />
          </div>
        )}
      </div>

      {/* {!editable && isMobile && inputType !== 'amount' && (
        <div className={styles['input-control']}>
          <input
            ref={inputRef}
            value={value}
            list="value"
            onChange={e => {
              if (handleChange) handleChange(e);
              setValue(e.target.value);
            }}
            disabled
            max={maxValue}
            type="text"
            {...rest}
            style={{
              textAlign: alignment,
            }}
          />
        </div>
      )} */}
    </div>
  );
};
