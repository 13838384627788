import { ComponentProps, CSSProperties, ReactNode } from 'react';
import styles from './sub-row.module.css';

interface Props extends ComponentProps<'div'> {
  children: ReactNode;
  style?: CSSProperties;
}

export const SubRow = ({ children, style, ...props }: Props) => {
  return (
    <div
      className={`${styles.subrow} ${props.className}`}
      style={style}
      {...props}
    >
      {children}
    </div>
  );
};
