import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '../Modal';
import styles from './send-budget-modal.module.css';
import { Button } from '../Button';

interface SendBudgetModalProps {
  isOpen: boolean;
  handleClose: () => void;
  status: 'sending' | 'success' | 'error';
  isEditing?: boolean;
  onPressMessage?: () => void;
}

export const SendBudgetModal = ({
  isOpen,
  handleClose,
  status,
  onPressMessage,
  isEditing = false,
}: SendBudgetModalProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      window.scrollTo(0, 0);
    }
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={isEditing ? 'Editar Orçamento' : 'Novo Orçamento'}
      isLoading={status === 'sending'}
      footer={
        <div className={styles.actions}>
          <Button
            handleClick={() => {
              if (onPressMessage) onPressMessage();
            }}
            style={{ display: status === 'success' ? 'flex' : 'none' }}
          >
            Enviar via WhatsApp
          </Button>
        </div>
      }
    >
      <p className={styles.content}>
        {status === 'success' &&
          `Orçamento ${isEditing ? 'atualizado ' : 'criado'} com sucesso!`}
        {status === 'error' && 'Ocorreu um erro, tente novamente!'}
      </p>
    </Modal>
  );
};
