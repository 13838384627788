import api from '../../../../../services/api/api';
import { LoginInput, AuthenticationResponse } from './interface';

export const authenticateWorkshop = async (props: LoginInput) => {
  const { data } = await api.post<AuthenticationResponse>('/auth/workshop', {
    ...props,
  });

  return data;
};

export const authenticateAgent = async (props: LoginInput) => {
  const { data } = await api.post<AuthenticationResponse>('/auth/agent', {
    ...props,
  });

  return data;
};
