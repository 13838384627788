import {
  CheckListRequest,
  Banner,
  IService,
  User,
  IAddress,
} from '../../../../../../@types/interface';
import api from '../../../../../../services/api/api';
import message from '../../../../../../utils/message';
import { Repair } from '../../../../../common/History/interfaces';

export interface CreateCustomerInput {
  name: string;
  document: string;
  email?: string;
  phone?: string;
  address?: IAddress;
}
export interface CreateCustomerResponse {
  id_users_Workshop: string;
  favorite: boolean;
  workshop_id: string;
  user_id: string;
  user: User;
  created_at: Date;
  updated_at: Date;
}
interface StatusResponse {
  id_status: string;
  status_flag: string;
  description: string;
  active: boolean;
  created_at: Date;
  updated_at: Date;
}
interface createRepairResponse {
  repair: Repair;
  workshop_id: string;
}

interface RepairData {
  name: string;
  id_user?: string;
  phone: string;
  description?: string;
  comment: string;
  total: number;
  defect: string;
  childServices: IService[];
  discount: number;
  km?: number;
  finished?: boolean;
  statusId: string;
  userId?: string;
  vehicleId?: string;
  date?: string;
  time?: string;
  finishDate?: string;
  finishTime?: string;
  checklistId?: string;
}
// VEHICLE
// export const createVehicle = async (
//   usersWorkshopsId: string,
//   licensePlate: string,
// ) => {
//   try {
//     const { data } = await api.post<CreateVehicleResponse>(
//       '/vehicle/customer',
//       {
//         licensePlate,
//         usersWorkshopsId,
//       },
//     );
//     return data;
//   } catch (error) {
//     message(error, 'error');
//     return null;
//   }
// };

// export const removeVehicle = async (
//   id_vehicle: string,
//   user_id: string,
//   workshop_id: string,
// ) => {
//   try {
//     await api.put(
//       `/vehicle/remove/${id_vehicle}?user_id=${user_id}&workshop_id=${workshop_id}`,
//     );
//   } catch (error) {
//     message(error, 'error');
//   }
// };
// CUSTOMER
// export const createCustomer = async (
//   workshop_id: string,
//   customerData: CreateCustomerInput,
// ) => {
//   try {
//     const { data } = await api.post<CreateCustomerResponse>(
//       '/workshop/customer',
//       {
//         workshopId: workshop_id,
//         vehicles: [],
//         ...customerData,
//       },
//     );
//     return data;
//   } catch (error) {
//     message(error, 'error');
//     return null;
//   }
// };
// REPAIR
export const fetchStatusOptions = async () => {
  try {
    const { data } = await api.get<StatusResponse[]>('/registrationStatus');
    return data;
  } catch (error) {
    message(error, 'error');
  }
};

export const createRepair = async (repairData: RepairData) => {
  try {
    const { data } = await api.post<createRepairResponse>(
      '/repair',
      repairData,
    );
    return data;
  } catch (error) {
    message(error, 'error');
  }
};

export const updateRepair = async (
  repairData: RepairData,
  repairId: string,
) => {
  try {
    const { data } = await api.put<Repair>('/repair', {
      ...repairData,
      repairId,
    });
    return data;
  } catch (error) {
    message(error, 'error');
  }
};

// CHECKLIST
export const createChecklist = async (formData: FormData) => {
  try {
    const { data } = await api.post<CheckListRequest>('/checklist', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    message(error, 'error');
    return null;
  }
};
export const removeBanner = async (id_banner: string) => {
  try {
    await api.delete(`/banner/${id_banner}`);
  } catch (error) {
    message(error, 'error');
  }
};
export const createBanner = async (formData: FormData) => {
  try {
    const { data } = await api.post<Banner>('/banner/checklist', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  } catch (error) {
    message(error, 'error');
  }
};
