import { useCallback } from 'react';
import { changeIsWatchingStatusController } from '../../../controller';

const useWatchingState = () => {
  const changeWatchingState = useCallback(
    async (answerQuotationId: string, watchState: 'on' | 'off') => {
      const res = await changeIsWatchingStatusController({
        id: answerQuotationId,
        watchState,
      });
      return res;
    },
    [],
  );

  return { changeWatchingState };
};

export default useWatchingState;
