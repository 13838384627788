import { ChangeEvent, TextareaHTMLAttributes } from 'react';
import styles from './textarea.module.css';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  value?: string;
  handleChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  label?: string;
  description?: string;
  error?: string;
  type?: 'default';
}

const switchPlaceholder = (label: string) => {
  label = label.trim();

  switch (label) {
    case 'Comentários:':
      return 'Informe nesse campo o prazo de entrega ou algum comentário adicional';
    default:
      return '';
  }
};

export const Textarea = ({
  handleChange,
  name,
  value,
  label,
  description,
  error,
  type = 'default',
  ...rest
}: Props) => {
  const commonTextAreaProps = {
    id: name,
    value,
    onChange: handleChange,
    className: error ? styles['textarea-error'] : styles.textarea,
    ...rest,
  };
  switch (type) {
    default:
      return (
        <div className={styles['textarea-control']}>
          {label && <label htmlFor={name}>{label}</label>}
          {description && (
            <span className={styles.description}>{description}</span>
          )}
          <textarea
            {...commonTextAreaProps}
            placeholder={switchPlaceholder(label || '')}
          />
          {error && <span className={styles['error-text']}>{error}</span>}
        </div>
      );
  }
};
