/* eslint-disable react/no-array-index-key */
import styles from './service-table.module.css';

interface Props {
  services: {
    name: string;
    quantity: number;
    unit: number;
  }[];
  discount: number;
}

const formatValue = (value: number) => {
  if (value)
    return value.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
    });
  return '0,00';
};

export const ServiceTable = ({ discount, services }: Props) => {
  let subtotal = 0;
  return (
    <>
      <div className={styles['table-container']}>
        <table className={styles['service-table']}>
          <thead>
            <tr>
              <th className={styles.item}>Item</th>
              <th className={styles.product}>Produto/Serviço</th>
              <th className={styles.quantity}>Qtd.</th>
              <th className={styles.unit}>Unit.</th>
              <th className={styles.subtotal}>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {services &&
              services.map((service, index) => {
                const totalValue = service.quantity * service.unit;
                subtotal += totalValue;
                return (
                  <tr key={index}>
                    <th className={styles.item}>{index + 1}</th>
                    <th className={styles.product}>{service.name}</th>
                    <th className={styles.quantity}>
                      {service.quantity.toFixed(3)}
                    </th>
                    <th className={styles.unit}>{formatValue(service.unit)}</th>
                    <th className={styles.subtotal}>
                      R$ {formatValue(totalValue)}
                    </th>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className={styles.summary}>
        <p>
          <span>Subtotal:</span> <span>R$ {formatValue(subtotal)}</span>
        </p>
        <p className={styles.discount}>
          <span>Desconto (R$):</span> <span>-R$ {formatValue(discount)}</span>
        </p>
        <p>
          <span>Total:</span>{' '}
          <span className={styles.total}>
            R$ {formatValue(subtotal - discount)}
          </span>
        </p>
      </div>
    </>
  );
};
