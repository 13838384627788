import styles from './progressbar.module.css';

interface ProgressBarProps {
  currentPage: number;
  pages: string[];
}

export const ProgressBar = ({ currentPage, pages }: ProgressBarProps) => {
  return (
    <div className={styles.container}>
      {pages.map((page, index) => (
        <div
          className={`${styles.step} ${
            currentPage >= index ? styles.selected : ''
          }`}
        >
          <h1 className={styles.title}>
            {index + 1}. {page}
          </h1>
          <hr />
        </div>
      ))}
    </div>
  );
};
