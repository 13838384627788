import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSocket } from '../hooks/useSocket';
import { AnswerQuotation } from '../@types/interface';
import { getAllMyAnswersController } from '../pages/common/Quotation/autoshop/controller';
import { QUOTATION } from '../constants';
import { usePageVisibility } from '../hooks/usePageVisibility';

interface AnswerQuotationState {
  receivedQuotationList: AnswerQuotation[];
  pendingQuotationList: AnswerQuotation[];
  finalizedQuotationList: AnswerQuotation[];
}

export type AnswerQuotationPages =
  | 'notAnswered'
  | 'approved'
  | 'finished'
  | 'missed';

interface AnswerQuotationContextType {
  answerQuotationState: AnswerQuotationState;
  setAnswerQuotationState: Dispatch<SetStateAction<AnswerQuotationState>>;
  selectedPage: AnswerQuotationPages;
  setSelectedPage: Dispatch<SetStateAction<AnswerQuotationPages>>;
  updateAnswerQuotations: (answerQuotationToUpdate: AnswerQuotation) => void;
  fetchQuotationsAnswers: () => void;
}

const AnswerQuotationContext = createContext<AnswerQuotationContextType | null>(
  null,
);

export const AnswerQuotationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { socketInstance } = useSocket();
  const [isSocketConnected, setIsSocketConnected] = useState(true);

  const [answerQuotationState, setAnswerQuotationState] =
    useState<AnswerQuotationState>({
      receivedQuotationList: [],
      pendingQuotationList: [],
      finalizedQuotationList: [],
    });

  const [selectedPage, setSelectedPage] =
    useState<AnswerQuotationPages>('notAnswered');

  const fetchQuotationsAnswers = useCallback(async () => {
    const res = await getAllMyAnswersController({
      page: 1,
      limit: QUOTATION.REQUEST_LIMIT,
    });

    if (res) {
      const { finished, not_confirmed, recieved } = res;

      setAnswerQuotationState({
        receivedQuotationList: recieved,
        pendingQuotationList: not_confirmed,
        finalizedQuotationList: finished,
      });
    }
  }, []);

  const addNewAnswerQuotation = (
    newAnswer: AnswerQuotation,
    existingAnswerQuotations: AnswerQuotation[],
  ): AnswerQuotation[] => {
    const alreadyExists = existingAnswerQuotations.some(
      answer => answer.id_answer_quotation === newAnswer.id_answer_quotation,
    );

    if (alreadyExists) {
      return existingAnswerQuotations.map(previousAnswer => {
        if (
          previousAnswer.id_answer_quotation === newAnswer.id_answer_quotation
        )
          return newAnswer;
        return previousAnswer;
      });
    }

    return [newAnswer, ...existingAnswerQuotations];
  };

  const removeAnswerQuotation = (
    quotationToRemove: AnswerQuotation,
    existingAnswerQuotations: AnswerQuotation[],
  ): AnswerQuotation[] => {
    const updatedAnswersQuotation = existingAnswerQuotations.filter(
      answer =>
        answer.id_answer_quotation !== quotationToRemove.id_answer_quotation,
    );

    return updatedAnswersQuotation;
  };

  const updateAnswerQuotation = (
    answerQuotationToUpdate: AnswerQuotation,
    existingAnswerQuotations: AnswerQuotation[],
  ) => {
    const updatedAnswerQuotations = existingAnswerQuotations.map(
      existingAnswerQuotation => {
        if (
          existingAnswerQuotation.id_answer_quotation ===
          answerQuotationToUpdate.id_answer_quotation
        ) {
          return answerQuotationToUpdate;
        }
        return existingAnswerQuotation;
      },
    );
    return updatedAnswerQuotations;
  };

  const updateAnswerQuotations = useCallback(
    (answerQuotationToUpdate: AnswerQuotation) => {
      setAnswerQuotationState(previous => ({
        receivedQuotationList: updateAnswerQuotation(
          answerQuotationToUpdate,
          previous.receivedQuotationList,
        ),
        pendingQuotationList: updateAnswerQuotation(
          answerQuotationToUpdate,
          previous.pendingQuotationList,
        ),
        finalizedQuotationList: updateAnswerQuotation(
          answerQuotationToUpdate,
          previous.finalizedQuotationList,
        ),
      }));
    },
    [],
  );

  useEffect(() => {
    socketInstance.on('answer_quotation_excluded', (data: AnswerQuotation) => {
      console.log('excluida', data);
      setAnswerQuotationState(previous => ({
        receivedQuotationList: removeAnswerQuotation(
          data,
          previous.receivedQuotationList,
        ),
        pendingQuotationList: removeAnswerQuotation(
          data,
          previous.pendingQuotationList,
        ),
        finalizedQuotationList: removeAnswerQuotation(
          data,
          previous.finalizedQuotationList,
        ),
      }));
    });
    socketInstance.on('answer_quotation_lost', (data: AnswerQuotation) => {
      console.log('perdida', data);
      setAnswerQuotationState(previous => ({
        receivedQuotationList: removeAnswerQuotation(
          data,
          previous.receivedQuotationList,
        ),
        pendingQuotationList: removeAnswerQuotation(
          data,
          previous.pendingQuotationList,
        ),
        finalizedQuotationList: addNewAnswerQuotation(
          data,
          previous.finalizedQuotationList,
        ),
      }));
    });
    socketInstance.on('answer_quotation_received', (data: AnswerQuotation) => {
      console.log('recebida', data);
      setAnswerQuotationState(previous => ({
        receivedQuotationList: addNewAnswerQuotation(
          data,
          previous.receivedQuotationList,
        ),
        pendingQuotationList: removeAnswerQuotation(
          data,
          previous.pendingQuotationList,
        ),
        finalizedQuotationList: removeAnswerQuotation(
          data,
          previous.finalizedQuotationList,
        ),
      }));
    });

    socketInstance.on('answer_quotation_pending', (data: AnswerQuotation) => {
      console.log('pendente', data);
      setAnswerQuotationState(previous => ({
        receivedQuotationList: removeAnswerQuotation(
          data,
          previous.receivedQuotationList,
        ),
        pendingQuotationList: addNewAnswerQuotation(
          data,
          previous.pendingQuotationList,
        ),
        finalizedQuotationList: removeAnswerQuotation(
          data,
          previous.finalizedQuotationList,
        ),
      }));
    });
    socketInstance.on('answer_quotation_finalized', (data: AnswerQuotation) => {
      console.log('finalizada', data);
      setAnswerQuotationState(previous => ({
        receivedQuotationList: removeAnswerQuotation(
          data,
          previous.receivedQuotationList,
        ),
        pendingQuotationList: removeAnswerQuotation(
          data,
          previous.pendingQuotationList,
        ),
        finalizedQuotationList: addNewAnswerQuotation(
          data,
          previous.finalizedQuotationList,
        ),
      }));
    });
    return () => {
      socketInstance.off('answer_quotation_received');
      socketInstance.off('answer_quotation_pending');
      socketInstance.off('answer_quotation_finalized');
      socketInstance.off('answer_quotation_excluded');
      socketInstance.off('answer_quotation_lost');
    };
  }, [socketInstance]);

  useEffect(() => {
    fetchQuotationsAnswers();
  }, [fetchQuotationsAnswers]);

  useEffect(() => {
    const handleConnect = () => setIsSocketConnected(true);
    const handleDisconnect = () => setIsSocketConnected(false);

    socketInstance.on('connect', handleConnect);
    socketInstance.on('disconnect', handleDisconnect);

    return () => {
      socketInstance.off('connect', handleConnect);
      socketInstance.off('disconnect', handleDisconnect);
    };
  }, [socketInstance]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isSocketConnected) {
        console.log('WebSocket desconectado. Realizando busca manual.');
        fetchQuotationsAnswers();
      }
    }, 5 * 1000); // 5 segundos

    return () => clearInterval(interval);
  }, [isSocketConnected, fetchQuotationsAnswers]);

  const contextValue = useMemo(
    () => ({
      answerQuotationState,
      setAnswerQuotationState,
      selectedPage,
      setSelectedPage,
      updateAnswerQuotations,
      fetchQuotationsAnswers,
    }),
    [
      answerQuotationState,
      fetchQuotationsAnswers,
      selectedPage,
      updateAnswerQuotations,
    ],
  );

  return (
    <AnswerQuotationContext.Provider value={contextValue}>
      {children}
    </AnswerQuotationContext.Provider>
  );
};

export const useAnswerQuotation = () => {
  return useContext(AnswerQuotationContext) as AnswerQuotationContextType;
};
