import styled, { keyframes } from 'styled-components';
import pixel from '../../styles/size';

const fade = keyframes`
  0%{
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
`;

export const MainComponent = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #49505762;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

export const Modal = styled.div`
  position: absolute;
  z-index: 2;
  background: white;
  border-radius: ${pixel(20)};
  width: ${pixel(270)};
  height: ${pixel(280)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${pixel(20)};
  animation-name: ${fade};
  animation-duration: 1s;

  @media only screen and (max-width: 768px) {
    width: 90%;
    height: 50%;
    gap: 25px;
    border-radius: 20px;
  }
`;

export const Text = styled.div`
  width: 70%;
  text-align: center;
  color: var(--green001);
  font-size: ${pixel(26)};
  font-family: 'Segoe UI';
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    width: 90%;
    font-size: 16pt;
  }
`;

export const Image = styled.img`
  width: ${pixel(110)};

  @media only screen and (max-width: 768px) {
    width: 150px;
  }
`;

export const Icon = styled.img`
  position: absolute;
  top: ${pixel(10)};
  right: ${pixel(15)};
  width: ${pixel(10)};

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: 768px) {
    top: 10px;
    right: 15px;
    width: 20px;
    color: #585858;
  }
`;
