import styles from './description-table.module.css';

export const DescriptionTable = () => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Status</h3>
      <div className={styles.wrapper}>
        <span className={styles.row}>
          <div className={`${styles['status-indicator']} ${styles.finished}`} />
          Concluído
        </span>
        <span className={styles.row}>
          <div className={styles['status-indicator']} />
          Em andamento
        </span>
      </div>
    </div>
  );
};
