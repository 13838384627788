import styles from './QuickTipCard.module.css';

interface cardQuickTip {
  icon: string;
  title: string;
  text: string;
}

export const QuickTipCard = ({ icon, title, text }: cardQuickTip) => {
  return (
    <article className={styles.cardQuickTip}>
      <img src={icon} alt="star" />
      <h1>{title}</h1>
      <p>{text}</p>
    </article>
  );
};
