import { Workshop } from '../@types/interface';
import api from '../services/api/api';
import { handleError } from './handleError';

export const getWorkshopData = async () => {
  try {
    const res = await api.get<Workshop>('/user/workshop');
    if (res && res.data) {
      return res.data;
    }
    throw new Error('Invalid data format');
  } catch (error) {
    console.error('Erro ao buscar dados da oficina:', error);
    handleError(error);
    return null;
  }
};

export const getAccessToken = () => {
  const token: string = localStorage.getItem('@AutoCenter: accessToken') ?? '';

  return token;
};
