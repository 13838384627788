import { BrowserRouter } from 'react-router-dom';
import { useEffect, Suspense, lazy, useCallback, useState } from 'react';
import { ReportRoutes } from './reports/routes';
import { CommonRoutes } from './common/routes';
import Fallback from '../shared/Fallback/Fallback';
import { NotificationProvider } from '../contexts/notificationContext';
import { QuotationRoutes } from './quotation/routes';
import { useAuth } from '../hooks/useAuth';

const WorkshopRoutes = lazy(() => import('./workshop/routes'));
const FleetRoutes = lazy(() => import('./fleet/routes'));
const GasStationRoutes = lazy(() => import('./gasStation/routes'));

const Routes = () => {
  const [accessType, setAccessType] = useState<string | null>(null);
  const { workshop, user } = useAuth();

  useEffect(() => {
    setAccessType(user?.role_name ?? '');
  }, [user?.role_name]);

  const handleAccessType = useCallback(() => {
    switch (accessType) {
      case 'agent':
        return (
          <>
            <Suspense fallback={<Fallback />}>
              <QuotationRoutes />
            </Suspense>
            <CommonRoutes />
          </>
        );

      case 'gas_station':
        return (
          <>
            <ReportRoutes workshopData={workshop} />
            <Suspense fallback={<Fallback />}>
              <GasStationRoutes />
            </Suspense>
            <CommonRoutes />
          </>
        );
      case 'fleet':
        return (
          <>
            <ReportRoutes workshopData={workshop} />
            <Suspense fallback={<Fallback />}>
              <FleetRoutes />
            </Suspense>
            <CommonRoutes />
          </>
        );
      case 'workshop':
        return (
          <>
            <ReportRoutes workshopData={workshop} />
            <Suspense fallback={<Fallback />}>
              <WorkshopRoutes />
            </Suspense>
            <CommonRoutes />
          </>
        );
      default:
        return <CommonRoutes />;
    }
  }, [accessType, workshop]);

  if (accessType === null) {
    return <Fallback />;
  }

  return (
    <BrowserRouter>
      <NotificationProvider>{handleAccessType()}</NotificationProvider>
    </BrowserRouter>
  );
};

export default Routes;
