import { Route, Routes as Switch } from 'react-router-dom';

import { BudgetReport } from '../../utils/pdf/Budget';
import { OrderServiceReport } from '../../utils/pdf/OrderService';
import { ChecklistReport } from '../../utils/pdf/ChecklistReport';
import { Workshop } from '../../@types/interface';

interface Props {
  workshopData: Workshop | null;
}

export const ReportRoutes = ({ workshopData }: Props) => (
  <Switch>
    <Route path="/budget-report" element={<BudgetReport />} />
    <Route path="/budgets" element={<BudgetReport />} />
    <Route
      path="/order-service-report"
      element={<OrderServiceReport workshopData={workshopData} />}
    />
    <Route
      path="/transaction-report"
      element={<OrderServiceReport workshopData={workshopData} />}
    />
    <Route path="/checklist-report" element={<ChecklistReport />} />
  </Switch>
);
