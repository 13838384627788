interface Props {
  content: string;
  title: string;
}

export const Section = ({ content, title }: Props) => {
  return `
  <style>
    .section h3 {
      background-color: #e9e9e9;
      color: #1e1e1e;
      border-radius: 8px;
    }
  </style>
  <section class="section">
    <h3>${title}:</h3>
    ${content}
</section>

  `;
};
