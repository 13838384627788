import axios from 'axios';
import { baseURL } from './api';

// export const bucketURL = 'https://autocenter.sfo3.digitaloceanspaces.com';

export interface GetAssignedUrlResponse {
  url: string;
  filename: string;
  image_url: string;
  key: string;
}
const accessToken = localStorage.getItem('@AutoCenter: accessToken') ?? '';

export const getAssignedUrl = async (filename: string, path: string) => {
  const { data } = await axios.get<GetAssignedUrlResponse>(
    `${baseURL}bucket/${path}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        filename,
      },
    },
  );
  return data;
};

export const sendBucketImage = async (file: File, assignedUrl: string) => {
  const { data } = await axios.put(assignedUrl, file, {
    headers: {
      'Content-Type': file.type,
      'Cache-Control': 'public,max-age=31536000,immutable',
      'x-amz-acl': 'public-read',
    },
  });
  return data;
};

export const deleteBucketImage = async (filepath: string) => {
  const { data } = await axios.delete(`${baseURL}bucket/delete`, {
    headers: {
      // 'Content-Type': file.type,
      // 'Cache-Control': 'public,max-age=31536000,immutable',
      // 'x-amz-acl': 'public-read',
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      filepath,
    },
    // data: {
    //   filepath,
    // },
  });
  return data;
};
