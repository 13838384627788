import { useEffect, useRef, useCallback } from 'react';

const useInfiniteScroll = (onScroll: () => void, delay = 200) => {
  const contentRef = useRef<null | HTMLDivElement>(null);
  const timeoutRef = useRef<number | null>(null);

  const handleScroll = useCallback(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      const listContent = contentRef.current;

      if (
        listContent &&
        listContent.scrollTop + listContent.clientHeight >=
          listContent.scrollHeight * 0.9
      ) {
        onScroll();
      }
    }, delay);
  }, [onScroll, delay]);

  useEffect(() => {
    const listContent = contentRef.current;

    if (listContent) {
      listContent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listContent) {
        listContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return contentRef;
};

export default useInfiniteScroll;
