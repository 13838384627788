import { BudgetProps } from '../../../features/budget/interfaces';
import { getWorkshopData } from '../../../utils/workshopData';
import api from '../../api/api';

export class BudgetDataFetcher {
  // eslint-disable-next-line class-methods-use-this
  async fetchOrderServices(limit: number, page: number) {
    const workshopData = await getWorkshopData();
    try {
      const response = await api.get<BudgetProps[]>('/budget', {
        params: {
          workshopId: workshopData?.id_workshop ?? '',
          limit,
          page,
        },
      });
      return response.data || null;
    } catch (error) {
      console.error();
    }
  }
}
