import styles from './loading-spinner.module.css';

type LoadingSpinnerProps = {
  size?: number;
  border?: 'sm' | 'md' | 'lg';
};

export const LoadingSpinner = ({
  size = 24,
  border = 'sm',
}: LoadingSpinnerProps) => {
  return (
    <span
      className={`${styles.loader} ${styles[border]}`}
      style={{
        height: size,
        width: size,
      }}
    />
  );
};
