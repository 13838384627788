import { ReactNode } from 'react';
import styles from './section.module.css';

interface Props {
  title: string;
  children: ReactNode;
}

export const Section = ({ children, title }: Props) => {
  return (
    <section className={styles.section}>
      <h3>{title}:</h3>
      {children}
    </section>
  );
};
