import { ChangeEvent, useState } from 'react';
import { MagnifyingGlass } from 'phosphor-react';
import styles from './search-list.module.css';
import { CommonInputProps, Option } from '../..';

interface Props {
  label?: string;
  commonInputAttributes: CommonInputProps;
  name: string;
  options: Option[];
  handleIconSearch?: () => void;
  handleClearImage?: () => void;
  handleOptionClick?: (option: Option) => void;
  handleChange?: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  error?: string;
  isLoading?: boolean;
}

export const SearchList = ({
  commonInputAttributes,
  name,
  label,
  options,
  handleChange,
  handleIconSearch,
  handleOptionClick,
  error,
  isLoading,
}: Props) => {
  const [shouldShowOptions, setShouldShowOptions] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles['input-control']}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={styles['input-icon-container']}>
        <input
          type="search"
          {...commonInputAttributes}
          onChange={e => {
            if (handleChange) handleChange(e);
            if (e.target.value.trim().length !== 0) {
              setShouldShowOptions(true);
              // setIsOpen(true);
            } else {
              setShouldShowOptions(false);
              // setIsOpen(false);
            }
            // toggleSelect();
          }}
          onClick={() => {
            setShouldShowOptions(true);
          }}
          onBlur={() => {
            setTimeout(() => {
              setShouldShowOptions(false);
              // setIsOpen(false);
            }, 225);
          }}
        />
        <span className={styles['input-icon']}>
          <button type="button" onClick={handleIconSearch}>
            <MagnifyingGlass size={18} />
            {/* {isOpen ? <X size={18} /> : <MagnifyingGlass size={18} />} */}
          </button>
        </span>
      </div>
      {!isLoading && shouldShowOptions && (
        <ul className={styles['searchList-options']}>
          {options.map(option => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <li
              key={option.name}
              onClick={() => {
                console.log('option', option);
                if (handleOptionClick) {
                  handleOptionClick(option);
                }
                setShouldShowOptions(false);
                // setIsOpen(false);
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
      {isLoading && (
        <ul className={styles['searchList-options']}>
          <li>Buscando na base de dados...</li>
        </ul>
      )}
      {error && <span className={styles['error-text']}>{error}</span>}
    </div>
  );
};
