/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import { EffectFade, Pagination } from 'swiper';
import { useEffect, useState } from 'react';
import styles from './page-services.module.css';
import imageFipe from '../../../../../assets/img/LandingPage/landig-page-carrocel-image-1.png';
import imageParcerias from '../../../../../assets/img/LandingPage/landig-page-carrocel-image-2.png';
import imageDesempenho from '../../../../../assets/img/LandingPage/landig-page-carrocel-image-3.png';
import backgroundPatternLeft from '../../../../../assets/img/LandingPage/BackgroundPatternServices.svg';
import backgroundPatternRight from '../../../../../assets/img/LandingPage/BackgroundPatternService2.svg';

import { getMobilePropaganda, getPageService } from './text';
import { Container } from '../../Components/Container/Container';
import { InfoBlock } from '../../Components/InfoBlock/InfoBlock';

const Services = () => {
  const [service, setService] = useState('');
  const [pageService, setPageService] = useState('Oficina');

  useEffect(() => {
    if (service === 'POSTOS') {
      setPageService('Postos');
    } else if (service === 'FROTAS') {
      setPageService('Frotas');
    } else {
      setPageService('Oficina');
    }

    return setService(prev => prev);
  }, [service]);

  return (
    <Container setTypeService={data => setService(data)}>
      <div className={styles.container}>
        <img
          className={styles.backgroundPattern2}
          src={backgroundPatternLeft}
          alt=""
        />
        <div className={styles.section}>
          <div className={styles.title}>
            <h1>
              {pageService === 'Oficina'
                ? 'OFICINAS'
                : pageService === 'Frotas'
                ? 'FROTAS'
                : 'POSTOS'}
            </h1>
          </div>
          <div className={styles.textLeft}>
            <InfoBlock
              image={getMobilePropaganda().image}
              title={getMobilePropaganda().h1}
              text={getMobilePropaganda().p}
              reverse
            >
              <div className={styles.botoesAplicativo}>
                <button
                  className={styles.botaoIOS}
                  type="button"
                  onClick={() => {
                    window.open(
                      'https://apps.apple.com/us/app/autocenter-app/id1637895498',
                      '_blank',
                    );
                  }}
                />
                <button
                  className={styles.botaoAndroid}
                  type="button"
                  onClick={() => {
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.autocenterapp',
                      '_blank',
                    );
                  }}
                />
              </div>
            </InfoBlock>
          </div>
          <div className={styles.textRight}>
            <InfoBlock
              image={getPageService(pageService).section1.image}
              title={getPageService(pageService).section1.h1}
              text={getPageService(pageService).section1.p}
            />
          </div>
          <div className={styles.textLeft}>
            <InfoBlock
              image={getPageService(pageService).section2.image}
              title={getPageService(pageService).section2.h1}
              text={getPageService(pageService).section2.p}
              reverse
            />
          </div>
          <div className={styles.textRight}>
            <InfoBlock
              image={getPageService(pageService).section3.image}
              title={getPageService(pageService).section3.h1}
              text={getPageService(pageService).section3.p}
            />
          </div>
          <div className={styles.lastText}>
            <div className={styles.titleSection}>
              <h2>Mais Que Serviços, Soluções Completas</h2>
              <p>
                Explore um leque de possibilidades conosco e transforme desafios
                em oportunidades. Vá além com nossos serviços exclusivos,
                projetados para atender às suas necessidades específicas.
                Descubra as vantagens que fazem a diferença e impulsionam o seu
                sucesso.
              </p>
            </div>
            <div className={styles.imageGroup}>
              <div style={{ backgroundImage: `url("${imageFipe}")` }} />
              <div style={{ backgroundImage: `url("${imageParcerias}")` }} />
              <div style={{ backgroundImage: `url("${imageDesempenho}")` }} />
            </div>
            <div className={styles.carousel}>
              <Swiper
                modules={[EffectFade, Pagination]}
                spaceBetween={50}
                slidesPerView={1}
                centeredSlides
                pagination={{ clickable: true }}
                effect="fade"
                className="mySwiper"
              >
                <SwiperSlide>
                  <div
                    style={{ backgroundImage: `url("${imageFipe}")` }}
                    className={styles.carouselImage}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    style={{ backgroundImage: `url("${imageParcerias}")` }}
                    className={styles.carouselImage}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    style={{ backgroundImage: `url("${imageDesempenho}")` }}
                    className={styles.carouselImage}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <img
          className={styles.backgroundPattern1}
          src={backgroundPatternRight}
          alt=""
        />
      </div>
    </Container>
  );
};

export default Services;
