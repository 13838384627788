import { useCallback, useEffect, useState } from 'react';
import {
  BsCalendarCheck,
  BsCardText,
  BsGeoAlt,
  BsPalette,
  BsWrench,
  BsCheckSquare,
} from 'react-icons/bs';
import { CarSimple, GasPump, WhatsappLogo } from '@phosphor-icons/react';
import { VehicleModalProps } from './interfaces';
import { InfoRow } from './components/InfoRow';
import { fetchVehicleDataByLicensePlateController } from '../../services/dataFetchers/VehicleDataFetcher/VehicleDataFetcher';
import { formatVehicleYear } from '../../utils/formatters/vehicleFormat';
import { handleHtmlToPdf } from '../../utils/pdf/handleHtmlToPdf';
import { searchVehiclePdf } from '../../utils/pdf/searchVehiclePdf';
import { isWebView } from '../../utils/detectUserAgent';
// import { visitorId } from '../../routes/routes';
import { Modal } from '../Modal';
import styles from './vehicle-modal.module.css';
import { Button } from '../Button';
import { COLORS } from '../../styles/colors';
import { VehicleResponse } from '../../services/dataFetchers/VehicleDataFetcher/model';

export const VehicleModal = ({
  isOpen,
  onClose,
  licensePlate,
}: VehicleModalProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [vehicleData, setVehicleData] = useState<VehicleResponse | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleVehicle = useCallback(async () => {
    try {
      setError(null);
      const res = await fetchVehicleDataByLicensePlateController(licensePlate);

      if (res) {
        setVehicleData(res);
      } else {
        onClose();
      }
      setIsLoading(false);
    } catch (err) {
      setError('Não foi possível encontrar o veículo.');
      setIsLoading(false);
    }
  }, [licensePlate, onClose]);

  const handleSendPdf = () => {
    const html = searchVehiclePdf({
      ...vehicleData,
      licensePlate,
    });
    if (isWebView === '1') {
      const data = {
        name: 'pdf',
        html,
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    } else {
      handleHtmlToPdf(html, `Autocenter-${licensePlate}`);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);

      // const isVisitor = userLocal && userLocal.id_user === visitorId;
      // const consults = parseInt(localStorage.getItem('consults') || '0', 10);
      // const exceededLimit = isVisitor && consults >= 3;

      // if (!exceededLimit) {
      //   if (isVisitor) {
      //     localStorage.setItem('consults', `${consults + 1}`);
      //   }
      handleVehicle();
      // }
    }
    return () => {
      setError(null);
      setVehicleData(null);
      setIsLoading(true);
    };
  }, [handleVehicle, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setVehicleData(null);
      }}
      isLoading={isLoading}
      title="Consulta de veículo"
      size="large"
    >
      {error ? (
        <p>Ocorreu um erro: {error}</p>
      ) : (
        <>
          <div className={styles.container}>
            <div className={styles.heading}>
              <div className={styles.plate}> {licensePlate}</div>
              <div className={styles.brand}>
                <img
                  src={vehicleData?.img_url || ''}
                  alt={vehicleData?.brand || ''}
                />
              </div>
            </div>
            <Button
              icon={<WhatsappLogo color="#fff" size={24} />}
              style={{
                background: COLORS.GREEN,
                alignSelf: 'end',
              }}
              handleClick={handleSendPdf}
            >
              Compartilhar
            </Button>
          </div>
          <div className={styles.content}>
            <InfoRow
              name="Marca"
              value={vehicleData?.brand}
              icon={<BsCheckSquare size="30" />}
            />
            <InfoRow
              name="Modelo/Versão"
              value={vehicleData?.model}
              icon={<CarSimple size="30" />}
            />
            <InfoRow
              name="Ano"
              value={(vehicleData && formatVehicleYear(vehicleData)) || '--'}
              icon={<BsCalendarCheck size="30" />}
            />
            <InfoRow
              name="Cor"
              value={vehicleData?.color}
              icon={<BsPalette size="30" />}
            />
            <InfoRow
              name="Combustível"
              value={vehicleData?.fuel}
              icon={<GasPump size="30" />}
            />

            <InfoRow
              name="Município"
              value={vehicleData?.uf}
              icon={<BsGeoAlt size="30" />}
            />

            <InfoRow
              name="Chassi"
              value={vehicleData?.chassi}
              icon={<BsCardText size="30" />}
            />
            <InfoRow
              name="Motor"
              value={vehicleData?.engine}
              icon={<BsWrench size="30" />}
            />
          </div>
        </>
      )}
    </Modal>
  );
};
