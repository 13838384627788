import { useCallback } from 'react';
import { AlertMessage } from '../../../../../../../../shared/AlertMessage';
import useSelectedQuotation from '../../../../hooks/useSelectedQuotation';
import useRemoveDraftModal from '../../../../hooks/useRemoveDraftModal';
import { deleteQuotationController } from '../../../../controller';
import useFeedbackModal from '../../../../hooks/useFeedbackModal';

export const RemoveDraftModal = () => {
  const selectedQuotation = useSelectedQuotation();
  const removeDraftModal = useRemoveDraftModal();
  const feedbackModal = useFeedbackModal();

  const handleClose = useCallback(() => {
    removeDraftModal.onClose();
  }, [removeDraftModal]);

  const handleRemoveDraft = useCallback(async () => {
    try {
      removeDraftModal.onClose();
      feedbackModal.onOpen();
      await deleteQuotationController(
        selectedQuotation.quotation?.id_quotation ?? '',
      );
      selectedQuotation.onClear();
    } catch (error) {
      console.error(error);
    } finally {
      feedbackModal.onClose();
    }
  }, [
    selectedQuotation.onClear,
    selectedQuotation.quotation?.id_quotation,
    feedbackModal.onClose,
    feedbackModal.onOpen,
  ]);

  return (
    <AlertMessage.Root
      isOpen={removeDraftModal.isOpen}
      onClose={handleClose}
      title="Excluir Rascunho?"
      footer={
        <AlertMessage.Footer>
          <>
            <AlertMessage.ConfirmButton onClick={handleClose}>
              Revisar
            </AlertMessage.ConfirmButton>
            <AlertMessage.CancelButton onClick={handleRemoveDraft}>
              Excluir
            </AlertMessage.CancelButton>
          </>
        </AlertMessage.Footer>
      }
    >
      <AlertMessage.Body>
        <p>
          Excluir o rascunho{' '}
          <b>{`N° ${selectedQuotation.quotation?.QUOTATION ?? ''}`}</b>?
        </p>
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
