import { useRef } from 'react';
import { PageLayout } from '../../../../../../../../shared/PageLayout';
import styles from './answer-quotation-modal.module.css';
import { useAnswerQuotation } from './useAnswerQuotation';
import {
  AnswerQuotation,
  AnswerQuotation as AnswerQuotationResponse,
} from '../../../../../../../../@types/interface';
import { Overlay } from '../../../../../../../../shared/Overlay';
import { QuotationDescription } from '../QuotationDescription';
import { AnswersList } from '../AnswersList';
import { AnswerFeedbackModal } from '../AnswerFeedbackModal';
import { ConfirmAnswerModal } from '../ConfirmAnswerModal';
import { ErrorModal } from '../ErrorModal';
import { useResponsive } from '../../../../../../../../hooks/useResponsive';
import {
  ModalContent,
  ModalTitle,
} from '../../../../../../Finance/components/Modal';
import { useAuth } from '../../../../../../../../hooks/useAuth';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  answer: AnswerQuotationResponse | null;
  localAnswerId?: string;
  onUpdate: (res: AnswerQuotation) => void;
}

const WatchingModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => (
  <ModalContent open={open} onClose={onClose}>
    <ModalTitle>Cotação Sendo Respondida</ModalTitle>
    <p>
      Ops! Parece que essa cotação já está sendo respondida por outro vendedor.
    </p>
  </ModalContent>
);

export const AnswerQuotationModal = ({
  isOpen,
  onClose,
  answer = null,
  localAnswerId = '',
  onUpdate,
}: Props) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const container = overlayRef.current || document.body;

  const {
    control,
    reset,
    status,
    handleResetStatus,
    isFeedbackModalOpen,
    closeErrorModal,
    errorModalMessage,
    formQuotationItems,
    selectedAnswer,
    isConfirmModalOpen,
    handleConfirmModal,
    toggleModal,
    handleCloseModal,
    handleWhatsappMessage,
    addSubItem,
    removeSubItem,
    handleCloseErrorModal,
    trigger,
    setIsAnswerWatching,
    isWatchingByOtherAgent,
  } = useAnswerQuotation({ answer, isOpen, localAnswerId, onClose, onUpdate });
  const { isAgent } = useAuth();

  const { sizeMobile } = useResponsive();

  const handleModalNavigation = () => {
    onClose();
    handleCloseModal();
    setIsAnswerWatching(false);
  };

  if (!isOpen) return null;

  return (
    <>
      <ConfirmAnswerModal
        isOpen={isConfirmModalOpen}
        onClose={() => toggleModal('close')}
        container={container}
        onCancel={() => toggleModal('close')}
        onConfirm={handleConfirmModal}
      />
      <ErrorModal
        container={container}
        isOpen={errorModalMessage.trim().length !== 0}
        onClose={closeErrorModal}
        message={errorModalMessage}
      />
      <WatchingModal
        open={isWatchingByOtherAgent && isAgent}
        onClose={onClose}
      />
      <AnswerFeedbackModal
        container={container}
        isOpen={isFeedbackModalOpen}
        status={status}
        onClose={() => {
          if (status === 'error') {
            handleCloseErrorModal();
          } else {
            handleResetStatus();
            onClose();
          }
        }}
      />
      <Overlay ref={overlayRef}>
        <PageLayout.Root
          title="Responder cotação"
          sectionStyle={{ background: 'var(--white)' }}
          handleNavigation={handleModalNavigation}
          contentStyle={{ padding: !sizeMobile ? '1rem 0' : '' }}
        >
          <div className={styles.content}>
            <div>
              <QuotationDescription answer={selectedAnswer} />
              <AnswersList
                answer={selectedAnswer}
                control={control}
                reset={reset}
                handleAnswer={async () => {
                  const isValid = await trigger();
                  if (isValid) toggleModal('open');
                }}
                formQuotationItems={formQuotationItems}
                handleWhatsappMessage={handleWhatsappMessage}
                addSubItem={addSubItem}
                removeSubItem={removeSubItem}
                onClose={onClose}
              />
            </div>
          </div>
        </PageLayout.Root>
      </Overlay>
    </>
  );
};
