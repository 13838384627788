import { ArchiveBox, CheckSquare, CopySimple } from '@phosphor-icons/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApprovedQuotations } from './pages/ApprovedQuotations';
import { FinishedQuotations } from './pages/FinishedQuotations';
import { NotAnsweredQuotations } from './pages/NotAnsweredQuotations';

import { PageLayout } from '../../../../shared/PageLayout';
import { Circle } from '../../../../shared/Circle';
import { useResponsive } from '../../../../hooks/useResponsive';

import {
  getObjectFromLocal,
  removeObjectFromLocal,
} from '../../../../utils/localStorage';
import { MobileHeaderAnswerQuotation } from './components/MobileHeaderAnswerQuotation';
import {
  AnswerQuotationPages,
  useAnswerQuotation,
} from '../../../../contexts/answerQuotationContext';
import useAnswerModal from './hooks/useAnswerModal';
import useConfirmModal from './hooks/useConfirmModal';
import useSelectedAnswer from './hooks/useSelectedAnswer';
import useViewModal from './hooks/useViewModal';
import { FeedbackModal } from '../../../../shared/FeedbackModal';
import useFeedbackModal from '../workshop/hooks/useFeedbackModal';
import { usePageVisibility } from '../../../../hooks/usePageVisibility';

export const AutoshopQuotation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    selectedPage,
    setSelectedPage,
    answerQuotationState,
    fetchQuotationsAnswers,
  } = useAnswerQuotation();

  const localAnswerId = getObjectFromLocal('answerId');

  const answerModal = useAnswerModal();
  const confirmModal = useConfirmModal();
  const selectedAnswer = useSelectedAnswer();
  const viewModal = useViewModal();
  const feedbackModal = useFeedbackModal();

  useEffect(() => {
    if (localAnswerId && localAnswerId.length > 0) {
      removeObjectFromLocal('answerId');
      setSelectedPage('notAnswered');
    }
  }, [localAnswerId, navigate, setSelectedPage]);

  const renderSelectedPage = useMemo(() => {
    switch (selectedPage) {
      case 'notAnswered':
        return <NotAnsweredQuotations />;
      case 'approved':
        return <ApprovedQuotations />;
      case 'finished':
        return <FinishedQuotations />;
      default:
        return <NotAnsweredQuotations />;
    }
  }, [selectedPage]);

  const handleSelectedTitle = useMemo(() => {
    switch (selectedPage) {
      case 'notAnswered':
        return 'Responder Cotação';
      case 'approved':
        return 'cotações aprovadas';
      case 'finished':
        return 'cotações finalizadas';
      default:
        return 'Responder Cotação';
    }
  }, [selectedPage]);

  const { sizeMobile } = useResponsive();

  const notAnsweredQuotationCounter = useMemo(
    () =>
      answerQuotationState.receivedQuotationList.filter(
        answer => !answer.visualized,
      ).length,
    [answerQuotationState.receivedQuotationList],
  );
  const notApprovedQuotationCounter = useMemo(
    () =>
      answerQuotationState.pendingQuotationList.filter(
        answer => !answer.visualized,
      ).length,
    [answerQuotationState.pendingQuotationList],
  );

  const handlePageNavigate = useCallback(() => {
    const pageToNavigate = searchParams.get('page');

    if (pageToNavigate) {
      setSelectedPage(pageToNavigate as AnswerQuotationPages);
    }
  }, [searchParams, setSelectedPage]);

  useEffect(() => {
    handlePageNavigate();
  }, [handlePageNavigate, searchParams]);

  useEffect(() => {
    return () => {
      answerModal.onClose();
      confirmModal.onClose();
      selectedAnswer.onClear();
      viewModal.onClose();
    };
  }, []);

  const refetchQuotationsAnswers = useCallback(async () => {
    feedbackModal.onOpen();
    await fetchQuotationsAnswers();
    feedbackModal.onClose();
  }, [fetchQuotationsAnswers]);

  const saveExitTime = useCallback(() => {
    localStorage.setItem('exitTime', Date.now().toString());
  }, []);
  const checkReturnTime = useCallback(() => {
    const exitTime = localStorage.getItem('exitTime');

    if (exitTime) {
      const timeElapsed = Date.now() - parseInt(exitTime, 10);
      // Verifica se o tempo fora do app
      if (timeElapsed >= 60 * 1000) {
        refetchQuotationsAnswers(); // Recarrega os dados se o tempo foi suficiente
      }
    } else {
      // recarrega caso não haja um problema no salvamento do momento da saída
      refetchQuotationsAnswers();
    }
    // Limpa o registro de saída após retornar
    localStorage.removeItem('exitTime');
  }, [refetchQuotationsAnswers]);

  usePageVisibility({
    onVisible: checkReturnTime,
    onHidden: saveExitTime,
  });

  const handleSwitchComponent = () => {
    if (!sizeMobile) {
      return (
        <>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'notAnswered'}
            onClick={() => setSelectedPage('notAnswered')}
          >
            <ArchiveBox size={32} weight="fill" />
            {notAnsweredQuotationCounter > 0 &&
              `(${notAnsweredQuotationCounter}) `}
            Recebidas
            {notAnsweredQuotationCounter > 0 && (
              <Circle animation="pulse" variant="notification" />
            )}
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'approved'}
            onClick={() => setSelectedPage('approved')}
          >
            {notApprovedQuotationCounter > 0 &&
              `(${notApprovedQuotationCounter})`}
            <CheckSquare size={32} weight="fill" />

            {notApprovedQuotationCounter > 0
              ? 'Aguardando Confirmar Pedido'
              : 'Confirmar Pedido'}

            {notApprovedQuotationCounter > 0 && (
              <Circle animation="pulse" variant="notification" />
            )}
          </PageLayout.TopMenuButton>
          {/* <PageLayout.TopMenuButton
            isSelected={selectedPage === 'missed'}
            onClick={() => setSelectedPage('missed')}
          >
            <LockSimple size={32} weight="fill" />
            Perdidas
          </PageLayout.TopMenuButton> */}
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'finished'}
            onClick={() => setSelectedPage('finished')}
          >
            <CopySimple size={32} weight="fill" />
            Finalizadas
          </PageLayout.TopMenuButton>
        </>
      );
    }
    return (
      <MobileHeaderAnswerQuotation
        notAnsweredCounter={notAnsweredQuotationCounter}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />
    );
  };

  const handleSwitchNavigate = () => {
    if (selectedPage === 'notAnswered') return navigate('/home');
    if (selectedPage === 'approved') return setSelectedPage('notAnswered');
    if (selectedPage === 'finished') return setSelectedPage('notAnswered');
  };

  return (
    <PageLayout.Root
      title={handleSelectedTitle}
      topMenu={handleSwitchComponent()}
      handleNavigation={() => {
        if (sizeMobile) {
          handleSwitchNavigate();
        } else {
          navigate('/home');
        }
      }}
    >
      <FeedbackModal isOpen={feedbackModal.isOpen} variant="sending" />
      {renderSelectedPage}
    </PageLayout.Root>
  );
};
