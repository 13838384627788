import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './not-answered-quotations.module.css';
import answeredQuotationsIcon from '../../../../../../assets/icons/answered-quotations.svg';
import { useNotAnsweredQuotations } from './hooks/useNotAnsweredQuotations';
import { FilterComponent } from '../../../../../../shared/FilterComponent';

import {
  getObjectFromLocal,
  removeObjectFromLocal,
} from '../../../../../../utils/localStorage';

import { ViewAnswerQuotationModal } from '../../../workshop/components/ViewAnswerQuotationModal';

import { AnswerQuotation } from '../../../../../../@types/interface';
import { useFilteredAnswers } from '../../../autoshop/hooks/useFilteredAnswers';

import { GetMyAnswerFilters } from '../../../autoshop/interface';
import { AnswerQuotationModal } from '../../../autoshop/pages/NotAnsweredQuotations/components/AnswerQuotationModal';
import useSelectedAnswer from '../../hooks/useSelectedAnswer';
import { AnswerList } from './components/QuotationListItem';
import useViewModal from '../../hooks/useViewModal';
import useAnswerModal from '../../hooks/useAnswerModal';
import { useAnswerQuotation } from '../../../../../../contexts/answerQuotationContext';
import useWatchingState from './hooks/useWatchingState';
import { updateAnswerQuotationWatchingStateController } from '../../../autoshop/controller';
import { useAuth } from '../../../../../../hooks/useAuth';

export const NotAnsweredQuotations = () => {
  const {
    isLoading,
    loadMoreData,
    page,
    setIsAnswerQuotationModalOpen,
    handleRowClick,
    fetchQuotationAnswerById,
  } = useNotAnsweredQuotations();
  const [searchParams, setSearchParams] = useSearchParams();

  const { updateAnswerQuotations, answerQuotationState } = useAnswerQuotation();

  const {
    filterType,
    filterValue,
    handleDateSearch,
    handleSearch,
    searchedAnswers,
    setFilterType,
  } = useFilteredAnswers({
    option: 'recieved',
  });

  const localAnswerId = getObjectFromLocal('answerId');

  useEffect(() => {
    if (localAnswerId && localAnswerId.length > 0) {
      removeObjectFromLocal('answerId');
      setIsAnswerQuotationModalOpen(true);
    }
  }, [localAnswerId, setIsAnswerQuotationModalOpen]);

  const viewModal = useViewModal();
  const answerModal = useAnswerModal();
  const selectedAnswer = useSelectedAnswer();

  const { changeWatchingState } = useWatchingState();

  const handleGetQuotationByIdParam = useCallback(async () => {
    const answerId = searchParams.get('id');
    if (answerId) {
      await fetchQuotationAnswerById(answerId);
      if (selectedAnswer.answer) {
        if (selectedAnswer.answer.answered) {
          viewModal.onOpen();
        } else {
          answerModal.onOpen();
          // se ainda não estiver vinculado a um agente
          if (!selectedAnswer.answer?.watching) {
            changeWatchingState(
              selectedAnswer.answer.id_answer_quotation,
              'on',
            );
          }
        }
      }
      setSearchParams({});
    }
  }, [
    searchParams,
    fetchQuotationAnswerById,
    selectedAnswer.answer,
    setSearchParams,
    viewModal,
    answerModal,
    changeWatchingState,
  ]);

  useEffect(() => {
    handleGetQuotationByIdParam();
  }, [handleGetQuotationByIdParam]);

  const isEmpty = useMemo(() => {
    return (
      !isLoading && answerQuotationState.receivedQuotationList.length === 0
    );
  }, [answerQuotationState.receivedQuotationList.length, isLoading]);

  const notFound = useMemo(() => {
    return filterValue.trim().length > 0 && searchedAnswers.length === 0;
  }, [filterValue, searchedAnswers.length]);

  const { user } = useAuth();

  const handleCloseAnswerModal = useCallback(async () => {
    try {
      const answerId = selectedAnswer.answer?.id_answer_quotation || '';
      const userAgentId = user?.agents?.[0]?.id_agent;
      const answerAgentId = selectedAnswer.answer?.agent_watch_id;

      // Verificação se o usuário atual é o responsável pelo "watching"
      if (answerAgentId === userAgentId) {
        console.log('O agente atual está assistindo. Liberando o watching...');
        await updateAnswerQuotationWatchingStateController(answerId, 'off');
      } else {
        console.log(
          'A cotação não pertence ao usuário atual. Não será alterada.',
        );
      }
    } catch (error) {
      console.error('Falha ao atualizar o estado do watching:', error);
    } finally {
      // Resetando os estados finais, independente do sucesso ou falha
      selectedAnswer.onClear();
      answerModal.onClose();
    }
  }, [answerModal, selectedAnswer, user?.agents]);

  return (
    <>
      <ViewAnswerQuotationModal
        answer={selectedAnswer.answer}
        isOpen={viewModal.isOpen}
        onClose={() => {
          selectedAnswer.onClear();
          viewModal.onClose();
        }}
        onUpdate={updateAnswerQuotations}
      />
      <AnswerQuotationModal
        localAnswerId={localAnswerId || ''}
        isOpen={answerModal.isOpen}
        onClose={handleCloseAnswerModal}
        answer={selectedAnswer.answer}
        onUpdate={updateAnswerQuotations}
      />
      <div className={styles.heading}>
        <div className={styles.describe}>
          <img src={answeredQuotationsIcon} alt="responder cotações" />
          <p>Cotações recebidas</p>
        </div>
        <div className={styles.actions}>
          <FilterComponent
            handleDateSearch={handleDateSearch}
            handleSearch={handleSearch}
            handleFilterChange={(filter: string) =>
              setFilterType(filter as GetMyAnswerFilters)
            }
            defaultValue="code"
            variant={filterType === 'date_recieved' ? 'date' : 'searchbar'}
            onlyNumber={filterType === 'code'}
            options={[
              {
                label: 'Número',
                value: 'code',
              },
              {
                label: 'Data',
                value: 'date_recieved',
              },
              {
                label: 'Placa',
                value: 'plate',
              },
              {
                label: 'Origem',
                value: 'origin',
              },
            ]}
          />
        </div>
      </div>
      <AnswerList
        answerList={
          filterValue.trim().length > 0
            ? searchedAnswers
            : answerQuotationState.receivedQuotationList
        }
        handleRowClick={(answer: AnswerQuotation) => handleRowClick(answer)}
        onScroll={() => loadMoreData(page)}
        isLoading={isLoading}
        isEmpty={isEmpty}
        notFound={notFound}
      />
    </>
  );
};
