import { useEffect, useMemo } from 'react';
import { Modal } from '../Modal';
import styles from './send-os-modal.module.css';
import { Button } from '../Button';

interface SendOSModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  status: 'sending' | 'error' | 'success';
  isEditing?: boolean;
}

export const SendOSModal = ({
  isOpen,
  onConfirm,
  status,
  isEditing = false,
}: SendOSModalProps) => {
  useEffect(() => {
    document.body.style.overflow = 'auto';
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0, 0);
    }
  }, [isOpen]);

  const isLoading = useMemo(() => {
    return status === 'sending';
  }, [status]);

  return (
    <Modal
      isOpen={isOpen}
      title={isEditing ? 'Editar OS' : 'Nova OS'}
      isLoading={isLoading}
      footer={
        !isLoading ? (
          <Button handleClick={onConfirm} className={styles['ok-btn']}>
            OK
          </Button>
        ) : null
      }
    >
      <div
        className={`${styles.container} ${
          status === 'success' ? styles.success : ''
        }`}
      >
        <p>
          {status === 'success' &&
            `OS ${isEditing ? 'atualizada ' : 'criada'} com sucesso!`}
          {status === 'error' && 'Ocorreu um erro, tente novamente!'}
        </p>
      </div>
    </Modal>
  );
};
