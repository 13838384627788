import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DashboardState {
  totalEarnings: number;
  totalServices: number;
  totalCustomers: number;
  totalAssessments: number;
}

const initialState: DashboardState = {
  totalEarnings: 0,
  totalServices: 0,
  totalCustomers: 0,
  totalAssessments: 0,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateTotalEarned: (state, action: PayloadAction<number>) => {
      state.totalEarnings = action.payload;
    },
    updateTotalServices: (state, action: PayloadAction<number>) => {
      state.totalServices = action.payload;
    },
    updateTotalCustomers: (state, action: PayloadAction<number>) => {
      state.totalCustomers = action.payload;
    },
    updateTotalAssessments: (state, action: PayloadAction<number>) => {
      state.totalAssessments = action.payload;
    },
  },
});

export const {
  updateTotalEarned,
  updateTotalServices,
  updateTotalCustomers,
  updateTotalAssessments,
} = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
