import { client } from './config';
import { IElasticProvider, ICreateElasticDocument } from './interfaces';

const elastic: IElasticProvider = {
  create: ({ body, index }: ICreateElasticDocument) => {
    const currentDate = new Date().toISOString();
    const document = {
      '@timestamp': currentDate,
      page: window.location.href,
      ...body,
    };
    fetch(`${client.ELASTIC_URL}/${index}/_doc`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: client.ELASTIC_AUTH,
      },
      body: JSON.stringify(document),
    });
  },
};

const elasticProvider = Object.freeze(elastic);

export default elasticProvider;
