import platform from 'platform';
import elasticProvider from './elasticProvider';

const logErrorToElastic = async (type: string, additionalData: any = {}) => {
  const osInfo = platform.os;
  const deviceInfo = platform.product || 'Unknown device';
  const browserInfo = `${platform.name} ${platform.version}`;
  const engineInfo = platform.layout;

  const errorData = {
    type, // Tipo de erro: 'axios' ou 'socket'
    os: `${osInfo}`,
    device: deviceInfo,
    browser: browserInfo,
    engine: engineInfo,
    ...additionalData, // Dados específicos do tipo de erro
  };

  await elasticProvider.create({ index: `errors-front`, body: errorData });
};

export default logErrorToElastic;
