import { create } from 'zustand';
import { AnswerQuotation } from '../../../../../@types/interface';

interface SelectedAnswerStore {
  answer: AnswerQuotation | null;
  onSelect: (answer: AnswerQuotation) => void;
  onClear: () => void;
}

const useSelectedAnswer = create<SelectedAnswerStore>(set => ({
  answer: null,
  onSelect: async (answer: AnswerQuotation) => {
    console.log('seleção');
    set({ answer });
  },
  onClear: () => {
    console.log('limpeza');
    set({ answer: null });
  },
}));

export default useSelectedAnswer;
