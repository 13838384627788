/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { getAccessToken } from '../utils/workshopData';
import { LoginModal } from '../shared/LoginModal';
import api from '../services/api/api';
import { AuthenticationResponse, User, Workshop } from '../@types/interface';
import useLoginModal from './useLoginModal';

interface IUserProvider {
  workshop: Workshop | null;
  user: User | null;
  setWorkshop: Dispatch<SetStateAction<Workshop | null>>;
  setUser: Dispatch<SetStateAction<User | null>>;
  authenticate: () => Promise<void>;
  isAuthenticating: boolean;
  isAgent: boolean;
}

const AuthContext = createContext({} as IUserProvider);

const AuthProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [workshop, setWorkshop] = useState<Workshop | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isAgent, setIsAgent] = useState(false);
  const loginModal = useLoginModal();

  const fetchUserAuth = async (): Promise<AuthenticationResponse | null> => {
    try {
      const { data } = await api.post<AuthenticationResponse>('/auth/token');
      if (data) {
        localStorage.setItem('@AutoCenter: accessToken', data.accessToken);
        return data;
      }
    } catch (err) {
      if (
        window.location.pathname !== '/page-login' &&
        window.location.pathname !== '/'
      ) {
        loginModal.onOpen();
      }
      console.error('Error during authentication:', err);
    }
    return null;
  };

  const {
    data,
    refetch,
    isLoading: isAuthenticating,
  } = useQuery(['userAuth'], fetchUserAuth, {
    staleTime: 1000 * 60 * 5, // revalida a cada 5 minutos
    retry: 1,
    onError: () => {
      if (
        window.location.pathname !== '/page-login' &&
        window.location.pathname !== '/'
      ) {
        loginModal.onOpen();
      }
    },
  });

  useEffect(() => {
    if (data?.user) {
      setUser(data.user);

      const workshopData =
        data.user.workshop || data.user.agents?.[0]?.company || null;

      setWorkshop(workshopData);

      setIsAgent(
        Array.isArray(data.user.agents) && data.user.agents.length > 0,
      );
    }
  }, [data]);

  const authenticate = useCallback(async () => {
    const accessToken = getAccessToken();
    if (!accessToken) {
      // TODO Redirecionar via react-router-dom
      window.location.href = '/page-login';
      return;
    }
    await refetch();
  }, [refetch]);

  return (
    <AuthContext.Provider
      value={{
        workshop,
        authenticate,
        isAuthenticating,
        user,
        setWorkshop,
        setUser,
        isAgent,
      }}
    >
      {children}
      <LoginModal />
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
