import * as yup from 'yup';
import message from '../../../../../../utils/message';
import { createRepair, updateRepair } from './model';
import { RepairProps } from './interfaces';

export const handleCreateRepair = async (
  isSeparateComponent: boolean,
  isSeparateCustomer: boolean,
  repair: Partial<RepairProps>,
) => {
  try {
    let filteredRepair = repair;
    const schema = yup.object().shape(getRepairSchema(repair));
    if (isSeparateComponent) {
      const { vehicleId, km, ...restData } = filteredRepair;
      filteredRepair = restData;
    }
    if (isSeparateCustomer) {
      const { userId, addressId, ...restData } = filteredRepair;
      filteredRepair = restData;
    }
    await schema.validate(filteredRepair);

    const response = await createRepair(filteredRepair as RepairProps);

    return response;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    } else message(error, 'error');
  }
};

export const handleUpdateRepair = async (
  isSeparateComponent: boolean,
  isSeparateCustomer: boolean,
  repair: Partial<RepairProps>,
  repairId: string,
) => {
  try {
    let filteredRepair = { ...repair, repairId };
    // console.log(filteredRepair);
    const schema = yup.object().shape({
      ...getRepairSchema(repair),
      repairId: yup.string().required('Não foi possível localizar a OS'),
    });
    if (isSeparateComponent) {
      const { vehicleId, km, ...restData } = filteredRepair;
      filteredRepair = restData;
    }
    if (isSeparateCustomer) {
      const { userId, ...restData } = filteredRepair;
      filteredRepair = { ...restData, userId: '' };
    }
    if (repair && repair.defect && repair.defect.length > 0) {
      filteredRepair.defect = repair.defect;
    }

    await schema.validate(filteredRepair);

    const response = await updateRepair(
      filteredRepair as RepairProps,
      repairId,
    );

    return response;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    } else message(error, 'error');
  }
};

const getRepairSchema = (repair: Partial<RepairProps>) => {
  return {
    name: yup.string().required('O campo nome é obrigatório.'),
    phone: yup.string(),
    description: yup.string(),
    comment: yup.string(),
    total: yup
      .number()
      .min(1, 'cadastre a OS com um valor mínimo maior que zero'),
    defect: yup.string(),
    childServices: yup.array().of(
      yup.object().shape({
        name: yup.string().required('O campo nome do serviço é obrigatório.'),
        description: yup.string(),
        total: yup.number().required('Verifique os valores do seu serviço.'),
        codProd: yup.string(),
        cod_prod: yup.string(),
        quantity: yup
          .number()
          .required('O campo quantidade do serviço é obrigatório.')
          .test(
            'quantidade inválida',
            'infome uma quantidade do serviço maior que zero',
            value => {
              if (!value) return false;
              if (value > 0) return true;
              return false;
            },
          ),
      }),
    ),
    discount: yup
      .number()
      .test(
        'valor de desconto inválido',
        'O desconto deve ser menor que o subtotal.',
        value => {
          const { total } = repair;
          if (!value) {
            return true;
          }
          if (total) return value < total;
          return false;
        },
      ),
    km: yup.number(),
    finished: yup.boolean(),
    statusId: yup.string().required('verifique o status da OS'),
    userId: yup.string(),
    vehicleId: yup.string(),
    date: yup.string(),
    time: yup.string(),
    finishDate: yup.string(),
    finishTime: yup.string(),
    checklistId: yup.string(),
  };
};
