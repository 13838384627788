import { AlertMessage } from '../../../../../../../../shared/AlertMessage';
import { AnswerQuotation } from '../../../../../../../../@types/interface';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onApprove: () => void;
  answer?: AnswerQuotation | null;
  hasNoApprovedItems: boolean;
  emptyApproval: boolean;
}

export const ApproveAnswerModal = ({
  isOpen,
  onClose,
  isLoading = false,
  onApprove,
  answer = null,
  hasNoApprovedItems,
  emptyApproval,
}: Props) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      title="confirmar aprovação?"
      footer={
        <AlertMessage.Footer>
          {!isLoading && (
            <>
              <AlertMessage.CancelButton onClick={onClose}>
                Revisar
              </AlertMessage.CancelButton>
              {!emptyApproval && (
                <AlertMessage.ConfirmButton onClick={onApprove}>
                  Aprovar Proposta
                </AlertMessage.ConfirmButton>
              )}
            </>
          )}
        </AlertMessage.Footer>
      }
    >
      <AlertMessage.Body>
        <p
          style={{
            textAlign: 'center',
          }}
        >
          {emptyApproval && (
            <sub
              style={{
                color: 'var(--error)',
              }}
            >
              <b>Você deve informar uma quantidade diferente de zero</b>
            </sub>
          )}
          {!emptyApproval && (
            <>
              Deseja aprovar a proposta de
              <b>{answer && ` ${answer?.workshop?.fantasy_name ?? ''}`}</b>?
              {hasNoApprovedItems && (
                <sub
                  style={{
                    color: 'var(--error)',
                  }}
                >
                  <b>Nenhum item foi selecionado</b>
                </sub>
              )}
            </>
          )}
        </p>
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
