import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Circle, RadioButton } from '@phosphor-icons/react';
import { ProductTable } from '../../../../../../../../shared/ProductTable';
import { Checkbox } from '../../../../../../../../shared/Checkbox';
import styles from './mobile-quotation-item.module.css';
import {
  ItemAnswerQuotation,
  ItemQuotation,
} from '../../../../../../../../@types/interface';
import { useAppSelector } from '../../../../../../../../hooks/useAppSelector';
import {
  addAnswerItem,
  editAnswerItem,
  removeAnswerItem,
} from '../../../../../../../../features/quotation/quotation-reply-slice';
import { ApprovedItems } from '../../../../../../../../features/quotation/interfaces';
import { ListTable } from '../../../../../../../../shared/ListTable';
import { Input } from '../../../../../../../../shared/Input';

interface Props {
  quotationItem: ItemQuotation;
  answer: ItemAnswerQuotation | null;
  isTrembleWarning: boolean;
  isAnswered: boolean;
}

export const MobileQuotationItem = ({
  answer,
  quotationItem,
  isTrembleWarning,
  isAnswered,
}: Props) => {
  const [askedQuantity, setAskedQuantity] = useState('0');
  const [selectCheckBoxQuantity, setSelectCheckBoxQuantity] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isOpacityDisable, setIsOpacityDisable] = useState(false);
  const [numberCaracters, setNumberCaracters] = useState(false);

  const dispatch = useDispatch();
  const { approvedQuotation } = useAppSelector(state => state.quotationReducer);

  const handleCheckboxChange = useCallback(
    (isChecked: boolean) => {
      const item_answer_quotation_id = answer?.id_item_answer_quotation ?? '';

      if (approvedQuotation) {
        if (isChecked) {
          const parsedValue = parseFloat(askedQuantity);
          const newItem: ApprovedItems = {
            quantity_asked: !isNaN(parsedValue) ? parsedValue : 0,
            item_answer_quotation_id,
            value: answer?.total || 0,
          };

          dispatch(
            addAnswerItem({
              answer_quotation_id: answer?.answer_quotation_id || '',
              newItem,
            }),
          );
        } else {
          dispatch(
            removeAnswerItem({
              answer_quotation_id: answer?.answer_quotation_id || '',
              itemId: answer?.id_item_answer_quotation || '',
            }),
          );
        }
      }
    },
    [answer, askedQuantity, dispatch, approvedQuotation],
  );

  const handleInputChange = (data: string) => {
    const maskedValue = data.replace(/[^\d]/g, '');
    const quantityAvailable = answer?.quantity_available.toString() || '0';

    setAskedQuantity(maskedValue);
    handleQuantityIsIvalid(maskedValue);
    if (maskedValue.length === quantityAvailable.length) {
      return setNumberCaracters(true);
    }
    setNumberCaracters(false);
  };

  const handleSendValueInput = (value: string) => {
    const parsedValue = parseFloat(value);
    dispatch(
      editAnswerItem({
        answer_quotation_id: answer?.answer_quotation_id || '',
        itemId: answer?.id_item_answer_quotation || '',
        newQuantityAsked: !isNaN(parsedValue) ? parsedValue : 0,
      }),
    );
  };

  const handleQuantityIsIvalid = (value: string) => {
    const parsedValue = parseFloat(value) || 0;
    const maxValue = answer?.quantity_available || 0;
    if (parsedValue > maxValue || parsedValue === 0) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
      handleSendValueInput(value);
    }
  };

  const handleOnRowClick = () => {
    setIsSelected(prev => !prev);
  };

  const handleBackground = () => {
    if (isSelected) {
      return '#a5d6a7';
    }
    // if (!isSelected && isInvalid) {
    //   return '#dc2220';
    // }

    return '#bcb5b5';
  };

  useEffect(() => {
    if (answer && quotationItem) {
      setAskedQuantity(
        parseFloat(quotationItem.quantity) <= answer.quantity_available
          ? quotationItem.quantity
          : `${answer.quantity_available}`,
      );
    }
  }, [answer, quotationItem]);

  return (
    <div className={styles['quote-item']}>
      <ListTable.Row
        style={{
          gridTemplateColumns: '1fr 2fr',
          position: 'relative',
          padding: '0.9rem 0 0.5rem',
          height: 'auto',
          fontWeight: 700,
          backgroundColor: handleBackground(),
          borderRadius: '5px',
        }}
        selected={isSelected}
        selectedWeight="fill"
        variant="caret"
        handleClick={handleOnRowClick}
      >
        <span>
          {selectCheckBoxQuantity || isAnswered ? (
            <RadioButton size={22} weight="fill" color="#2194f3" />
          ) : (
            <RadioButton size={22} weight="fill" color="#ffffff" />
          )}
        </span>

        {/* <span>{quotationItem?.description || '----'}</span> */}

        <span>
          {new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
          }).format(answer?.total ?? 0)}
        </span>

        <div />
      </ListTable.Row>
      {isSelected && (
        <ListTable.SubRow
          style={{
            width: '100%',
            height: 'auto',
            flexDirection: 'column',
            padding: '0 1rem 1.1rem 1rem ',
            backgroundColor: '#a5d6a7',
            borderRadius: '5px',
          }}
        >
          <ProductTable.Row
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              padding: '0 0.3rem',
              gap: '1rem',
            }}
          >
            <div className={styles.wrapperSubRow}>
              {answer?.quantity_available ? (
                <>
                  <div className={styles['requested-quantity']}>
                    <Checkbox
                      theme="green"
                      name={
                        (answer && answer.id_item_answer_quotation) ?? 'none'
                      }
                      isChecked={isAnswered ? true : selectCheckBoxQuantity}
                      handleChange={() => {
                        setSelectCheckBoxQuantity(previous => {
                          const newValue = !previous;
                          handleCheckboxChange(newValue);
                          handleQuantityIsIvalid(askedQuantity);
                          setIsOpacityDisable(true);
                          return newValue;
                        });
                      }}
                      disabled={isAnswered || isInvalid}
                    />
                    {isAnswered ? 'Confirmado' : 'Confirmar'}
                  </div>

                  <div
                    className={`${styles.wrapperAmount}  ${
                      !isOpacityDisable ? styles.opacity : styles.Answer
                    }`}
                  >
                    <div>
                      <div>
                        {/* <img src={numpad} alt="numpad" /> */}
                        {/* <b>{isAnswered ? 'Qtd solicitada:' : 'Quantidade:'}</b> */}
                      </div>
                      {isAnswered ? (
                        <span>{answer?.quantity_asked ?? 0}</span>
                      ) : (
                        <Input
                          name="quantity"
                          value={askedQuantity}
                          handleChange={data =>
                            handleInputChange(data.target.value)
                          }
                          pattern="([0-9]+)"
                          onBlur={() => {
                            if (isInvalid) {
                              handleCheckboxChange(false);
                              setSelectCheckBoxQuantity(false);
                            }
                          }}
                          disabled={
                            !isOpacityDisable ||
                            (!selectCheckBoxQuantity &&
                              !isInvalid &&
                              numberCaracters)
                          }
                          style={{
                            margin: 0,
                          }}
                        />
                      )}
                    </div>
                    <div>
                      Qtd disponível:
                      <span>{answer?.quantity_available || '----'}</span>
                    </div>
                    {isInvalid && (
                      <span
                        className={`${styles.warning}  ${
                          isTrembleWarning ? styles.warningAnimation : ''
                        }`}
                      >
                        informe uma quantidade válida
                      </span>
                    )}
                  </div>
                </>
              ) : (
                <div className={styles.notOffer}>Sem oferta</div>
              )}
            </div>
            <div className={styles.wrapperSubRow2}>
              <div>
                {answer?.reference &&
                  answer?.reference !== quotationItem?.reference &&
                  (answer?.reference.length !== 0 ||
                    quotationItem?.reference.length !== 0) && (
                    <Circle size={12} weight="fill" color="#fbc02d" />
                  )}
              </div>
              <div>
                <div>
                  <b>Referência: </b>
                  <p>
                    {(answer?.reference
                      ? answer?.reference
                      : quotationItem?.reference) || 'não informado'}
                  </p>
                </div>
                <div>
                  <b>Marca:</b>
                  <p>{answer?.brand || 'não informado'}</p>
                </div>
              </div>
            </div>
          </ProductTable.Row>
        </ListTable.SubRow>
      )}
    </div>
  );
};
