import * as y from 'yup';

export const createAgentDTO = y.object({
  company_id: y.string().required('Algo deu errado'),
  name: y.string().required('Nome é um campo obrigatório'),
  document: y
    .string()
    .required('Digite o cpf ou o cnpj do vendedor')
    .min(11, 'Informe um cpf ou um cnpj válido'),
  phone: y
    .string()
    .required('Telefone é um campo obrigatório')
    .min(11, 'Informe um número válido'),
  max_points: y.string().default(''),
  class_agent_id: y.string(),
});

export type ICreateAgentDTO = y.InferType<typeof createAgentDTO>;
