import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PlusCircle } from '@phosphor-icons/react';
import { resetBudget } from '../../../../../features/budget/budget-slice';
import { DeleteModal } from '../DeleteModal';
import styles from './budget-list.module.css';
import { ListTable } from '../../../../../shared/ListTable';
import { BudgetItem } from '../BudgetItem';
import { BudgetActions } from '../BudgetActions';
import { Button } from '../../../../../shared/Button';
import { useBudgetData } from './useBudgetData';
import { useBudgetActions } from './useBudgetActions';
import { useResponsive } from '../../../../../hooks/useResponsive';

export const BudgetList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { budgetsFromState, isLoading, loadMoreData } = useBudgetData();
  const {
    budgetId,
    setBudgetId,
    handleCreateOS,
    handleDeleteBudget,
    handleEdit,
    handleRowPress,
    handleWhatsappMessage,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    selectedRow,
  } = useBudgetActions({
    dispatch,
    navigate,
  });

  const { sizeMobile } = useResponsive();

  return (
    <div className={styles.container}>
      <DeleteModal
        budgetId={budgetId}
        setBudgetId={setBudgetId}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
      />
      <div className={styles.head}>
        <Button
          handleClick={() => {
            navigate('/create-budget');
            dispatch(resetBudget());
          }}
          style={{
            fontWeight: 700,
            padding: '1rem',
            boxShadow: '0px 2px 2px #00000044',
            fontSize: '1.2rem',
          }}
        >
          <PlusCircle size="2rem" /> Novo Orçamento
        </Button>
      </div>
      <ListTable.Root
        isLoading={isLoading}
        message="Nenhum Orçamento encontrado"
        isEmpty={!isLoading && budgetsFromState.length === 0}
      >
        <ListTable.Head
          style={{
            gridTemplateColumns: !sizeMobile
              ? '.5fr repeat(4, 1fr)'
              : '.5fr repeat(3, 1fr)',
          }}
        >
          <span> {sizeMobile ? 'N°' : 'Número'} </span>
          <span> Cliente</span>
          <span>Placa</span>
          <span className={styles.desktop}>Contato</span>
          <span>Data</span>
        </ListTable.Head>
        <ListTable.Body onScroll={() => loadMoreData()}>
          {budgetsFromState.map((budget, index) => (
            <React.Fragment key={budget.id_budget}>
              <ListTable.Row
                handleClick={() => handleRowPress(index)}
                selected={selectedRow === index}
                style={{
                  gridTemplateColumns: !sizeMobile
                    ? '.5fr repeat(4, 1fr)'
                    : '.5fr repeat(3, 1fr)',
                }}
              >
                <BudgetItem budget={budget} />
              </ListTable.Row>
              {selectedRow === index && (
                <ListTable.SubRow>
                  <BudgetActions
                    handleCreateOS={() => handleCreateOS(budget)}
                    handleDeleteBudget={() => handleDeleteBudget(budget)}
                    handleEdit={() => handleEdit(budget)}
                    handleView={() =>
                      navigate(`/budget-report?budgetId=${budget.id_budget}`)
                    }
                    handleWhatsappMessage={() => handleWhatsappMessage(budget)}
                  />
                </ListTable.SubRow>
              )}
            </React.Fragment>
          ))}
        </ListTable.Body>
      </ListTable.Root>
    </div>
  );
};
