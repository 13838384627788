import { ControllerRenderProps } from 'react-hook-form';
import { useState } from 'react';
import { VehicleModal } from '../../../../../../shared/VehicleModal';
import { Input } from '../../../../../../shared/Input';
import styles from './plate-input.module.css';

interface PlateInputProps {
  field: ControllerRenderProps;
  placeholder?: string;
  defaultValue?: string;
  mask?: (value: string) => string;
  onChange?: (value: string) => void;
}

export const PlateInput = ({
  field: { onChange: _onChange, onBlur, value },
  placeholder,
  defaultValue,
  mask,
  onChange,
}: PlateInputProps) => {
  const [showModal, setShowModal] = useState(false);

  const formattedValue = mask ? mask(value) : value;

  return (
    <>
      <Input
        name="plate"
        type="search"
        placeholder={placeholder}
        handleChange={e => {
          _onChange(e.target.value);
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        onBlur={onBlur}
        value={formattedValue}
        defaultValue={defaultValue}
        style={{
          height: '3rem',
        }}
        handleIconSearch={() => setShowModal(true)}
        controlClassName={styles.input}
      />

      <VehicleModal
        isOpen={showModal}
        licensePlate={formattedValue}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};
