import { useCallback, useEffect, useState } from 'react';
import { QuotationNotificationResponse } from './interface';
import {
  getQuotationNotificationController,
  updateQuotationNotificationController,
} from './controller';

export const useQuotationNotification = () => {
  const [notifications, setNotifications] = useState<
    QuotationNotificationResponse[]
  >([]);
  const [selectedNotification, setSelectedNotification] =
    useState<QuotationNotificationResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchQuotationNotifications = useCallback(async () => {
    setIsLoading(true);
    const res = await getQuotationNotificationController({
      page: 1,
      limit: 999,
    });
    if (res) {
      setNotifications(res.filter(notification => !notification.read));
    }
    setIsLoading(false);
  }, []);

  const readQuotationNotification = useCallback(async () => {
    if (selectedNotification) {
      const res = await updateQuotationNotificationController(
        selectedNotification.id_notification,
      );
      if (res) {
        setNotifications(previous =>
          previous.filter(
            notification =>
              notification.id_notification !== res.id_notification,
          ),
        );
      }
    }
  }, [selectedNotification]);

  useEffect(() => {
    readQuotationNotification();
  }, [readQuotationNotification, selectedNotification]);

  useEffect(() => {
    const interval = setInterval(fetchQuotationNotifications, 1000 * 10);

    return () => {
      clearInterval(interval);
    };
  }, [fetchQuotationNotifications]);

  useEffect(() => {
    fetchQuotationNotifications();
  }, [fetchQuotationNotifications]);

  return {
    notifications,
    setSelectedNotification,
    selectedNotification,
    isLoading,
  };
};
