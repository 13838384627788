import { useEffect } from 'react';
import { Modal } from '../../../Modal';
import { Button } from '../../../Button';
import styles from './image-modal.module.css';

interface ImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  url: string;
  title: string;
  confirmText?: string;
}

export const ImageModal = ({
  isOpen,
  onClose,
  onConfirm,
  url,
  title,
  confirmText = 'Confirmar',
}: ImageModalProps) => {
  useEffect(() => {
    document.body.style.overflow = 'auto';
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0, 0);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      footer={
        <div className={styles.actions}>
          <Button handleClick={onClose} variant="ghost">
            Cancelar
          </Button>
          {onConfirm && (
            <Button handleClick={onConfirm} variant="register">
              {confirmText}
            </Button>
          )}
        </div>
      }
    >
      <div className={styles.container}>
        <img src={url} alt="Envio de Imagem" />
      </div>
    </Modal>
  );
};
