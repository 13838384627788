import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormValues } from '../interface';

export const useRegisterForms = () => {
  const form = useForm<FormValues>({
    defaultValues: {
      workshopId: '',
      corporateName: '',
      fantasyName: '',
      phone: '',
      whatsapp: '',
      email: '',
      document: '',
      type: 'CAR',
      stateRegistration: undefined,
      address: {
        zip: '',
        street: '',
        uf: '',
        city: '',
        district: '',
        number: '',
      },
      code: undefined,
      confirmPassword: '',
      password: '',
      quotation_segment: 'both',
    },
    resolver: yupResolver(
      yup.object().shape({
        corporateName: yup.string().required('A razão social é obrigatória'),
        fantasyName: yup.string().required('O nome fantasia é obrigatório'),
        phone: yup.string().required('O número de telefone é obrigatório'),
        whatsapp: yup.string(),
        email: yup
          .string()
          .email('informe um email válido')
          .required('O email é obrigatório'),
        document: yup.string().required('O CPF/CNPJ é obrigatório'),
        type: yup.string(),
        address: yup.object(),
        password: yup
          .string()
          .nullable()
          .test('passwords-match', 'Senhas não coincidem', (value, context) => {
            return !value || context.parent.confirmPassword === value;
          })
          .test(
            'password-size',
            'A senha deve conter no mínimo 6 caracteres',
            value => {
              return !value || value.length >= 6;
            },
          ),
        confirmPassword: yup
          .string()
          .nullable()
          .test('passwords-match', 'Senhas não coincidem', (value, context) => {
            return !value || context.parent.password === value;
          }),
        code: yup.string().nullable(),
      }),
    ),
  });

  return { ...form };
};
