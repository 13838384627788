/* eslint-disable no-nested-ternary */
import { FormControl, Row, Spinner, Text, TextArea } from 'native-base';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';
import pixel from '../../../../../styles/size';
import { PlateInput } from './inputs/PlateInput';
import { COLORS } from '../../../../../styles/colors';
import { Input } from '../../../../../shared/Input';

type InputType = 'text' | 'password' | 'email' | 'plate' | 'textarea';

interface InputControlProps {
  required?: boolean;
  label: string;
  description?: string;
  inline?: boolean;
  type?: InputType;
  placeholder?: string;
  name: keyof FieldValues;
  control: Control<any>;
  rules?: RegisterOptions;
  defaultValue?: string;
  mask?: (arg: string) => string;
  onChange?: (arg: string) => void;
  isLoading?: boolean;
}

export const InputControl = ({
  required,
  label,
  description,
  inline,
  type = 'text',
  placeholder,
  name,
  control,
  rules,
  defaultValue,
  mask,
  onChange,
  isLoading = false,
}: InputControlProps) => {
  const renderInput = ({
    field: { onChange: _onChange, onBlur, value },
  }: {
    field: ControllerRenderProps<FieldValues, string>;
  }) => {
    const handleChange = (newValue: string) => {
      _onChange(newValue);
      if (onChange) {
        onChange(newValue);
      }
    };
    const formattedValue = mask ? mask(value) : value;
    return (
      <Input
        name={name}
        placeholder={placeholder}
        handleChange={e => handleChange(e.target.value)}
        onBlur={onBlur}
        value={formattedValue}
        defaultValue={defaultValue}
        style={{
          height: '3rem',
        }}
      />
    );
  };

  const renderTextArea = ({
    field: { onChange: _onChange, onBlur, value },
  }: {
    field: ControllerRenderProps<FieldValues, string>;
  }) => {
    const handleChange = (newValue: string) => {
      _onChange(newValue);
      if (onChange) {
        onChange(newValue);
      }
    };
    return (
      <TextArea
        w={{ base: pixel(645, 800), xl: pixel(520, 1366) }}
        fontSize={{ base: pixel(30, 800), xl: pixel(18, 1366) }}
        placeholder={placeholder}
        onChangeText={handleChange}
        onBlur={onBlur}
        value={value}
        defaultValue={defaultValue || ''}
        autoCompleteType={undefined}
        h="6rem"
        borderRadius="10px"
      />
    );
  };

  return (
    <FormControl
      flexDir={inline ? 'row' : 'column'}
      alignItems={inline ? 'center' : 'initial'}
      ml="0rem"
    >
      <FormControl.Label
        mr="1rem"
        _text={{
          color: COLORS.CHARCOAL,
          fontWeight: '600',
          fontSize: { base: pixel(30, 800), xl: pixel(18, 1366) },
        }}
      >
        {label}
        {required && (
          <sup style={{ color: '#E00D0D', fontWeight: 'Semibold' }}>*</sup>
        )}
      </FormControl.Label>
      {description && <Text fontSize="0.875rem">{description}</Text>}
      {type === 'textarea' ? (
        <Controller
          control={control}
          render={renderTextArea}
          name={name}
          rules={rules}
        />
      ) : type === 'plate' ? (
        <Row alignItems="center">
          <Controller
            control={control}
            render={({ field }) => (
              <PlateInput field={field} mask={mask} onChange={onChange} />
            )}
            name={name}
            rules={rules}
          />
          {isLoading && <Spinner ml=".5rem" />}
        </Row>
      ) : (
        <Row alignItems="center">
          <Controller
            control={control}
            render={renderInput}
            name={name}
            rules={rules}
          />
          {isLoading && <Spinner ml=".5rem" />}
        </Row>
      )}
    </FormControl>
  );
};
