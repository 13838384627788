import { Eye } from 'phosphor-react';
import { AlertMessage } from '../../../../../../../../shared/AlertMessage';
import { AnswerQuotation } from '../../../../../../../../@types/interface';
import styles from './approved-quotation-resume-modal.module.css';
import { Button } from '../../../../../../../../shared/Button';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  container: Element | DocumentFragment;
  answers: AnswerQuotation[];
  title: string;
  onSelect: (answer: AnswerQuotation) => void;
}

export const QuotationResumeModal = ({
  container,
  isOpen,
  onClose,
  answers,
  title,
  onSelect,
}: Props) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      container={container}
      disableScroll
      title={title}
    >
      <AlertMessage.Body>
        {answers.length > 0 ? (
          <ul className={styles.list}>
            {answers.map(answer => (
              <Button
                handleClick={() => onSelect(answer)}
                variant="unstyled"
                key={answer.id_answer_quotation}
              >
                <li>
                  {answer?.workshop?.fantasy_name ?? ''}
                  <Eye size={18} />
                </li>
              </Button>
            ))}
          </ul>
        ) : (
          <p>Nenhuma proposta nova</p>
        )}
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
