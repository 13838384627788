/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  CaretDown,
  ChatText,
  WhatsappLogo,
  X,
  CaretUp,
  Check,
} from '@phosphor-icons/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Truck } from 'phosphor-react';
import styles from './answer-item.module.css';
import { ProductTable } from '../../../../../../../../shared/ProductTable';
import { Textarea } from '../../../../../../../../shared/Textarea';
import {
  AnswerQuotation,
  ItemQuotation,
} from '../../../../../../../../@types/interface';
import { editAnswerRebuttal } from '../../../../../../../../features/quotation/quotation-reply-slice';
import { useAppSelector } from '../../../../../../../../hooks/useAppSelector';
import { Button } from '../../../../../../../../shared/Button';
import { AnswerRow } from '../AnswerRow';
import { ConfirmRejectAnswerModal } from '../ConfirmRejectAnswerModal';
import { ApproveAnswerModal } from '../ApproveAnswerModal';
import { ApprovedAnswer } from '../../../../../../../../features/quotation/interfaces';

interface Props {
  index: number;
  answer: AnswerQuotation;
  quotationItems: ItemQuotation[];
  setInvalid: (invalid: boolean) => void;
  isTrembleWarning: boolean;
  handleWhatsappMessage: (phone: string) => void;
  isAnswered: boolean;
  handleApproveAnswer: (id: string) => void;
  handleRejectAnswer: (id: string) => void;
}

export const AnswerItem = ({
  answer,
  index,
  quotationItems,
  setInvalid,
  isTrembleWarning,
  handleWhatsappMessage,
  isAnswered,
  handleApproveAnswer,
  handleRejectAnswer,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();

  const { approvedQuotation } = useAppSelector(state => state.quotationReducer);
  const [subtotal, setSubtotal] = useState(0);

  const approvedItems = useMemo(() => {
    if (
      !approvedQuotation?.list_answers_approved ||
      !answer?.id_answer_quotation
    ) {
      return undefined;
    }

    return approvedQuotation.list_answers_approved.find(
      approvedItem =>
        approvedItem?.answer_quotation_id === answer.id_answer_quotation,
    );
  }, [answer?.id_answer_quotation, approvedQuotation?.list_answers_approved]);

  const calculateNotApprovedSubtotal = useCallback(() => {
    const newSubtotal = quotationItems.reduce(
      (previousValue, quotationItem) => {
        const currentAnswer = answer?.items_answer_quotation?.find(
          answerItem =>
            answerItem.item_quotation_id === quotationItem.id_item_quotation,
        );

        if (!currentAnswer) return previousValue;

        const quantityAsked = parseFloat(`${quotationItem?.quantity ?? 0}`);
        const quantityAvailable = Math.min(
          quantityAsked,
          currentAnswer?.quantity_available ?? 0,
        );
        return previousValue + (currentAnswer?.total ?? 0) * quantityAvailable;
      },
      0,
    );

    setSubtotal(newSubtotal);
  }, [answer?.items_answer_quotation, quotationItems]);

  const calculateApprovedSubtotal = useCallback(() => {
    if (!approvedItems?.items) {
      setSubtotal(0);
      return;
    }

    const newSubtotal = approvedItems.items.reduce(
      (previousValue, currentValue) =>
        previousValue +
        (currentValue?.quantity_asked ?? 0) * (currentValue?.value ?? 0),
      0,
    );

    setSubtotal(newSubtotal);
  }, [approvedItems]);

  const calculateAnsweredSubtotal = useCallback(() => {
    if (!answer?.items_answer_quotation) {
      setSubtotal(0);
      return;
    }

    const newSubtotal = answer.items_answer_quotation
      .filter(item => item.quantity_asked)
      .reduce(
        (previousValue, currentValue) =>
          previousValue +
          (currentValue?.quantity_asked ?? 0) * currentValue.total,
        0,
      );

    setSubtotal(newSubtotal);
  }, [answer?.items_answer_quotation]);

  useEffect(() => {
    if (isAnswered) {
      calculateAnsweredSubtotal();
    } else if (approvedItems?.items.length === 0) {
      // if nothing is approved
      calculateNotApprovedSubtotal();
    } else {
      // if approve any item
      calculateApprovedSubtotal();
    }
  }, [
    approvedItems?.items.length,
    calculateAnsweredSubtotal,
    calculateApprovedSubtotal,
    calculateNotApprovedSubtotal,
    isAnswered,
  ]);

  useEffect(() => {
    if (answer?.confirmed || answer?.finished || answer?.rejected) {
      setIsOpen(false);
    }
  }, [answer]);

  const [isConfirmRejectionModalOpen, setIsConfirmRejectionModalOpen] =
    useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleAnswerStatus = () => {
    if (answer?.rejected) return '(Rejeitado)';
    if (answer?.finished) return '(Pedido Finalizado)';
    if (answer?.confirmed) return '(Preparando Pedido)';
    return '';
  };

  const hasInvalidAnswers = (answers: ApprovedAnswer[]) => {
    return answers?.some(approvedAnswer =>
      approvedAnswer?.items?.some(item => item.quantity_asked === 0),
    );
  };

  const workshopFantasyName = useMemo(
    () => answer?.workshop?.fantasy_name ?? '',
    [answer?.workshop?.fantasy_name],
  );

  const agent = useMemo(() => answer?.agent ?? null, [answer?.agent]);

  const answerComment = useMemo(() => answer?.comment ?? '', [answer?.comment]);

  const taxValue = useMemo(() => {
    return answer?.tax_value
      ? answer.tax_value.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : 'Não informado';
  }, [answer?.tax_value]);

  const rebuttal = useMemo(() => answer?.rebuttal ?? '', [answer?.rebuttal]);

  return (
    <>
      <ConfirmRejectAnswerModal
        isOpen={isConfirmRejectionModalOpen}
        onClose={() => setIsConfirmRejectionModalOpen(false)}
        onConfirm={() => {
          handleRejectAnswer(answer?.id_answer_quotation ?? '');
          setIsConfirmRejectionModalOpen(false);
        }}
        onCancel={() => setIsConfirmRejectionModalOpen(false)}
        container={containerRef.current || document.body}
      />
      <ApproveAnswerModal
        isOpen={isApproveModalOpen}
        onClose={() => setIsApproveModalOpen(false)}
        onApprove={() => {
          handleApproveAnswer(answer?.id_answer_quotation ?? '');
          setIsApproveModalOpen(false);
        }}
        answer={answer}
        hasNoApprovedItems={approvedItems?.items.length === 0}
        emptyApproval={hasInvalidAnswers([approvedItems as ApprovedAnswer])}
      />
      <div className={styles.container} ref={containerRef}>
        <div className={styles.heading}>
          <h1 onClick={() => setIsOpen(previous => !previous)}>
            {isOpen ? (
              <CaretUp size={24} weight="fill" color="#43A046" />
            ) : (
              <CaretDown size={24} weight="fill" color="#43A046" />
            )}
            {index + 1}. {workshopFantasyName} <sub>{handleAnswerStatus()}</sub>
            {agent && <sub>vendedor: {agent?.user?.name || ''}</sub>}
          </h1>
          <div
            style={{
              display: isOpen ? 'block' : 'none',
            }}
          >
            <ul className={styles.info}>
              <li>
                <Button
                  handleClick={() =>
                    handleWhatsappMessage(answer?.workshop?.whatsapp)
                  }
                  style={{
                    height: '1.5rem',
                  }}
                >
                  <WhatsappLogo size={24} weight="fill" /> Whatsapp
                </Button>
              </li>
            </ul>
            <ProductTable.Header
              style={{
                gridTemplateColumns: '.5fr 2fr 1.5fr 1fr',
              }}
            >
              <span>Item</span>
              <span
                style={{
                  textAlign: 'start',
                }}
              >
                Descrição
              </span>
              <span
                style={{
                  paddingLeft: '2rem',
                  justifySelf: 'center',
                }}
              >
                Referência
              </span>
              <span>Qtd. Solicitada</span>
            </ProductTable.Header>
            <div className={styles['answer-list']}>
              {quotationItems.map((item, counter) => (
                <AnswerRow
                  key={`${item.id_item_quotation} - ${answer.id_answer_quotation}`}
                  quotationItem={item}
                  answer={answer}
                  index={counter}
                  setInvalid={setInvalid}
                  isTrembleWarning={isTrembleWarning}
                  isAnswered={isAnswered}
                />
              ))}
            </div>
            {answerComment.trim().length > 0 && (
              <div className={styles.comment}>
                <b>
                  <ChatText size={24} color="var(--primary)" /> Comentário do
                  Fornecedor:
                </b>
                <p>{answerComment}</p>
              </div>
            )}

            <div className={styles.comment}>
              <b>
                <Truck size={24} color="var(--primary)" /> Taxa de Entrega:
              </b>
              <p> {taxValue}</p>
            </div>

            <div className={styles.subtotal}>
              {isAnswered ||
              (approvedItems &&
                approvedItems.items &&
                approvedItems.items.length > 0) ? (
                <b>Total</b>
              ) : (
                <div className={styles['subtotal-info']}>
                  <b>Sub-Total</b>
                  <span>(Cálculo com os menores preços)</span>
                </div>
              )}
              <b>
                {subtotal.toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: 'currency',
                  currency: 'BRL',
                })}
              </b>
            </div>
            <div className={styles['comment-container']}>
              <p>
                {!isAnswered ? (
                  <>
                    Adicionar Observação <span>(Opcional)</span>
                  </>
                ) : (
                  `${(answer?.rebuttal ?? '').length > 0 ? 'Observação' : ''}`
                )}
              </p>
              {!isAnswered ? (
                <Textarea
                  name="comment"
                  disabled={isAnswered}
                  handleChange={e => {
                    dispatch(
                      editAnswerRebuttal({
                        answer_quotation_id: answer.id_answer_quotation,
                        updatedValue: e.target.value,
                      }),
                    );
                  }}
                  style={{ fontWeight: '500', color: '#000000' }}
                />
              ) : (
                <p>{rebuttal}</p>
              )}
            </div>
          </div>
        </div>
        {isOpen && !isAnswered && (
          <div className={styles.actions}>
            <Button
              handleClick={() => setIsConfirmRejectionModalOpen(true)}
              style={{
                borderRadius: '4px',
                background: '#E53835',
                width: '200px',
                padding: '.5rem',
                textAlign: 'center',
              }}
            >
              <X weight="fill" color="var(--white)" size={32} /> Rejeitar
              cotação
            </Button>

            <Button
              handleClick={() => setIsApproveModalOpen(true)}
              style={{
                borderRadius: '4px',
                background: 'var(--shade_1)',
                width: '200px',
                padding: '.5rem',
                textAlign: 'center',
              }}
            >
              <Check weight="fill" color="var(--white)" size={32} /> Aprovar
              selecionados
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
