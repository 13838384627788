import { CopySimple, FileText, GridFour } from '@phosphor-icons/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageLayout } from '../../../../shared/PageLayout';
import { AnsweredQuotations } from './pages/AnsweredQuotations';
import { FinishedQuotations } from './pages/FinishedQuotations';
import { CreatedQuotations } from './pages/CreatedQuotations';
import { MobileWorkshopQuotationHeader } from './components/MobileWorkshopQuotationHeader';
import { Circle } from '../../../../shared/Circle';
import { useResponsive } from '../../../../hooks/useResponsive';
import {
  QuotationPages,
  useQuotation,
} from '../../../../contexts/quotationContext';
import useViewModal from './hooks/useViewModal';
import useViewRepliesModal from './hooks/useViewRepliesModal';
import useSelectedQuotation from './hooks/useSelectedQuotation';
import useFinishModal from './hooks/useFinishModal';
import useRedoModal from './hooks/useRedoModal';
import useRejectModal from './hooks/useRejectModal';
import useResumeModal from './hooks/useResumeModal';
import { usePageVisibility } from '../../../../hooks/usePageVisibility';
import useFeedbackModal from './hooks/useFeedbackModal';
import { FeedbackModal } from '../../../../shared/FeedbackModal';

export const WorkshopQuotation = () => {
  const { selectedPage, setSelectedPage, quotationState, fetchQuotations } =
    useQuotation();
  const viewModal = useViewModal();
  const viewReplies = useViewRepliesModal();
  const selectedQuotation = useSelectedQuotation();
  const finishModal = useFinishModal();
  const redoModal = useRedoModal();
  const rejectModal = useRejectModal();
  const resumeModal = useResumeModal();
  const feedbackModal = useFeedbackModal();

  const [searchParams] = useSearchParams();

  const { sizeMobile } = useResponsive();
  const navigate = useNavigate();

  const renderSelectedPage = useMemo(() => {
    switch (selectedPage) {
      case 'created':
        return <CreatedQuotations />;
      case 'replies':
        return <AnsweredQuotations />;
      case 'completed':
        return <FinishedQuotations />;
      default:
        return <CreatedQuotations />;
    }
  }, [selectedPage]);

  const handleSelectedTitle = useMemo(() => {
    switch (selectedPage) {
      case 'created':
        return 'Cotações';
      case 'replies':
        return 'Cotações Respondidas';
      case 'completed':
        return 'Cotações Finalizadas';
      default:
        return 'Cotações';
    }
  }, [selectedPage]);

  const newOffersCounter = useMemo(
    () =>
      quotationState.answeredQuotationList.filter(answer => !answer.visualized)
        .length,
    [quotationState.answeredQuotationList],
  );

  const handlePageNavigate = useCallback(() => {
    const pageToNavigate = searchParams.get('page');

    if (pageToNavigate) {
      setSelectedPage(pageToNavigate as QuotationPages);
    }
  }, [searchParams, setSelectedPage]);

  useEffect(() => {
    handlePageNavigate();
  }, [handlePageNavigate, searchParams]);

  useEffect(() => {
    return () => {
      viewModal.onClose();
      viewReplies.onClose();
      selectedQuotation.onClear();
      finishModal.onClose();
      redoModal.onClose();
      rejectModal.onClose();
      resumeModal.onClose();
    };
  }, []);

  const renderTopMenuButtons = () => {
    if (!sizeMobile) {
      return (
        <>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'created'}
            onClick={() => setSelectedPage('created')}
          >
            <GridFour size={32} weight="fill" />
            Criadas
          </PageLayout.TopMenuButton>
          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'replies'}
            onClick={() => {
              setSelectedPage('replies');
            }}
          >
            <FileText size={32} weight="fill" />
            {newOffersCounter > 0 && `(${newOffersCounter})`} Respondidas
            {newOffersCounter > 0 && (
              <Circle animation="pulse" variant="notification" />
            )}
          </PageLayout.TopMenuButton>

          <PageLayout.TopMenuButton
            isSelected={selectedPage === 'completed'}
            onClick={() => setSelectedPage('completed')}
          >
            <CopySimple size={32} weight="fill" />
            Finalizadas
          </PageLayout.TopMenuButton>
        </>
      );
    }
    return (
      <MobileWorkshopQuotationHeader
        newOffers={newOffersCounter}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
      />
    );
  };

  const navigateBetweenPages = () => {
    if (selectedPage === 'created') return navigate('/home');
    if (selectedPage === 'replies') return setSelectedPage('created');
    if (selectedPage === 'completed') return setSelectedPage('replies');
  };

  const refetchQuotations = useCallback(async () => {
    feedbackModal.onOpen();
    await fetchQuotations();
    feedbackModal.onClose();
  }, [fetchQuotations]);

  const saveExitTime = useCallback(() => {
    localStorage.setItem('exitTime', Date.now().toString());
  }, []);
  const checkReturnTime = useCallback(() => {
    const exitTime = localStorage.getItem('exitTime');

    if (exitTime) {
      const timeElapsed = Date.now() - parseInt(exitTime, 10);
      // Verifica se o tempo fora do app
      if (timeElapsed >= 60 * 1000) {
        refetchQuotations(); // Recarrega os dados se o tempo foi suficiente
      }
    } else {
      // recarrega caso não haja um problema no salvamento do momento da saída
      refetchQuotations();
    }
    // Limpa o registro de saída após retornar
    localStorage.removeItem('exitTime');
  }, [refetchQuotations]);

  usePageVisibility({
    onVisible: checkReturnTime,
    onHidden: saveExitTime,
  });

  return (
    <PageLayout.Root
      title={handleSelectedTitle}
      topMenu={renderTopMenuButtons()}
      handleNavigation={() => {
        if (sizeMobile) {
          navigateBetweenPages();
        } else {
          navigate('/home');
        }
      }}
    >
      {renderSelectedPage}
      <FeedbackModal
        isOpen={feedbackModal.isOpen}
        // isOpen
        variant="sending"
      />
    </PageLayout.Root>
  );
};
