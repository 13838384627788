import budget from '../../../../../assets/img/LandingPage/landing-page-budget.svg';
import services from '../../../../../assets/img/LandingPage/landing-page-services.svg';
import inteligence from '../../../../../assets/img/LandingPage/landing-page-inteligence.svg';
import preventive from '../../../../../assets/img/LandingPage/landing-page-service-fleet-prev.svg';
import notify from '../../../../../assets/img/LandingPage/landing-page-service-fleet-notify.svg';
import price from '../../../../../assets/img/LandingPage/landing-page-service-gasStation-price.svg';
import detailed from '../../../../../assets/img/LandingPage/landing-page-service-gasStation-detailed-display.svg';
import payment from '../../../../../assets/img/LandingPage/landing-page-service-gasStation-payment.svg';
import yourHand from '../../../../../assets/img/LandingPage/landing-app-your-hand.svg';

// oficinas, frotas, postos
const contentText = {
  Oficina: {
    section1: {
      h1: 'Orçamentos Sob Medida',
      p: 'Ofereça aos seus clientes orçamentos cuidadosamente elaborados, que se adaptam perfeitamente às necessidades individuais de cada um. Com nossa ferramenta intuitiva, você pode criar propostas que destacam o valor do seu serviço, garantindo transparência e confiança desde o primeiro contato.',
      image: budget,
    },
    section2: {
      h1: 'Gestão Eficiente de Ordens de Serviço',
      p: 'Simplifique a gestão das suas tarefas com um sistema que permite criar, editar e gerenciar ordens de serviço de maneira eficiente. Tenha o controle total do fluxo de trabalho e acesse o histórico completo de cada serviço prestado, possibilitando um acompanhamento detalhado e um atendimento ao cliente de excelência.',
      image: services,
    },
    section3: {
      h1: 'Agendamento Inteligente de Manutenções Preventivas',
      p: 'Antecipe-se às necessidades dos seus clientes com um planejamento estratégico de manutenções preventivas. Nosso sistema permite agendar e notificar os clientes sobre as manutenções próximas, evitando desgastes e custos desnecessários, e assegurando a longevidade e o bom funcionamento dos veículos.',
      image: inteligence,
    },
  },
  Posto: {
    section1: {
      h1: 'Atualização em Tempo Real de Combustíveis e Preços',
      p: 'Maximize a satisfação do cliente com nosso serviço de atualização instantânea para postos de combustível. Atualize os preços e a disponibilidade dos seus combustíveis diretamente na nossa plataforma e permita que os clientes vejam as informações em tempo real através do app. Simples, rápido e eficiente – mantenha seu posto competitivo e seus clientes sempre informados.',
      image: price,
    },
    section2: {
      h1: 'Exibição de Serviços Detalhados',
      p: 'Garanta que seus clientes estejam sempre bem-informados com o acesso a informações atualizadas do seu posto de combustível. A nossa plataforma permite que você destaque fotos e o horário de operação de cada serviço oferecido - seja a loja de conveniência, a borracharia ou o próprio abastecimento - com clareza e precisão, permitindo que você mostre aos seus clientes a excelência e o cuidado presentes em cada detalhe do seu negócio.',
      image: detailed,
    },
    section3: {
      h1: 'Atualização das Formas de Pagamento',
      p: 'Ofereça a flexibilidade que o cliente moderno espera. Informe claramente todas as formas de pagamento aceitas no seu estabelecimento e atualize-as facilmente conforme você expande suas opções.',
      image: payment,
    },
  },
  Frota: {
    section1: {
      h1: 'Cotações',
      p: 'Busque pelas lojas que ofecerem o melhor preço usando nosso sistema de cotações. Com ele, o trabalho manual de reenviar uma lista de peças é simplificado, permitindo enviar automaticamente o orçamento feito por uma oficina para outras autopeças diretamente pelo whatsapp. Além disso, as respostas são recebidas diretamente no nosso sistema, permitindo que você escolha as melhores ofertas recebidas!',
      image: budget,
    },
    section2: {
      h1: 'Manutenções preventivas em lista',
      p: 'Não espere os seus veículos quebrarem para fazer uma manutenção! Com o sistema de manutenção preventiva do nosso aplicativo, você pode cadastrar os seus veículos e ser notificado sempre que estiver próxima a data de realização de alguma manuteção preventiva. Além disso, você mesmo pode personalizar quando será alertado e quais manutenções serão monitoradas!',
      image: preventive,
    },
    section3: {
      h1: 'Notificações personalizadas',
      p: 'Seja notificado sempre que algo importante sobre o seu veículo for acontecer. Com o aplicativo móvel, você pode monitorar essas notificações e usa-las para se preparar para um problema antes dele acontecer!',
      image: notify,
    },
  },
};

const mobilePropaganda = {
  image: yourHand,
  h1: 'Seu Veículo na Palma da Mão',
  p: 'Apresentamos o nosso aplicativo gratuito, uma extensão  digital do nosso site pensado tanto para você, quanto para o seu cliente. Com ele, visualize seus veículos cadastrados, acessando históricos detalhados e manutenções programadas com apenas um toque. Consulte valores atualizados pela Tabela FIPE, e planeje suas viagens com o recurso de cálculo de distância e custo, otimizando seu tempo e economia. Tudo isso e muito mais, para levar praticidade e controle ao seu dia a dia. Baixe o AutoCenter App e tenha a solução completa para você e seu veículo!',
};

export const getPageService = (pageService: string) => {
  if (pageService === 'Oficina') {
    return contentText.Oficina;
  }
  if (pageService === 'Postos') {
    return contentText.Posto;
  }
  if (pageService === 'Frotas') {
    return contentText.Frota;
  }

  return contentText.Oficina;
};

export const getMobilePropaganda = () => {
  return mobilePropaganda;
};
