import { useEffect, useState, useRef, useCallback } from 'react';
import { Socket } from 'socket.io-client';
import { socket } from '../services/api/socket';
import logErrorToElastic from '../services/logs/logErrorToElastic';
import { useAuth } from './useAuth';

export const useSocket = () => {
  const socketInstanceRef = useRef<Socket>(socket);
  const [isConnected, setIsConnected] = useState(false);
  const { user } = useAuth();

  const initializeSocket = useCallback(async () => {
    const socketInstance = socketInstanceRef.current;
    if (socketInstance) {
      socketInstance.emit('entry', { user_id: user?.id_user ?? '' });
      const handleConnect = () => {
        setIsConnected(true);
        console.log('Conectado');
      };

      const handleDisconnect = (reason: any) => {
        setIsConnected(false);
        console.log('Desconectado');
        const errorData = {
          eventType: 'disconnect',
          error: reason
            ? reason.message || 'Unknown error'
            : 'No error information',
        };
        logErrorToElastic('socket', errorData);
      };

      socketInstance.on('connect', handleConnect);
      socketInstance.on('disconnect', handleDisconnect);

      return () => {
        socketInstance.off('connect', handleConnect);
        socketInstance.off('disconnect', handleDisconnect);
        socketInstance.disconnect();
      };
    }
    // garantir que sempre retorna uma função válida
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [user]);

  useEffect(() => {
    const token = localStorage.getItem('@AutoCenter: accessToken') ?? '';
    if (token && token.length > 0) {
      initializeSocket();
    }
  }, [initializeSocket]);
  return {
    isConnected,
    socketInstance: socketInstanceRef.current,
  };
};
