/* eslint-disable jsx-a11y/label-has-associated-control */
import { FunnelSimple } from 'phosphor-react';
import { ChangeEvent } from 'react';
import styles from './companies-filter.module.css';

interface Props {
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const CompaniesFilter = ({ handleChange }: Props) => {
  return (
    <div className={styles.searchOptions}>
      <div className={styles.option}>
        <input
          type="radio"
          id="workshop"
          name="searchOptions"
          value="workshop"
          defaultChecked
          onChange={handleChange}
        />
        <label htmlFor="workshop">
          <FunnelSimple size={18} />
          Oficina
        </label>
      </div>
      <div className={styles.option}>
        <input
          type="radio"
          id="service"
          name="searchOptions"
          value="service"
          onChange={handleChange}
        />
        <label htmlFor="service">
          <FunnelSimple size={18} />
          Serviço
        </label>
      </div>
      <div className={styles.option}>
        <input
          type="radio"
          id="product"
          name="searchOptions"
          value="product"
          onChange={handleChange}
        />
        <label htmlFor="product">
          <FunnelSimple size={18} />
          Peça
        </label>
      </div>
    </div>
  );
};
