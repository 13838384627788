import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  Input,
  Row,
  Spinner,
  Text,
} from 'native-base';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import axios from 'axios';
import { ArrowClockwise } from 'phosphor-react';
import pixel from '../../../../../../styles/size';
import { AddressResponse, CustomerFormProps, FormValues } from './interfaces';
import { CustomerProps } from '../../../../../../features/orderService/interfaces';
import api from '../../../../../../services/api/api';
import { InputAutocomplete } from '../../InputAutocomplete';
import {
  maskCep,
  maskCpfCnpj,
  maskPhone,
} from '../../../../../../services/helpers/mask';
import { validateForm } from './schema';
import { AlertModal } from '../../../../../../shared/AlertModal';
import { findCustomersByWorkshopId } from './model';
import {
  createAddressController,
  createCustomerController,
  updateAddressController,
} from './controller';
import { Repair } from '../../../../../common/History/interfaces';
import {
  getObjectFromLocal,
  saveObjectLocally,
} from '../../../../../../utils/localStorage';
import { transferVehicleController } from '../VehicleForm/controller';
import { FeedbackModal } from '../../../../../../shared/FeedbackModal';
import { IAddress } from '../../../../../../@types/interface';
import { COLORS } from '../../../../../../styles/colors';
// import { ProgressBar } from '../../../../../../shared/ProgressBar';
import { Header } from '../../../../../../shared/Header';
import { MainComponent } from '../../../../../../shared/MainComponent/MainComponent';
import { ProgressBar } from '../../../../../../shared/ProgressBar';
import { useAuth } from '../../../../../../hooks/useAuth';
// import { visitorId } from '../../../../../../routes/routes';

const formControlProps = {
  w: { base: pixel(830, 1080), xl: pixel(730, 1920) },
  mb: '2rem',
};

const requiredLabelProps = {
  style: { color: '#E00D0D', fontWeight: 600, marginLeft: '5px' },
  children: '*',
};

const buttonProps = {
  variant: 'outline',
  h: '10',
  w: '6rem',
  borderRadius: '9px',
};

export const CustomerForm = ({
  setPage,
  isSeparateCustomer,
  setIsSeparateCustomer,
  showTransferVehicle,
  setShowTransferVehicle,
  previousOwnerId,
}: CustomerFormProps) => {
  const [customersList, setCustomersList] = useState<CustomerProps[]>([]);
  const [customerName, setCustomerName] = useState<string>('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [changeVehicleOwner, setChangeVehicleOwner] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const { workshop } = useAuth();

  const [localOrderServiceData, setLocalOrderServiceData] =
    useState<Repair | null>(null);

  const { control, getValues, reset, setValue } = useForm<FormValues>({
    defaultValues: {
      name: '',
      document: '',
      phone: '',
      email: '',
      city: '',
      district: '',
      number: '',
      street: '',
      uf: '',
      zip: '',
    },
  });

  useEffect(() => {
    let localOrderService: Repair | null = null;
    fetchCustomers();
    localOrderService = getObjectFromLocal('@local-order-service');
    setLocalOrderServiceData(localOrderService);
    // if (userLocal && userLocal.id_user !== visitorId) {
    //   fetchCustomers();
    //   localOrderService = getObjectFromLocal('@local-order-service');
    //   setLocalOrderServiceData(localOrderService);
    // } else {
    //   localOrderService = OrderServiceData as Repair;

    //   setLocalOrderServiceData(localOrderService);
    // }
    if (localOrderService) {
      let osAddress = {} as IAddress;
      const { phone, name, address, email } = localOrderService;
      let userData: Partial<FormValues> = {
        name,
        email: email || '',
        phone,
      };

      if (localOrderService?.user_workshop?.user) {
        const { user } = localOrderService.user_workshop;
        userData = {
          ...userData,
          document: user.document,
          email: user.email || email || '',
        };
        reset({ ...userData });
        setCustomerName(name || '');
        // se houver endereço do cliente
        if (address) {
          osAddress = address;
        }
      }
      // se houver endereço da OS
      if (localOrderService.address) {
        osAddress = localOrderService.address;
      }
      if (osAddress) {
        const { city, district, number, street, uf, zip } = osAddress;
        reset({
          ...userData,
          city: city || '',
          district: district || '',
          number: number || '',
          street: street || '',
          uf: uf || '',
          zip: zip || '',
        });
      }

      setCustomerName(localOrderService?.user_workshop?.user?.name || '');
    } else {
      setIsSeparateCustomer(true);
    }
  }, []);

  const customerNames = customersList.map(item => item.name);
  customerNames.sort();

  const handleSave = (
    id_address?: string,
    id_user?: string,
    id_users_Workshop?: string,
  ) => {
    let localOrderService: Repair | null = null;

    const { city, district, number, street, uf, zip, ...customerData } =
      getValues();

    const address = {
      city,
      district,
      number,
      street,
      uf,
      zip,
      id_address: id_address || '',
    };

    if (localOrderServiceData) {
      localOrderService = {
        ...localOrderServiceData,
        name: customerData.name,
        phone: customerData.phone,
        email: customerData.email,
        address,
        user_workshop: {
          ...localOrderServiceData?.user_workshop,
          id_users_Workshop: id_users_Workshop || '',
          user: {
            ...localOrderServiceData?.user_workshop?.user,
            ...customerData,
            id_user: id_user || '',
            address,
          },
        },
      };
    }
    saveObjectLocally('@local-order-service', localOrderService);
  };

  const fetchCustomers = async () => {
    const customerResponse = await findCustomersByWorkshopId(
      workshop?.id_workshop ?? '',
    );
    setCustomersList(
      customerResponse.map(customer => ({
        name: customer?.user?.name || '',
        document: customer?.user?.document || '',
        phone: customer?.user?.phone || '',
        email: customer?.user?.email || '',
        id_user: '',
        address: customer.user.address,
        address_id: customer?.user?.address_id || null,
        vehicles: [],
      })),
    );
  };

  const getSelectedCustomer = (value: string) => {
    return (
      customersList.find(customer => customer.name === value) || {
        id_user: '',
        name: '',
        email: '',
        phone: '',
        document: '',
        address: null,
        address_id: null,
        vehicles: [],
      }
    );
  };

  const fetchCustomerAddress = async (address_id: string) => {
    try {
      const res = await api.get(`/user/Address?addressId=${address_id}`);

      if (res.data) {
        const formValues = getValues();
        const { city, street, district, number, uf, zip } = res.data;
        reset({
          ...formValues,
          city: city || '',
          district: district || '',
          number: number || '',
          street: street || '',
          uf: uf || '',
          zip: zip || '',
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCustomerData = () => {
    const selectedCustomer = getSelectedCustomer(customerName);
    if (selectedCustomer && selectedCustomer.name) {
      reset({
        name: customerName,
        email:
          selectedCustomer?.email ||
          localOrderServiceData?.user_workshop?.user?.email ||
          '',
        phone:
          selectedCustomer?.phone ||
          localOrderServiceData?.user_workshop?.user?.phone ||
          '',
        document:
          selectedCustomer?.document ||
          localOrderServiceData?.user_workshop?.user?.document ||
          '',
      });
    }
    if (selectedCustomer.address_id) {
      fetchCustomerAddress(selectedCustomer.address_id);
    }
  };

  const handleAddress = async (value: string) => {
    try {
      setLoadingSearch(true);
      const { data } = await axios.get<AddressResponse>(
        `https://viacep.com.br/ws/${value}/json/`,
      );
      const formValues = getValues();
      reset({
        ...formValues,
        city: data?.localidade || '',
        district: data?.bairro || '',
        uf: data?.uf || '',
        street: data?.logradouro || '',
        number: data?.number || '',
      });
    } catch (err) {
      console.error(err);
    }
    setLoadingSearch(false);
  };

  const handleChangePage = async () => {
    setIsSending(true);
    const formValues = getValues();
    const isFormValid = await validateForm(formValues);
    if (isFormValid) {
      if (!isSeparateCustomer) {
        const newCustomer = await createCustomerController({
          ...formValues,
        });
        if (localOrderServiceData) {
          const { vehicle_id, address } = localOrderServiceData;
          if (
            (changeVehicleOwner || previousOwnerId.length === 0) &&
            vehicle_id &&
            newCustomer
          ) {
            await transferVehicleController({
              userId: newCustomer.user_id,
              vehicleId: localOrderServiceData.vehicle_id,
            });
          }
          if (address) {
            const { id_address } = address;
            if (id_address) {
              console.log('  atualizar endereço');
              const { city, district, number, street, uf, zip } = formValues;
              const newAddress = await updateAddressController({
                city,
                district,
                number,
                street,
                uf,
                zip,
                addressId: id_address,
              });

              if (newCustomer && newAddress) {
                setLocalOrderServiceData(prevData => {
                  if (prevData)
                    return {
                      ...prevData,
                      address: newAddress,
                    };
                  return null;
                });
                handleSave(
                  newAddress.id_address as string,
                  newCustomer.user_id as string,
                  newCustomer.id_users_Workshop as string,
                );
                setPage(2);
              }
            } else {
              const newAddress = await createAddressController({
                ...formValues,
              });
              if (newCustomer && newAddress) {
                setLocalOrderServiceData(prevData => {
                  if (prevData)
                    return {
                      ...prevData,
                      address: newAddress,
                    };
                  return null;
                });
                handleSave(
                  newAddress.id_address as string,
                  newCustomer.user_id as string,
                  newCustomer.id_users_Workshop as string,
                );
                setPage(2);
              }
            }
          } else {
            const newAddress = await createAddressController({
              ...formValues,
            });
            if (newCustomer && newAddress) {
              setLocalOrderServiceData(prevData => {
                if (prevData)
                  return {
                    ...prevData,
                    address: newAddress,
                  };
                return null;
              });
              handleSave(
                newAddress.id_address as string,
                newCustomer.user_id as string,
                newCustomer.id_users_Workshop as string,
              );
              setPage(2);
            }
          }
        }
      } else if (isSeparateCustomer) {
        handleSave();
        setPage(2);
      } else {
        setPage(2);
      }
    }
    setIsSending(false);
  };

  const clearClientFields = () => {
    reset({
      name: '',
      city: '',
      district: '',
      document: '',
      email: '',
      number: '',
      phone: '',
      street: '',
      uf: '',
      zip: '',
    });
  };

  useEffect(() => {
    handleCustomerData();
  }, [customerName]);

  useEffect(() => {
    if (isSeparateCustomer) {
      const name = localOrderServiceData?.name || 'Cliente avulso';
      const phone = localOrderServiceData?.phone || '';

      reset({
        name,
        city: '',
        district: '',
        document: '',
        email: '',
        number: '',
        phone,
        street: '',
        uf: '',
        zip: '',
      });
    } else if (
      localOrderServiceData &&
      localOrderServiceData.user_workshop &&
      localOrderServiceData.user_workshop.user
    ) {
      const { document, email, address } =
        localOrderServiceData.user_workshop.user;
      const { phone, name } = localOrderServiceData;
      const userData = {
        name: name || '',
        document,
        email: email || '',
        phone: maskPhone(phone || ''),
      };
      reset({ ...userData });
      if (address) {
        const { zip, street, uf, city, district, number } = address;
        reset({
          ...userData,
          zip,
          street,
          uf,
          city,
          district,
          number,
        });
      }
    }
  }, [isSeparateCustomer, reset]);

  const handleChangeOwner = (newValue: boolean) => {
    if (!newValue) setChangeVehicleOwner(false);
    setShowModal(newValue);
  };

  return (
    <MainComponent>
      <Header
        title="Informações do Cliente"
        page={1}
        setPage={setPage}
        onClick={() => {
          handleSave();
          setPage(0);
        }}
      />
      <div
      // className={styles.content}
      >
        <ProgressBar
          currentPage={1}
          pages={['Veículos', 'Clientes', 'Serviços']}
        />

        <Box>
          <AlertModal
            content="Confirma a transferência de veículo? O atual proprietário será notificado."
            isOpen={showModal}
            setIsOpen={setShowModal}
            onConfirm={() => {
              setChangeVehicleOwner(true);
              clearClientFields();
            }}
            onCancel={() => setChangeVehicleOwner(false)}
          />
          <FeedbackModal isOpen={isSending} variant="sending" />
          <Row
            w={{ base: pixel(830, 1080), xl: pixel(730, 1920) }}
            justifyContent={{ base: 'center', xl: 'space-between' }}
          >
            <Box justifyContent="center" alignItems="start">
              <Checkbox.Group>
                <Checkbox
                  isChecked={isSeparateCustomer}
                  onChange={(value: boolean) => setIsSeparateCustomer(value)}
                  value=""
                  alignItems="center"
                  _text={{
                    fontSize: '.8rem',
                  }}
                >
                  <Text>Cliente Avulso</Text>
                </Checkbox>
              </Checkbox.Group>
            </Box>
            <Button
              p={0}
              variant="unstyled"
              onPress={() => {
                clearClientFields();
                setShowTransferVehicle(false);
              }}
            >
              <Box flexDir="row" alignItems="center">
                <IconButton
                  variant="unstyled"
                  onPress={clearClientFields}
                  icon={<ArrowClockwise size="1.5rem" color={COLORS.GREEN} />}
                />
                <Text color={COLORS.GREEN} textDecorationLine="underline">
                  Limpar campos
                </Text>
              </Box>
            </Button>
          </Row>
          {(customersList.length === 0 || isSeparateCustomer) && (
            <FormControl {...formControlProps}>
              <FormControl.Label>
                Nome
                <sup {...requiredLabelProps} />
              </FormControl.Label>
              <Controller
                control={control}
                name="name"
                rules={{ required: true }}
                render={({ field }) => <Input h="3rem" {...field} />}
              />
            </FormControl>
          )}

          {customersList.length !== 0 && !isSeparateCustomer && (
            <InputAutocomplete
              setShowTransferVehicle={setShowTransferVehicle}
              setFormName={(value: string) => setValue('name', value)}
              value={customerName}
              setValue={(value: string) => setCustomerName(value)}
              options={customerNames.filter(
                (name, index, self) => self.indexOf(name) === index,
              )}
            />
          )}

          <Row
            justifyContent="center"
            mb=".8rem"
            px=".5rem"
            display={
              !isSeparateCustomer && showTransferVehicle ? 'flex' : 'none'
            }
          >
            <Text
              fontSize=".8rem"
              color="gray.4"
              fontWeight="medium"
              w={{ base: '50%', xl: '80%' }}
            >
              Caso esse veículo não pertença a esse cliente
            </Text>
            <Checkbox
              accessibilityLabel="transferir veículo"
              value="1"
              borderColor="green.1"
              isChecked={changeVehicleOwner}
              onChange={handleChangeOwner}
            >
              <Text underline color="green.1" fontWeight="semibold">
                Clique Aqui!
              </Text>
            </Checkbox>
          </Row>
          <FormControl
            {...formControlProps}
            display={!isSeparateCustomer ? 'flex' : 'none'}
          >
            <FormControl.Label>
              CPF/CNPJ
              <sup {...requiredLabelProps} />
            </FormControl.Label>
            <Controller
              control={control}
              name="document"
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  h="3rem"
                  {...field}
                  value={maskCpfCnpj(field.value)}
                  isDisabled={isSeparateCustomer}
                  fontSize="1rem"
                />
              )}
            />
          </FormControl>
          <FormControl {...formControlProps}>
            <FormControl.Label>Telefone</FormControl.Label>
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <Input
                  h="3rem"
                  {...field}
                  value={maskPhone(field.value)}
                  fontSize="1rem"
                />
              )}
            />
          </FormControl>
          <FormControl
            {...formControlProps}
            display={!isSeparateCustomer ? 'flex' : 'none'}
          >
            <FormControl.Label>Email</FormControl.Label>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <Input
                  h="3rem"
                  {...field}
                  isDisabled={isSeparateCustomer}
                  fontSize="1rem"
                />
              )}
            />
          </FormControl>
          {!isSeparateCustomer && (
            <>
              <Row {...formControlProps} mx="auto">
                <FormControl w="32%">
                  <FormControl.Label>
                    CEP {loadingSearch && <Spinner />}
                  </FormControl.Label>
                  <Controller
                    control={control}
                    name="zip"
                    render={({ field }) => (
                      <Input
                        h="3rem"
                        {...field}
                        onChangeText={(value: string) => {
                          if (value.length >= 8) handleAddress(value);
                        }}
                        value={maskCep(field.value)}
                        isDisabled={isSeparateCustomer}
                        fontSize="1rem"
                      />
                    )}
                  />
                </FormControl>
                <FormControl w="32%">
                  <FormControl.Label>Cidade</FormControl.Label>
                  <Controller
                    control={control}
                    name="city"
                    render={({ field }) => (
                      <Input
                        h="3rem"
                        {...field}
                        isDisabled={isSeparateCustomer}
                        fontSize="1rem"
                      />
                    )}
                  />
                </FormControl>
                <FormControl w="32%">
                  <FormControl.Label>UF</FormControl.Label>
                  <Controller
                    control={control}
                    name="uf"
                    render={({ field }) => (
                      <Input
                        h="3rem"
                        {...field}
                        isDisabled={isSeparateCustomer}
                        fontSize="1rem"
                      />
                    )}
                  />
                </FormControl>
              </Row>
              <FormControl {...formControlProps}>
                <FormControl.Label>Bairro</FormControl.Label>
                <Controller
                  control={control}
                  name="district"
                  render={({ field }) => (
                    <Input
                      h="3rem"
                      {...field}
                      isDisabled={isSeparateCustomer}
                      fontSize="1rem"
                    />
                  )}
                />
              </FormControl>
              <Row {...formControlProps} mx="auto">
                <FormControl w="49%">
                  <FormControl.Label>Endereço</FormControl.Label>
                  <Controller
                    control={control}
                    name="street"
                    render={({ field }) => (
                      <Input
                        h="3rem"
                        {...field}
                        isDisabled={isSeparateCustomer}
                        fontSize="1rem"
                      />
                    )}
                  />
                </FormControl>
                <FormControl w="49%">
                  <FormControl.Label>Número</FormControl.Label>
                  <Controller
                    control={control}
                    name="number"
                    render={({ field }) => (
                      <Input
                        h="3rem"
                        {...field}
                        isDisabled={isSeparateCustomer}
                        fontSize="1rem"
                      />
                    )}
                  />
                </FormControl>
              </Row>
            </>
          )}
          <Row justifyContent="space-between">
            <Button
              _hover={{ bg: 'green.1', color: 'white' }}
              onPress={() => {
                handleSave();
                setPage(0);
              }}
              {...buttonProps}
            >
              <AiOutlineLeft size="2rem" />
            </Button>
            <Button
              bg="green.1"
              color="white"
              _hover={{ bg: 'white', color: 'green.1' }}
              onPress={handleChangePage}
              {...buttonProps}
            >
              <AiOutlineRight size="2rem" />
            </Button>
          </Row>
        </Box>
      </div>
    </MainComponent>
  );
};
