import * as yup from 'yup';
import message from '../../../utils/message';
import {
  addAgentPermissions,
  createAgent,
  getAgent,
  getAgentById,
  getAgentPermissions,
  removeAgentPermissions,
  updateAgent,
} from './model';
import { ERROR_MESSAGES } from '../../../utils/validations/errorMessages';
import {
  AddAgentPermissionsInput,
  CreateAgent,
  RemoveAgentPermissionsInput,
  UpdateAgent,
} from './interface';
import { handleError } from '../../../utils/handleError';

export const getAgentController = async () => {
  try {
    const res = await getAgent();
    return res;
  } catch (error) {
    handleError(error);
  }
};
export const getAgentByIdController = async (idAgent: string) => {
  try {
    const res = await getAgentById(idAgent);
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const createAgentController = async (props: CreateAgent) => {
  try {
    const res = await createAgent(props);
    message('Vendedor criado com sucesso', 'success');
    return res;
  } catch (error) {
    message(error, 'error');
  }
};

export const updateAgentController = async (props: UpdateAgent) => {
  try {
    const schema = yup.object({
      agent_id: yup.string().required(ERROR_MESSAGES.required),
      actived: yup.string().required(ERROR_MESSAGES.required),
      class_agent_id: yup.string(),
      max_points: yup.string(),
    });
    await schema.validate(props);

    const res = await updateAgent(props);
    const status = props.actived ? 'ativado' : 'desativado';
    message(`Vendedor ${status} com sucesso`, 'success');
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    } else {
      message(error, 'error');
    }
  }
};

export const getAgentPermissionsController = async () => {
  try {
    const res = await getAgentPermissions();
    return res;
  } catch (error) {
    handleError(error);
  }
};
export const addAgentPermissionsController = async (
  props: AddAgentPermissionsInput,
) => {
  try {
    const schema = yup.object({
      user_id: yup.string().required(ERROR_MESSAGES.required),
      permissions: yup.array().of(yup.string()),
    });
    await schema.validate(props);

    const res = await addAgentPermissions(props);
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const removeAgentPermissionsController = async (
  props: RemoveAgentPermissionsInput,
) => {
  try {
    const schema = yup.object({
      user_id: yup.string().required(ERROR_MESSAGES.required),
      permissions: yup.array().of(yup.string()),
    });
    await schema.validate(props);

    const res = await removeAgentPermissions(props);
    return res;
  } catch (error) {
    handleError(error);
  }
};
