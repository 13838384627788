/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useMemo, useState } from 'react';
import { FileText } from 'phosphor-react';
import {
  AnswerQuotation,
  ItemAnswerQuotation,
  ItemQuotation,
} from '../../../../../../../@types/interface';
import { Product } from '../Product';
import styles from './item.module.css';

interface Props {
  index: number;
  item: ItemQuotation | null;
  itemsAnswerQuotation?: ItemAnswerQuotation[];
  isEmpty?: boolean;
  answer?: AnswerQuotation | null;
  handleWithSubTotal?: (subTotal: number, quantity: number) => void;
}

export const Item = ({
  item,
  index,
  itemsAnswerQuotation = [],
  isEmpty,
  answer = null,
  handleWithSubTotal,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      setIsOpen(previous => !previous);
    },
    [],
  );

  const reference = useMemo(() => {
    if (!item) return 'Não Informada';
    if (!item?.reference || item.reference.length === 0) return 'Não Informada';
    return item?.reference;
  }, [item]);

  const description = useMemo(() => {
    return item?.description ?? '';
  }, [item?.description]);

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={styles['item-info']}>
        <span>{index}.</span>
        <span>
          {description}{' '}
          <b>
            {!isOpen &&
              itemsAnswerQuotation.length > 0 &&
              `${itemsAnswerQuotation.length} resposta(s)`}
          </b>
        </span>
        <span>{item?.quantity}</span>
        <span>{reference}</span>
      </div>
      {isOpen && (
        <div className={styles['product-list']}>
          {isEmpty ? (
            <div className={styles['empty-row']}>
              <FileText size={32} />
              <span>Sem Oferta</span>
            </div>
          ) : (
            <>
              {itemsAnswerQuotation.map(answerItem => (
                <Product
                  itemAnswerQuotation={answerItem}
                  key={answerItem.id_item_answer_quotation}
                  answered={answer?.answered}
                  approved={answer?.approved}
                  handleWithSubTotal={handleWithSubTotal}
                />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};
