import { useCallback, useEffect, useState } from 'react';
import { Quotation } from '../../../../../../../@types/interface';
import { getMyQuotationsController } from '../../../controller';
import { QuotationFilterOptions } from '../../../interface';
import { useQuotation } from '../../../../../../../contexts/quotationContext';
import useSelectedQuotation from '../../../hooks/useSelectedQuotation';

const LIMIT = 80;

export const useAnsweredQuotations = () => {
  const { setQuotationState } = useQuotation();

  const [page, setPage] = useState(1);

  const [searchedQuotations, setSearchedQuotations] = useState<Quotation[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  const [showBlockedQuotationMessage, setShowBlockedQuotationMessage] =
    useState(false);
  const [filterType, setFilterType] = useState<QuotationFilterOptions>('code');
  const [filterValue, setFilterValue] = useState('');

  const selectedQuotation = useSelectedQuotation();

  const fetchNotAnsweredQuotations = useCallback(
    async (pageRequest: number) => {
      setIsLoading(true);
      if (!noMoreData) {
        const res = await getMyQuotationsController({
          limit: LIMIT,
          page: pageRequest,
          option: 'answered',
        });

        if (res && res.length > 0) {
          setQuotationState(previous => {
            const newQuotations = res.filter(
              quotation =>
                !previous.answeredQuotationList.some(
                  existingQuotation =>
                    quotation.id_quotation === existingQuotation.id_quotation,
                ),
            );
            return {
              ...previous,
              answeredQuotationList: [
                ...previous.answeredQuotationList,
                ...newQuotations,
              ],
            };
          });
        } else {
          setNoMoreData(true);
        }
      }
      setIsLoading(false);
    },
    [noMoreData],
  );

  const loadMoreData = useCallback(
    (previousPage = 1) => {
      if (!noMoreData) {
        fetchNotAnsweredQuotations(previousPage);
        setPage(previousPage + 1);
      }
    },
    [fetchNotAnsweredQuotations, noMoreData],
  );

  useEffect(() => {
    loadMoreData();
  }, [loadMoreData]);

  const handleRowClick = (quotation: Quotation) => {
    if (
      !selectedQuotation ||
      selectedQuotation?.quotation?.id_quotation !== quotation.id_quotation
    ) {
      selectedQuotation.onSelect(quotation);
    } else {
      selectedQuotation.onClear();
    }
  };

  const handleShowBlockedQuotationMessage = (option: 'close' | 'open') => {
    if (option === 'open') setShowBlockedQuotationMessage(true);
    else setShowBlockedQuotationMessage(false);
  };

  const handleChangeQuotationState = (quotation: Quotation) => {
    setQuotationState(previous => ({
      ...previous,
      answeredQuotationList: previous.answeredQuotationList.filter(
        repliedQuotation =>
          repliedQuotation.id_quotation !== quotation.id_quotation,
      ),
    }));
    selectedQuotation.onClear();
  };

  const fetchQuotationsByFilter = useCallback(async () => {
    if (filterValue.trim().length > 0) {
      setIsSearching(true);
      const res = await getMyQuotationsController({
        option: 'answered',
        filter: filterType,
        filter_value: filterValue,
      });

      if (res && res.length > 0) {
        setSearchedQuotations(res);
      } else {
        setSearchedQuotations([]);
      }
    } else {
      setSearchedQuotations([]);
    }
    setIsSearching(false);
  }, [filterType, filterValue]);

  const handleDateSearch = (period: string[]) => {
    // clear search state for invalid period
    if (period.length !== 2) {
      setFilterValue('');
    } else {
      const [initialDate, finalDate] = period;
      if (initialDate.trim().length > 0 && finalDate.trim().length > 0) {
        setFilterValue(`["${initialDate}", "${finalDate}"]`);
      }
    }
  };
  const handleSearch = (query: string) => {
    setFilterValue(filterType === 'plate' ? query.toUpperCase() : query);
  };

  const resetStates = useCallback(async () => {
    // reset search controllers
    setNoMoreData(false);
    setPage(0);
  }, []);

  useEffect(() => {
    if (filterValue.trim().length > 0) {
      fetchQuotationsByFilter();
    }
  }, [fetchQuotationsByFilter, filterValue]);

  return {
    page,
    isLoading,
    noMoreData,
    loadMoreData,
    handleRowClick,
    fetchNotAnsweredQuotations,
    handleShowBlockedQuotationMessage,
    showBlockedQuotationMessage,
    handleChangeQuotationState,
    handleDateSearch,
    handleSearch,
    filterType,
    filterValue,
    searchedQuotations,
    setFilterType,
    resetStates,
    isSearching,
  };
};
