import { Button, Divider, Input, Row, Stack, Text } from 'native-base';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import api from '../../../../../../services/api/api';
import pixel from '../../../../../../styles/size';
import { EditableTable } from '../../../../../../shared/EditableTable/EditableTable';
import {
  Banner,
  CheckListRequest,
  ChecklistInput,
  IService,
} from '../../../../../../@types/interface';
import { InputControl } from '../../../../../workshop/Budget/components/InputControl';
import { SendBudgetModal } from '../../../../../../shared/SendBudgetModal';
import message from '../../../../../../utils/message';
import {
  maskLicensePlate,
  numberOnly,
} from '../../../../../../services/helpers/mask';
import { sendWhatsappMessage } from '../../../../../../utils/sendWhatsappMessage';
import { FormValues, ServiceFormProps } from './interfaces';
import { validateForm } from './validation';
import { formatServiceTable } from '../../../../../../utils/formatters/serviceFormat';
import {
  setBudget,
  setBudgetChecklist,
  updateBudgetReducer,
} from '../../../../../../features/budget/budget-slice';
import { ChecklistForm } from '../../../../../../shared/CheckListForm';
import { COLORS } from '../../../../../../styles/colors';
import { MainComponent } from '../../../../../../shared/MainComponent/MainComponent';
import { Header } from '../../../../../../shared/Header';
import { ProgressBar } from '../../../../../../shared/ProgressBar';
import styles from './service-form.module.css';
import { useAuth } from '../../../../../../hooks/useAuth';

export const ServiceForm = ({ page, setPage }: ServiceFormProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const budget = useAppSelector(state => state.budget.budget);
  const checklistFromState = useAppSelector(
    state => state.orderServices.checklistInput,
  );

  const [services, setServices] = useState<IService[]>(
    budget
      ? budget.child_services
      : [{ cod_prod: '1', description: '', name: '', quantity: 0, total: 0 }],
  );
  const { control, handleSubmit, reset, getValues } = useForm<FormValues>();

  const [idBudget, setIdBudget] = useState();
  const [subtotal, setSubtotal] = useState<number>(0);
  const [isBudgetModalOpen, setIsBudgetModalOpen] = useState(false);
  const [statusModal, setStatusModal] = useState<
    'sending' | 'success' | 'error'
  >('sending');
  const [isCheckListOpen, setIsCheckListOpen] = useState(false);
  const [checklistData, setChecklistData] = useState<ChecklistInput[]>([]);
  const [checklistId, setChecklistId] = useState(budget?.checklist_id || '');
  const [discount, setDiscount] = useState<string>(
    budget ? budget.discount.toString() : '0',
  );
  const [bannersToRemove, setBannersToRemove] = useState<string[]>([]);
  const [uploadErrors, setUploadErrors] = useState<number[]>([]);

  const { workshop } = useAuth();

  // Budget
  const createBudget = async (budgetBody: any) => {
    try {
      const { data: response } = await api.post('/budget', {
        workshopId: workshop?.id_workshop,
        ...budgetBody,
      });
      if (response) {
        setIdBudget(response.id_budget);
        setStatusModal('success');
        dispatch(updateBudgetReducer(response));
      }
    } catch (err) {
      setStatusModal('error');
      message(err, 'error');
    }
  };

  const updateBudget = async (budgetBody: any) => {
    try {
      const { data: response } = await api.put('/budget', {
        workshopId: workshop?.id_workshop,
        id_budget: budget.id_budget,
        ...budgetBody,
      });
      if (response) {
        setIdBudget(response.id_budget);
        setStatusModal('success');
        dispatch(updateBudgetReducer(response));
      }
    } catch (err) {
      setStatusModal('error');
      message(err, 'error');
    }
  };

  const onSubmit = async (data: FormValues) => {
    setIsBudgetModalOpen(true);

    const { validity, defect, comment } = data;
    const checklistIdResponse = await handleSubmitChecklist();

    const budgetBody: any = {
      validity,
      defect,
      comment,
      name: budget.name,
      phone: budget.phone,
      document: budget.document,
      child_services: formatServiceTable(services),
      discount: parseFloat(discount || '0'),
      total: subtotal,
      plate: maskLicensePlate(budget.plate) || '',
      vehicle: budget.vehicle,
      status: budget.status || '',
    };

    const validation = validateForm(budgetBody);

    if (validation.isValid) {
      if (budget.id_budget.length > 0) updateBudget(budgetBody);
      else {
        if (checklistIdResponse || checklistId.length > 0) {
          budgetBody.checklistId = checklistIdResponse || checklistId;
        }
        createBudget(budgetBody);
      }
    } else {
      setIsBudgetModalOpen(false);
      console.log('errors: ', validation.errors);
      message(validation.errors[0], 'warning');
    }
  };
  // CHECKLIST
  const createChecklist = async (checklistRequest: ChecklistInput[]) => {
    try {
      const formData = new FormData();
      if (checklistRequest[0].file) {
        formData.append('file', checklistRequest[0].file);
      }
      const { data } = await api.post<CheckListRequest>(
        '/checklist',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  };
  const removeBanner = async (id_banner: string) => {
    try {
      await api.delete(`/banner/${id_banner}`);
    } catch (err) {
      console.error(err);
    }
  };
  const createBanner = async (file: File, checklist_id: string) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('checklistId', checklist_id);
      const { data } = await api.post<Banner>('/banner/checklist', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data.id_banner;
    } catch (err) {
      console.error(err);
    }
  };
  const handleSubmitChecklist = async () => {
    let checklistIdResponse = '';
    let updatedChecklist = checklistData;
    if (checklistId.length === 0) {
      const checklistResponse = await createChecklist(checklistData);

      checklistIdResponse = checklistResponse?.id_checklist || '';
      setChecklistId(checklistIdResponse);

      updatedChecklist =
        checklistData.map((item, index) => {
          if (index === 0)
            return {
              ...item,
              file: null,
              id_banner: checklistResponse?.banners[0]?.id_banner || '',
            };
          return item;
        }) || [];
    }

    await Promise.all(
      bannersToRemove.map(async (item, index) => {
        if (item.length > 0) {
          try {
            await removeBanner(item);
          } catch (error) {
            setUploadErrors(prevErrors => [...prevErrors, index]);
            console.error(error);
          }
        }
      }),
    );

    updatedChecklist = await Promise.all(
      updatedChecklist.map(async (item, index) => {
        if (item.file) {
          try {
            const id_banner =
              (await createBanner(
                item.file,
                checklistId || checklistIdResponse,
              )) || '';
            return { ...item, file: null, id_banner };
          } catch (error) {
            setUploadErrors(prevErrors => [...prevErrors, index]);
            console.error(error);
            return item;
          }
        }
        return item;
      }),
    );

    setChecklistData(updatedChecklist);
    return checklistId || checklistIdResponse;
  };
  // LOGICA DOS DADOS
  const handleWhatsappMessage = (budgetId: string) => {
    const phone = `+55${numberOnly(budget.phone)}`;

    const linkText = `Olá, ${
      budget.name
    }! Segue o orçamento da *${encodeURIComponent(
      workshop?.fantasy_name ?? '',
    )}*%0A%0Ahttps://oficinas.autocenterapp.com/budget-report?budgetId=${budgetId}%0A%0A🛞Veículo: ${encodeURIComponent(
      budget.vehicle || 'não informado',
    )} %0A🚘Placa: ${encodeURIComponent(
      budget.plate,
    )}%0A%0A%0ABaixe nosso app Autocenter!%0A%0Ahttps://onelink.to/629pmh
    %0A%0A%0A Caso o link não esteja clicável, responda essa mensagem, ou, copie o link e insira em um navegador de sua preferência para acessar o conteúdo.
    `;
    sendWhatsappMessage(linkText, phone);
  };
  // const handleChangePage = () => {
  //   const data = getValues();
  //   dispatch(
  //     setBudget({
  //       ...budget,
  //       validity: data.validity ? data.validity : budget.validity,
  //       comment: data.comment ? data.comment : budget.comment,
  //       defect: data.defect ? data.defect : budget.defect,
  //       child_services: services,
  //     }),
  //   );
  //   dispatch(setBudgetChecklist(checklistData));
  //   setPage(0);
  // };
  const handleDiscountChange = (value: string) => {
    value = value.replace(',', '.');
    value = value.replace(/[^\d.]+/g, '').replace(/(\.)+/g, '.');

    setDiscount(value);
  };
  // USE EFFECTS
  useEffect(() => {
    setSubtotal(
      services.map((el: any) => el.total).reduce((acc, next) => acc + next, 0),
    );
  }, [services]);

  useEffect(() => {
    if (checklistFromState.length === 0) {
      if (budget.checklist && budget.checklist.banners.length > 0) {
        const checklistFromBudget = budget.checklist.banners.map(banner => {
          const { id_banner, img_url } = banner;
          return {
            id_banner,
            img_url,
            file: null,
          };
        });
        setIsCheckListOpen(true);
        setChecklistData(checklistFromBudget);
      }
    } else {
      setIsCheckListOpen(true);
      setChecklistData(checklistFromState);
    }
  }, [budget.checklist, checklistFromState]);

  useEffect(() => {
    if (budget) {
      setServices(budget.child_services);
      reset({
        comment: budget?.comment ?? 'nada ',
        defect: budget?.defect ?? 'nada',
        validity: budget?.validity ?? 'nada',
      });
    }
    if (checklistFromState.length > 0) {
      setChecklistData(checklistFromState);
    }
  }, [budget, checklistFromState, reset]);

  const handleSaveData = useCallback(() => {
    const { comment, defect, validity } = getValues();
    dispatch(
      setBudget({
        ...budget,
        comment,
        defect,
        validity,
        child_services: services,
      }),
    );
    dispatch(setBudgetChecklist(checklistData));
  }, [budget, checklistData, dispatch, getValues, services]);

  return (
    <MainComponent>
      <Header
        title="Orçamento"
        onClick={handleSaveData}
        setPage={setPage}
        page={page}
      />
      <div className={styles.content}>
        <ProgressBar currentPage={1} pages={['Cliente', 'Orçamento']} />

        <Stack
          alignItems="start"
          w={{ base: pixel(645, 800), xl: pixel(520, 1366) }}
        >
          <SendBudgetModal
            isOpen={isBudgetModalOpen}
            handleClose={() => {
              setIsBudgetModalOpen(false);
              setStatusModal('sending');
            }}
            onPressMessage={() => {
              if (idBudget) handleWhatsappMessage(idBudget);
              setIsBudgetModalOpen(false);
              navigate('/home');
            }}
            status={statusModal}
          />
          <Stack mt="1rem">
            <InputControl
              name="validity"
              label="Validade"
              placeholder="Ex: até 7 dias"
              inline
              control={control}
              defaultValue={budget.validity}
            />
            <br />

            <InputControl
              name="defect"
              label="Defeitos"
              description="Esse campo contém os defeitos relatados pelo cliente"
              type="textarea"
              placeholder="Ex: Freios com defeito;"
              control={control}
              defaultValue={budget.defect}
            />
            <br />

            <InputControl
              name="comment"
              label="Observações"
              description="Esse campo contém o parecer da oficina mecânica"
              type="textarea"
              control={control}
              defaultValue={budget.comment}
            />
            <br />
            <ChecklistForm
              checklistData={checklistData}
              isOpen={isCheckListOpen}
              setChecklistData={setChecklistData}
              toggleIsOpen={() => {
                setIsCheckListOpen(!isCheckListOpen);
              }}
              bannersToRemove={bannersToRemove}
              setBannersToRemove={setBannersToRemove}
              uploadErrors={uploadErrors}
            />
          </Stack>
          <br />
          <Divider mb="-2rem" />
          <Stack maxH="100%">
            <EditableTable
              setServices={setServices}
              services={budget.child_services}
            />
          </Stack>
          <Divider />

          <Divider />
          <br />

          <Stack w="100%">
            <Row justifyContent="space-between" my=".5rem">
              <Text fontWeight="500">Subtotal</Text>{' '}
              <Text fontWeight="500">
                {' '}
                {subtotal.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </Row>
            <Row justifyContent="space-between" my=".5rem">
              <Text fontWeight="500">Desconto</Text>{' '}
              <Input
                value={`R$ ${discount.replace('.', ',')}`}
                keyboardType="numeric"
                onChangeText={handleDiscountChange}
                textAlign="right"
                placeholder="0"
              />
            </Row>
            <Row justifyContent="space-between" my=".5rem">
              <Text fontWeight="600" color={COLORS.DARKBLUE}>
                Total
              </Text>{' '}
              <Text fontWeight="600" color={COLORS.GREEN} my=".5rem">
                {`${(subtotal - parseFloat(discount || '0')).toLocaleString(
                  'pt-BR',
                  {
                    style: 'currency',
                    currency: 'BRL',
                  },
                )}`}
              </Text>
            </Row>
          </Stack>
          <br />
          <Button
            bg={COLORS.GREEN}
            py=".5rem"
            px="1.5rem"
            borderRadius="10px"
            alignSelf="end"
            onPress={handleSubmit(onSubmit)}
            _text={{
              fontWeight: '600',
            }}
          >
            Enviar
          </Button>
        </Stack>
      </div>
    </MainComponent>
  );
};
