import { useCallback, useEffect, useState } from 'react';
import { Quotation } from '../../../../../../../@types/interface';
import { getMyQuotationsController } from '../../../../workshop/controller';
import { QUOTATION } from '../../../../../../../constants';
import { QuotationFilterOptions } from '../../../../workshop/interface';
import { useQuotation } from '../../../../../../../contexts/quotationContext';

export const useCreatedQuotationData = () => {
  const { setQuotationState } = useQuotation();
  const [page, setPage] = useState(1);

  const [searchedQuotations, setSearchedQuotations] = useState<Quotation[]>([]);
  const [searchedQuotation, setSearchedQuotation] = useState<Quotation | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  const fetchNotAnsweredQuotations = useCallback(
    async (pageRequest: number) => {
      setIsLoading(true);
      if (!noMoreData) {
        const res = await getMyQuotationsController({
          limit: QUOTATION.REQUEST_LIMIT,
          page: pageRequest,
          option: 'not_answered',
        });

        if (res && res.length > 0) {
          setQuotationState(previous => {
            const newQuotations = res.filter(
              quotation =>
                !previous.notAnsweredQuotationList.some(
                  existingQuotation =>
                    quotation.id_quotation === existingQuotation.id_quotation,
                ),
            );
            return {
              ...previous,
              notAnsweredQuotationList: [
                ...previous.notAnsweredQuotationList,
                ...newQuotations,
              ],
            };
          });
        } else {
          setNoMoreData(true);
        }
      }
      setIsLoading(false);
    },
    [noMoreData, setQuotationState],
  );

  const fetchQuotationsByFilter = useCallback(
    async (filterValue: string, filterType: QuotationFilterOptions) => {
      setIsLoading(true);

      if (filterValue.trim().length > 0) {
        const res = await getMyQuotationsController({
          option: 'not_answered',
          filter: filterType,
          filter_value: filterValue,
        });

        if (res && res.length > 0) {
          setSearchedQuotations(res);
        } else {
          setSearchedQuotations([]);
        }
      } else {
        setSearchedQuotations([]);
      }
      setIsLoading(false);
    },
    [],
  );

  const loadMoreData = useCallback(
    (previousPage = 1) => {
      if (!noMoreData) {
        fetchNotAnsweredQuotations(previousPage);
        setPage(previousPage + 1);
      }
    },
    [fetchNotAnsweredQuotations, noMoreData],
  );

  const handleFinishQuotation = (
    res: Quotation,
    onFinishQuotation?: () => void,
  ) => {
    setQuotationState(previous => ({
      ...previous,
      notAnsweredQuotationList: previous.notAnsweredQuotationList.filter(
        quotation => quotation.id_quotation !== res.id_quotation,
      ),
    }));
    if (onFinishQuotation) onFinishQuotation();
  };

  const resetStates = useCallback(async () => {
    // reset search controllers
    setNoMoreData(false);
    setPage(0);
  }, []);

  useEffect(() => {
    loadMoreData();
  }, [loadMoreData]);

  return {
    page,
    isLoading,
    handleFinishQuotation,
    fetchQuotationsByFilter,
    searchedQuotation,
    searchedQuotations,
    loadMoreData,
    setSearchedQuotation,
    resetStates,
  };
};
