import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getDashboard } from '../../workshop/Home/model';
import {
  updateTotalAssessments,
  // updateTotalCustomers,
  // updateTotalEarned,
  updateTotalServices,
} from '../../../features/dashboard/dashboard-slice';
import { resetOS } from '../../../features/orderService/orderService-slice';
import {
  getObjectFromLocal,
  removeObjectFromLocal,
} from '../../../utils/localStorage';
// import { visitorId } from '../../../routes/routes';
// import { useQuotation } from '../../../contexts/quotationContext';
// import { useAnswerQuotation } from '../../../contexts/answerQuotationContext';

export const useHomeData = () => {
  // const [isVisitor, setIsVisitor] = useState(false);
  const [newCustomers, setNewCustomers] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const [isAnswerQuotationModalOpen, setIsAnswerQuotationModalOpen] =
    useState(false);

  // const [totalServices, setTotalServices] = useState(0);
  const [newQuotationsHome, setNewQuotationsHome] = useState(0);
  const [amountNotifications, setAmountNotifications] = useState(0);
  const [hasUnseenQuotation, setHasUnseenQuotation] = useState(false);
  const [hasUnseenAnswerQuotation, setHasUnseenAnswerQuotation] =
    useState(false);

  // const { quotationState } = useQuotation();
  // const { answerQuotationState } = useAnswerQuotation();

  // const newQuotations = useMemo(
  //   () =>
  //     quotationState.answeredQuotationList.filter(
  //       quotation => !quotation.visualized,
  //     ),
  //   [quotationState.answeredQuotationList],
  // );
  // const newAnswerQuotations = useMemo(() => {
  //   return [
  //     ...answerQuotationState.receivedQuotationList.filter(
  //       answer => !answer.visualized,
  //     ),
  //     ...answerQuotationState.pendingQuotationList.filter(
  //       answer => !answer.visualized,
  //     ),
  //   ];
  // }, [
  //   answerQuotationState.pendingQuotationList,
  //   answerQuotationState.receivedQuotationList,
  // ]);

  const totalAssessments = useAppSelector(
    state => state.dashboard.totalAssessments,
  );
  // const notificationsFromState = useAppSelector(state => state.notifications);
  const totalServices = useAppSelector(state => state.dashboard.totalServices);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (user?.id_user === visitorId) {
  //     setIsVisitor(true);
  //   }
  // }, [user?.id_user]);

  const fetchDashboard = useCallback(async () => {
    // const today = new Date();
    // const day = today.getDate();
    // const month = today.getMonth() + 1;
    // const year = today.getFullYear();

    // const initialDate = `${year}-${month <= 9 ? `0${month}` : month}-01`;
    // const finalDate = `${year}-${month <= 9 ? `0${month}` : month}-${
    //   day <= 9 ? `0${day}` : day
    // }`;

    // const dashboardResponse = await getDashboard(
    //   user.workshop.id_workshop,
    //   initialDate,
    //   finalDate,
    // );

    const dashboardResponse = await getDashboard();

    // const customersResponse = await getCustomers(
    //   user.workshop.id_workshop,
    //   initialDate,
    //   finalDate,
    // );
    // const date = new Date();

    // const newCustomersResponse = await getNewCustomers(
    //   date.getFullYear().toString(),
    //   (date.getMonth() + 1).toString(),
    // );

    setNewQuotationsHome(dashboardResponse.amount_quotation_notifications || 0);
    setNewCustomers(dashboardResponse.newCustomersByClientCode || 0);
    dispatch(updateTotalAssessments(dashboardResponse.rating || 0));
    dispatch(updateTotalServices(dashboardResponse.amount_month_repairs || 0));

    setAmountNotifications(dashboardResponse.amount_notifications || 0);
    // setTotalServices(dashboardResponse.amount_month_repairs || 0);
    setHasUnseenQuotation(dashboardResponse.has_unseen_quotation || false);
    setHasUnseenAnswerQuotation(
      dashboardResponse.has_unseen_answer_quotation || false,
    );

    // setNewCustomers(newCustomersResponse?.length || 0);
    // dispatch(updateTotalAssessments(dashboardResponse?.rating || 0));
    // dispatch(updateTotalEarned(dashboardResponse?.totalEarned || 0));
    // dispatch(updateTotalServices(dashboardResponse?.totalServices || 0));
    // dispatch(updateTotalCustomers(customersResponse?.customers || 0));
    setLoadingData(false);
  }, [dispatch]);

  const navigate = useNavigate();

  const localAnswerId = getObjectFromLocal('answerId');

  useEffect(() => {
    if (localAnswerId && localAnswerId.length > 0) {
      setIsAnswerQuotationModalOpen(true);
    }
  }, [localAnswerId, setIsAnswerQuotationModalOpen]);

  const handleSingOut = () => {
    localStorage.clear();
    Cookies.remove('@AutoCenter: accessToken');
    navigate('/page-login');
    Cookies.remove('@AutoCenter: accessToken');
    navigate(0);
  };

  useEffect(() => {
    if (localStorage.getItem('reload')) {
      localStorage.removeItem('reload');
      window.location.reload();
    }

    fetchDashboard();

    dispatch(resetOS());
  }, [dispatch, fetchDashboard]);

  const handleAnswerQuotation = () => {
    navigate('/answer-quotation');
  };

  const handleCancelAnswerQuotation = () => {
    removeObjectFromLocal('answerId');
    setIsAnswerQuotationModalOpen(false);
  };

  return {
    // isVisitor,
    newCustomers,
    loadingData,
    newQuotationsHome,
    // newAnswerQuotations,
    amountNotifications,
    hasUnseenQuotation,
    hasUnseenAnswerQuotation,
    totalAssessments,
    // notificationsFromState,
    totalServices,
    handleSingOut,
    navigate,
    isAnswerQuotationModalOpen,
    handleAnswerQuotation,
    handleCancelAnswerQuotation,
  };
};
