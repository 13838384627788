import { useState, useCallback } from 'react';
import { getAnswerQuotationByIdController } from '../../autoshop/controller';

export const useFetchAnswer = () => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchAnswer = useCallback(async (id: string) => {
    setIsLoading(true);

    try {
      const res = await getAnswerQuotationByIdController(id);
      return res;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { fetchAnswer, isLoading };
};
