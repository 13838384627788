/* eslint-disable no-useless-escape */
import * as yup from 'yup';
import { ERROR_MESSAGES } from '../../../../../../utils/validations/errorMessages';
import {
  validateCNPJ,
  validateCPF,
} from '../../../../../../utils/validations/documentValidation';
import { validateLicensePlate } from '../../../../../../utils/validations/licensePLateValidation';

export const schema = yup.object({
  document: yup
    .string()
    .nullable()
    .test('document', ERROR_MESSAGES.invalidDocument, value => {
      if (!value) return true;
      if (value.trim().length === 0) return true;
      const formattedDocument = value.replace(/[^\d]+/g, '');
      if (formattedDocument.length === 11) {
        return validateCPF(formattedDocument);
      }
      if (formattedDocument.length === 14) {
        return validateCNPJ(formattedDocument);
      }
      return false;
    }),
  name: yup
    .string()
    .required(ERROR_MESSAGES.required)
    .min(1, ERROR_MESSAGES.minLength),
  phone: yup
    .string()
    .nullable()
    .test('phone', ERROR_MESSAGES.invalidPhone, value => {
      if (value && value.length < 10) return false;
      return true;
    }),
  license_plate: yup
    .string()
    .test(
      'license plate validation',
      ERROR_MESSAGES.invalidLicensePlate,
      value => {
        if (!value) return true;
        return validateLicensePlate(value || '');
      },
    ),
  vehicle: yup.string().nullable(),
});
