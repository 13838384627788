import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  Input,
  Row,
  Spinner,
  Text,
  TextArea,
} from 'native-base';
import { useForm, Controller } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { FileSearch } from 'phosphor-react';
import pixel from '../../../../../../styles/size';
import { FormValues, VehicleFormProps } from './interfaces';
import {
  initialOrderServiceState,
  resetOS,
} from '../../../../../../features/orderService/orderService-slice';
import { validateForm } from './schema';
import message from '../../../../../../utils/message';
import { numberWithCommas } from '../../../../../../services/helpers/mask';
import { AlertModal } from '../../../../../../shared/AlertModal';
import { VehicleModal } from '../../../../../../shared/VehicleModal';
import {
  formatVehicle,
  formatVehicleYear,
} from '../../../../../../utils/formatters/vehicleFormat';
import { fetchVehicleDataByLicensePlateController } from '../../../../../../services/dataFetchers/VehicleDataFetcher/VehicleDataFetcher';
import { VehicleResponse } from '../../../../../../services/dataFetchers/VehicleDataFetcher/model';
import { updateKmController, updateVehicleController } from './controller';
import { UpdateVehicleInput } from './model';
import { Repair, User } from '../../../../../common/History/interfaces';
import {
  getObjectFromLocal,
  removeObjectFromLocal,
  saveObjectLocally,
} from '../../../../../../utils/localStorage';
import { FeedbackModal } from '../../../../../../shared/FeedbackModal';
import { validateLicensePlate } from '../../../../../../utils/validations/licensePLateValidation';
import { COLORS } from '../../../../../../styles/colors';
// import { visitorId } from '../../../../../../routes/routes';
// import { OrderServiceData } from '../../../mockup';
import { ProgressBar } from '../../../../../../shared/ProgressBar';
import { Header } from '../../../../../../shared/Header';
import { MainComponent } from '../../../../../../shared/MainComponent/MainComponent';
import styles from './vehicle-form.module.css';
import { Modal } from '../../../../../../shared/Modal';
import { Button as ButtonComponent } from '../../../../../../shared/Button';

const formControlProps = {
  w: { base: pixel(830, 1080), xl: pixel(730, 1920) },
  mb: '2rem',
};

const requiredLabelProps = {
  style: { color: '#E00D0D', fontWeight: 600, marginLeft: '5px' },
  children: '*',
};

const buttonProps = {
  variant: 'outline',
  h: '10',
  w: '6rem',
  borderRadius: '9px',
};

export const VehicleForm = ({
  setPage,
  setPreviousOwnerId,
  // setVehicleId,
  isSeparateComponent,
  setIsSeparateComponent,
  setIsSeparateCustomer,
  setShowTransferVehicle,
}: VehicleFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [customerData, setCustomerData] = useState<User | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showVehicleModal, setShowVehicleModal] = useState(false);
  const [vehicleDataResponse, setVehicleDataResponse] =
    useState<VehicleResponse | null>(null);
  const [vehicleId, setVehicleId] = useState('');
  const [showAdviceModal, setShowAdviceModal] = useState(false);

  const formatVehicleDate = ({ vehicle }: Repair) => {
    let formattedDate = '';
    if (vehicle) {
      const { year, yearModel } = vehicle;
      if (year) {
        if (yearModel && !year.includes('/')) {
          formattedDate = `${year}/${yearModel}`;
        } else {
          formattedDate = `${year}`;
        }
      }
    }
    return formattedDate;
  };

  const dispatch = useDispatch();

  const { control, getValues, reset } = useForm<FormValues>({
    defaultValues: {
      licensePlate: '',
      vehicle: '',
      year: '',
      displacement: '',
      km: '',
      description: '',
    },
  });

  useEffect(() => {
    let localOrderService: Repair | null = null;
    // if (userLocal && userLocal.id_user !== visitorId) {
    localOrderService = getObjectFromLocal('@local-order-service');
    // } else {
    //   localOrderService = OrderServiceData as Repair;
    // }
    if (localOrderService?.vehicle?.license_plate) {
      handleLicensePlateChange(localOrderService.vehicle.license_plate);
    }
    if (localOrderService) {
      if (localOrderService.vehicle) {
        const { km, vehicle, description } = localOrderService;
        const { name, license_plate } = vehicle;
        reset({
          licensePlate: license_plate || '',
          vehicle: name || '',
          year: formatVehicleDate(localOrderService),
          displacement: '',
          km: `${km || ''}`,
          description: description || '',
        });
      } else {
        const { description } = localOrderService;
        reset({
          description: description || '',
        });
        setIsSeparateComponent(true);
      }
    }
  }, []);

  const handleLicensePlateChange = async (licensePlate: string) => {
    if (!licensePlate || licensePlate.length !== 7) {
      reset({
        licensePlate: licensePlate || '',
      });
      setVehicleDataResponse(null);
      return;
    }

    try {
      setIsLoading(true);
      const { description } = getValues();
      const vehicleResponse = await fetchVehicleDataByLicensePlateController(
        licensePlate,
      );

      if (vehicleResponse) {
        handleVehicleResponse(vehicleResponse, licensePlate, description);
      } else {
        console.error('API sem retorno, verifique a conexão');
      }
    } catch (error) {
      setCustomerData(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVehicleResponse = (
    vehicleResponse: VehicleResponse,
    licensePlate: string,
    description: string,
  ) => {
    const { id_vehicle, yearModel, name, displacement, current_km } =
      vehicleResponse;

    setVehicleDataResponse(vehicleResponse);
    setVehicleId(id_vehicle || '');
    setShowTransferVehicle(false);

    if (vehicleResponse.user) {
      const { id_user } = vehicleResponse.user;
      setShowTransferVehicle(true);
      setPreviousOwnerId(id_user);
      setCustomerData(vehicleResponse.user);
    }

    reset({
      licensePlate,
      vehicle: formatVehicle(vehicleResponse),
      year: formatVehicleYear(vehicleResponse),
      displacement: displacement || '',
      km: `${current_km && current_km > 0 ? current_km : ''}`,
      description,
    });

    if (!yearModel || !name) {
      message('Dados do veículo não encontrados, informe os dados', 'info');
    }
  };

  const handleSave = (customer?: User | null, vehicle_id?: string) => {
    const { description, displacement, km, licensePlate, vehicle, year } =
      getValues();
    const previusOrderService = getObjectFromLocal('@local-order-service');
    let localOrderService = {} as Repair;
    if (previusOrderService) {
      localOrderService = {
        ...previusOrderService,
        description,
        vehicle_id: vehicle_id || '',
        vehicle: {
          ...previusOrderService.vehicle,
          license_plate: licensePlate,
          year,
          displacement,
          name: vehicle,
        },
      };
    } else {
      localOrderService = {
        ...initialOrderServiceState,
        description,
        vehicle_id: vehicle_id || '',
        vehicle: {
          ...initialOrderServiceState.vehicle,
          license_plate: licensePlate,
          year,
          displacement,
          name: vehicle,
        },
      };
    }
    if (km) {
      localOrderService.km = parseInt(km, 10);
    }
    if (customer) {
      setIsSeparateCustomer(false);
      localOrderService = {
        ...localOrderService,
        phone: customer.phone || localOrderService?.phone || '',
        name: customer.name,
        user_workshop: {
          ...localOrderService.user_workshop,
          user: {
            ...localOrderService?.user_workshop?.user,
            id_user: customer.id_user,
            name: customer.name,
            document: customer.document,
            phone: customer?.phone || localOrderService?.phone || '',
            email: customer?.email || localOrderService?.email || '',
            address: customer.address,
            address_id: customer.address_id || '',
          },
        },
      };
    }
    saveObjectLocally('@local-order-service', localOrderService);
  };
  const handleUpdateKm = async (km: string, id_vehicle: string) => {
    const convertedKm = parseInt(km, 10);
    if (!isNaN(convertedKm)) return updateKmController(convertedKm, id_vehicle);
    // caso a km não seja informada
    return true;
  };

  const handleChangePage = async () => {
    // if (userLocal && userLocal.id_user !== visitorId) {
    let current_km = 100;
    if (vehicleDataResponse) {
      current_km =
        vehicleDataResponse.current_km >= 100
          ? vehicleDataResponse.current_km
          : 100;
    }
    if (!isSeparateComponent) {
      await handleSubmit(current_km);
    } else {
      handleSave();
      setPage(1);
    }
    // }
    //  else {
    //   setPage(1);
    // }
  };

  const handleSubmit = async (current_km: number) => {
    setIsSending(true);
    if (vehicleDataResponse) {
      const { id_vehicle } = vehicleDataResponse;
      const { year, km, vehicle, licensePlate } = getValues();

      const isValidForm = await validateForm({
        km,
        currentKm: current_km,
        licensePlate,
        vehicle,
      });

      if (isValidForm) {
        const updateKm = await handleUpdateKm(km, id_vehicle);
        const shouldUpdateVehicle =
          (!vehicleDataResponse.yearModel && !!year) ||
          !vehicleDataResponse.name;

        if (shouldUpdateVehicle) {
          const updateVehicleData: UpdateVehicleInput = {
            vehicleId: id_vehicle,
          };
          if (!vehicleDataResponse.name) {
            updateVehicleData.name = vehicle;
          }
          if (!vehicleDataResponse.yearModel) {
            updateVehicleData.yearModel = year;
          }
          const updateVehicle = await updateVehicleController(
            updateVehicleData,
          );
          if (updateVehicle && updateKm) {
            handleSave(customerData, vehicleId);
            setPage(1);
          }
        } else if (updateKm) {
          handleSave(customerData, vehicleId);
          setPage(1);
        }
      }
    } else {
      message(
        'Informe uma placa ou selecione a opção Outros para prosseguir!',
        'info',
      );
    }
    setIsSending(false);
  };

  const navigate = useNavigate();

  const handleNavigation = useCallback(() => {
    const localOrderService = !!localStorage.getItem('@local-order-service');
    if (localOrderService) {
      setShowAdviceModal(true);
    } else {
      navigate('/home');
    }
  }, [navigate]);

  const handleBackButton = useCallback(() => {
    dispatch(resetOS());
    removeObjectFromLocal('@local-order-service');
    navigate('/home');
  }, [dispatch, navigate]);

  return (
    <MainComponent>
      <Header
        title="Informações do Veículo"
        page={0}
        setPage={setPage}
        onClick={handleNavigation}
        disableNavigation
      />

      <Modal
        isOpen={showAdviceModal}
        onClose={() => setShowAdviceModal(false)}
        title="Deseja Sair?"
        footer={
          <div className={styles['modal-actions']}>
            <ButtonComponent handleClick={handleBackButton}>
              Confirmar
            </ButtonComponent>
            <ButtonComponent
              variant="ghost"
              handleClick={() => setShowAdviceModal(false)}
            >
              Cancelar
            </ButtonComponent>
          </div>
        }
      >
        <p>Salve em andamento para não perder os dados preenchidos!</p>
      </Modal>

      <div className={styles.content}>
        <ProgressBar
          currentPage={0}
          pages={['Veículos', 'Clientes', 'Serviços']}
        />
      </div>

      <Box>
        <VehicleModal
          isOpen={showVehicleModal}
          onClose={() => setShowVehicleModal(false)}
          licensePlate={getValues('licensePlate')}
        />
        <AlertModal
          content="Verifique se os campos de KM ou Ano estão preenchidos para ter uma melhor experiência"
          isOpen={showModal}
          setIsOpen={setShowModal}
          onConfirm={handleChangePage}
        />
        <FeedbackModal isOpen={isSending} variant="sending" />
        <FormControl {...formControlProps}>
          <Checkbox.Group>
            <Checkbox
              isChecked={isSeparateComponent}
              onChange={(value: boolean) => setIsSeparateComponent(value)}
              value=""
              _text={{
                fontSize: '.8rem',
              }}
              width="10"
            >
              Outros
            </Checkbox>
          </Checkbox.Group>
        </FormControl>
        {!isSeparateComponent && (
          <>
            <FormControl {...formControlProps}>
              <FormControl.Label>
                Placa
                <sup {...requiredLabelProps} />
              </FormControl.Label>
              <Controller
                control={control}
                name="licensePlate"
                rules={{ required: true }}
                render={({ field }) => (
                  <Box>
                    <Row>
                      <Input
                        h="3rem"
                        w="100%"
                        fontSize="1rem"
                        placeholder="XXXXXXX"
                        maxLength={7}
                        borderColor={
                          field?.value?.length !== 0 &&
                          !validateLicensePlate(field.value)
                            ? '#f00'
                            : '#d4d4d4'
                        }
                        {...field}
                        value={field?.value?.toUpperCase()}
                        onChange={(e: any) => {
                          const { value } = e.target;
                          field.onChange({
                            target: {
                              value: e.target.value
                                .replace(/[^A-Za-z0-9]+/, '')
                                .toUpperCase(),
                            },
                          });
                          if (!isSeparateComponent)
                            handleLicensePlateChange(
                              value.replace('-', '').trim(),
                            );
                        }}
                        rightElement={
                          <IconButton
                            onPress={() => setShowVehicleModal(true)}
                            variant="unstyled"
                            display={
                              field &&
                              field?.value &&
                              field?.value?.length === 7
                                ? 'flex'
                                : 'none'
                            }
                            icon={<FileSearch size={26} color={COLORS.GREEN} />}
                          />
                        }
                      />
                      {isLoading && <Spinner ml="1rem" />}
                    </Row>
                    {field?.value?.length === 7 &&
                      !validateLicensePlate(field?.value || '') && (
                        <Text color="#f00" mt=".5rem">
                          Essa placa é inválida{' '}
                        </Text>
                      )}
                  </Box>
                )}
              />
            </FormControl>

            <FormControl {...formControlProps}>
              <FormControl.Label>Veículo</FormControl.Label>
              <Controller
                control={control}
                name="vehicle"
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    h="3rem"
                    placeholder="MODELO | CILINDRADAS | COMBUSTÍVEL"
                    isDisabled={
                      !!vehicleDataResponse && !!vehicleDataResponse.name
                    }
                    _disabled={{
                      opacity: 1,
                      color: '#000',
                      fontWeight: '500',
                    }}
                    fontSize="1rem"
                    {...field}
                  />
                )}
              />
            </FormControl>
            <Row {...formControlProps}>
              <FormControl w="49%">
                <FormControl.Label>Ano</FormControl.Label>
                <Controller
                  control={control}
                  name="year"
                  render={({ field }) => (
                    <Input
                      h="3rem"
                      placeholder="Ex: 2010"
                      {...field}
                      isDisabled={
                        !!vehicleDataResponse && !!vehicleDataResponse.yearModel
                      }
                      fontSize="1rem"
                      _disabled={{
                        opacity: 1,
                        color: '#000',
                        fontWeight: '500',
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl w="49%">
                <FormControl.Label>km</FormControl.Label>

                <Controller
                  control={control}
                  name="km"
                  render={({ field: { onChange, value } }) => (
                    <Input
                      h="3rem"
                      fontSize="1rem"
                      placeholder="0"
                      value={numberWithCommas(value)}
                      onChangeText={text => {
                        onChange(text.replace(/[^\d]/g, ''));
                      }}
                    />
                  )}
                />
              </FormControl>
            </Row>
          </>
        )}
        {isSeparateComponent && (
          <FormControl {...formControlProps}>
            <FormControl.Label>Descrição</FormControl.Label>
            <Controller
              control={control}
              name="description"
              rules={{ required: true }}
              render={({ field }) => (
                <TextArea
                  autoCompleteType={undefined}
                  fontSize="1rem"
                  {...field}
                />
              )}
            />
          </FormControl>
        )}
        <Row justifyContent="space-between">
          <Button
            _hover={{ bg: 'green.1', color: 'white' }}
            onPress={() => {
              dispatch(resetOS());
              navigate(-1);
            }}
            {...buttonProps}
          >
            <AiOutlineLeft size="2rem" />
          </Button>
          <Button
            bg="green.1"
            color="white"
            _hover={{ bg: 'white', color: 'green.1' }}
            onPress={() => {
              const { year, km } = getValues();
              if ((!year || !km) && !isSeparateComponent) {
                setShowModal(true);
              } else {
                handleChangePage();
              }
            }}
            {...buttonProps}
          >
            <AiOutlineRight size="2rem" />
          </Button>
        </Row>
      </Box>
    </MainComponent>
  );
};
