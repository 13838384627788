import React, { ComponentProps } from 'react';
import styles from './head-item.module.css';

interface Props extends ComponentProps<'div'> {
  children?: React.ReactNode;
}

export const HeadItem = ({ children, ...props }: Props) => {
  return <div className={`${styles.item} ${props.className}`}>{children}</div>;
};
