import { CursorClick, WarningCircle } from '@phosphor-icons/react';
import { useCallback, useMemo, useState } from 'react';
import styles from './answers-list.module.css';
import {
  AnswerQuotation,
  Quotation,
} from '../../../../../../../../@types/interface';
import { useResponsive } from '../../../../../../../../hooks/useResponsive';
import { MobileAnswerItem } from '../MobileAnswerItem';
import { AnswerItem } from '../AnswerItem';
import { AnswerListLoading } from '../AnswerListLoading';
import handshake from '../../../../../../../../assets/img/handshake.svg';
import { QuotationResumeModal } from '../ApprovedQuotationResumeModal';
import { ViewQuotationModal } from '../ViewQuotationModal';

export interface FormValues {
  answers_quotation: AnswerQuotation[];
}

interface Props {
  quotation: Quotation | null;
  isTrembleWarning: boolean;
  setInvalid: (invalid: boolean) => void;
  handleWhatsappMessage: (
    phone: string,
    quotationNumber: Quotation | null,
  ) => void;
  handleApproveAnswer: (id: string) => void;
  handleRejectAnswer: (id: string) => void;
  isLoading: boolean;
}

export const AnswersList = ({
  quotation,
  isTrembleWarning,
  setInvalid,
  handleWhatsappMessage,
  handleApproveAnswer,
  handleRejectAnswer,
  isLoading,
}: Props) => {
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);
  const [resumeModalType, setResumeModalType] = useState<
    'approved' | 'pending' | 'rejected' | ''
  >('');
  const [selectedAnswerToView, setSelectedAnswerToView] =
    useState<AnswerQuotation | null>(null);

  const answers = useMemo(() => {
    if (quotation && quotation.answers_quotation) {
      return (
        quotation &&
        quotation.answers_quotation &&
        quotation.answers_quotation?.filter(
          answer => answer?.items_answer_quotation?.length !== 0,
        )
      );
    }
    return null;
  }, [quotation]);

  const notApprovedAnswers = useMemo(() => {
    return (
      answers?.filter(
        answer =>
          answer?.answered &&
          !answer?.approved &&
          !answer?.rejected &&
          !answer?.rejected_by_reciever,
      ) || []
    );
  }, [answers]);

  const approvedAnswers = useMemo(
    () => answers?.filter(answer => answer.answered && answer.approved) ?? [],
    [answers],
  );

  const availableAnswers = useMemo(
    () => answers?.filter(answer => !answer.confirmed) ?? [],
    [answers],
  );
  const notAvailableAnswers = useMemo(
    () =>
      quotation?.answers_quotation?.filter(answer => !answer.answered) ?? [],
    [quotation?.answers_quotation],
  );

  const rejectedProposals = useMemo(
    () =>
      quotation?.answers_quotation?.filter(
        answer => answer.rejected_by_reciever || answer.rejected,
      ) ?? [],
    [quotation?.answers_quotation],
  );
  const { sizeMobile } = useResponsive();

  const handleNumberOfAnswersText = useCallback((numberOfAnswers: number) => {
    if (numberOfAnswers === 0) return `Nenhuma nova proposta`;
    if (numberOfAnswers === 1) return `1 nova proposta`;
    return `${numberOfAnswers} novas propostas`;
  }, []);

  const handleResumeQuotationModalTitle = useCallback(() => {
    if (resumeModalType === 'approved') return 'Propostas Aprovadas';
    if (resumeModalType === 'pending') return 'Aguardando Propostas';
    if (resumeModalType === 'rejected') return 'Propostas Rejeitadas';
    return '';
  }, [resumeModalType]);

  const handleResumeQuotationModalItems = useCallback(() => {
    if (resumeModalType === 'approved') return approvedAnswers;
    if (resumeModalType === 'pending') return notAvailableAnswers;
    if (resumeModalType === 'rejected') return rejectedProposals;
    return [];
  }, [
    approvedAnswers,
    notAvailableAnswers,
    rejectedProposals,
    resumeModalType,
  ]);

  const handleSwitchComponent = useCallback(
    (data: AnswerQuotation, quotes: Quotation, index: number) => {
      if (sizeMobile) {
        return (
          <MobileAnswerItem
            key={data.id_answer_quotation}
            quotationItems={quotes.items_quotation}
            answer={data}
            isTrembleWarning={isTrembleWarning}
            handleWhatsappMessage={phone =>
              handleWhatsappMessage(phone, quotation)
            }
            isAnswered={data.confirmed}
            handleApproveAnswer={handleApproveAnswer}
            handleRejectAnswer={handleRejectAnswer}
          />
        );
      }
      return (
        <AnswerItem
          key={data.id_answer_quotation}
          quotationItems={quotes.items_quotation}
          answer={data}
          index={index}
          setInvalid={invalid => {
            setInvalid(invalid);
          }}
          isTrembleWarning={isTrembleWarning}
          handleWhatsappMessage={phone =>
            handleWhatsappMessage(phone, quotation)
          }
          isAnswered={data.confirmed}
          handleApproveAnswer={handleApproveAnswer}
          handleRejectAnswer={handleRejectAnswer}
        />
      );
    },
    [
      handleApproveAnswer,
      handleRejectAnswer,
      handleWhatsappMessage,
      isTrembleWarning,
      quotation,
      setInvalid,
      sizeMobile,
    ],
  );

  return (
    <div className={styles.container}>
      {isLoading ? (
        <AnswerListLoading />
      ) : (
        <>
          <QuotationResumeModal
            isOpen={isResumeModalOpen}
            onClose={() => setIsResumeModalOpen(false)}
            container={document.body}
            answers={handleResumeQuotationModalItems()}
            title={handleResumeQuotationModalTitle()}
            onSelect={(answer: AnswerQuotation) =>
              setSelectedAnswerToView(answer)
            }
          />
          <ViewQuotationModal
            answer={selectedAnswerToView}
            isOpen={!!selectedAnswerToView}
            onClose={() => setSelectedAnswerToView(null)}
          />
          {/* <div className={styles['quotation-resume']}>
            <h3>
              <CursorClick size={24} color="var(--secondary)" /> Clique para
              mais detalhes
            </h3>
            <ul className={styles['resume-list']}>
              <Button
                variant="unstyled"
                handleClick={() => {
                  setIsResumeModalOpen(true);
                  setResumeModalType('approved');
                }}
              >
                <li>
                  <span className={styles.counter}>
                    {approvedAnswers.length}
                  </span>
                  <span>Aprovadas</span>
                </li>
              </Button>
              <Button
                variant="unstyled"
                handleClick={() => {
                  setIsResumeModalOpen(true);
                  setResumeModalType('pending');
                }}
              >
                <li>
                  <span className={styles.counter}>
                    {notAvailableAnswers.length}
                  </span>
                  <span>Aguardando</span>
                </li>
              </Button>
              <Button
                variant="unstyled"
                handleClick={() => {
                  setIsResumeModalOpen(true);
                  setResumeModalType('rejected');
                }}
              >
                <li>
                  <span className={styles.counter}>
                    {rejectedProposals.length}
                  </span>
                  <span>Rejeitadas</span>
                </li>
              </Button>
            </ul>
          </div> */}
          <div className={styles.heading}>
            <h1 className={styles.title}>Propostas Recebidas:</h1>
            {!sizeMobile && (
              <div className={styles['answers-info']}>
                <WarningCircle size={24} weight="fill" />
                {handleNumberOfAnswersText(availableAnswers.length)}
              </div>
            )}
          </div>
          <div className={styles.wrapper}>
            <div className={styles['answers-list']}>
              {notApprovedAnswers &&
                quotation &&
                notApprovedAnswers.map((answer, index) => {
                  return handleSwitchComponent(answer, quotation, index);
                })}

              {notApprovedAnswers && notApprovedAnswers.length === 0 && (
                <div className={styles['empty-proposals']}>
                  <img src={handshake} alt="nenhuma proposta nova" />
                  <b>Nenhuma proposta nova!</b>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
