import { CaretDown, CaretUp, Eye, PaperPlaneRight, User } from 'phosphor-react';
import { useCallback, useMemo, useState } from 'react';
import { Button } from '../../../../../../../../../shared/Button';
import { Table } from '../../../../../../../Finance/components/Table';
import styles from './desktop-list.module.css';
import { AnswerQuotation } from '../../../../../../../../../@types/interface';
import {
  dateOffset,
  formatDate,
  formatTime,
} from '../../../../../../../../../utils/dateFunctions';
import useInfiniteScroll from '../../../../../../../../../hooks/useInfinityScroll';
import { ListLoader } from '../../../../../../../../../shared/ListLoader';
import useViewModal from '../../../../../hooks/useViewModal';
import useAnswerModal from '../../../../../hooks/useAnswerModal';
import useSelectedAnswer from '../../../../../hooks/useSelectedAnswer';
import { useResponsive } from '../../../../../../../../../hooks/useResponsive';
import { Circle } from '../../../../../../../../../shared/Circle';
import useWatchingState from '../../../../../../autoshop_agent/pages/NotAnsweredQuotations/hooks/useWatchingState';
import { Modal } from '../../../../../../../../../shared/Modal';
import { useAnswerQuotation } from '../../../../../../../../../contexts/answerQuotationContext';

interface Props {
  answerList: AnswerQuotation[];
  handleRowClick: (answer: AnswerQuotation) => void;
  onScroll: () => void;
  isLoading: boolean;
  isEmpty?: boolean;
  notFound?: boolean;
}

export const DesktopList = ({
  answerList,
  handleRowClick,
  onScroll,
  isLoading,
  isEmpty = false,
  notFound = false,
}: Props) => {
  const viewModal = useViewModal();
  const answerModal = useAnswerModal();
  const selectedAnswer = useSelectedAnswer();

  const listRef = useInfiniteScroll(onScroll);

  const message = useMemo(() => {
    if (isEmpty) return 'Sem Novas Cotações';
    if (notFound) return 'Nenhuma Cotação Encontrada';
    return null;
  }, [isEmpty, notFound]);

  return (
    <Table.Root>
      <Table.Row className={styles['table-header']}>
        <Table.Header value="N° Cotação" align="left" />
        {/* <Table.Header value="Situação" align="left" /> */}
        <Table.Header value="Origem" align="left" />
        <Table.Header value="Placa" align="left" />
        <Table.Header value="Data de Criação" align="left" />
      </Table.Row>

      <div ref={listRef} className={styles['table-content']}>
        {message && <p>{message}</p>}
        {!message &&
          answerList.map(answerItem => (
            <ListItem
              key={answerItem.id_answer_quotation}
              answer={answerItem}
              isSelected={
                !!selectedAnswer.answer &&
                selectedAnswer.answer.id_answer_quotation ===
                  answerItem.id_answer_quotation
              }
              onAnswer={() => answerModal.onOpen()}
              onRowClick={() => handleRowClick(answerItem)}
              onView={() => viewModal.onOpen()}
            />
          ))}
        {!message && isLoading && <ListLoader />}
      </div>
    </Table.Root>
  );
};

interface ListItemProps {
  answer: AnswerQuotation;
  onView: () => void;
  onAnswer: () => void;
  onRowClick: (answer: AnswerQuotation) => void;
  isSelected: boolean;
}

const ListItem = ({
  answer,
  isSelected,
  onAnswer,
  onRowClick,
  onView,
}: ListItemProps) => {
  const [agentModalOpen, setAgentModalOpen] = useState(false);

  const quotationNumber = useMemo(() => {
    if (!answer?.quotation?.QUOTATION) return '---';
    return answer.quotation.QUOTATION;
  }, [answer?.quotation?.QUOTATION]);

  const createdAt = useMemo(() => {
    if (!answer?.quotation?.created_at) return '--/--/--';
    const date = dateOffset(new Date(answer?.quotation?.created_at));

    return `${formatDate(date)} às ${formatTime(date)}`;
  }, [answer?.quotation?.created_at]);

  const workshop = useMemo(() => {
    if (!answer?.quotation?.workshop?.fantasy_name) return '---';
    return answer.quotation.workshop.fantasy_name;
  }, [answer?.quotation?.workshop?.fantasy_name]);

  const licensePlate = useMemo(() => {
    if (!answer?.quotation?.vehicle) return '---';
    return answer.quotation.vehicle.license_plate;
  }, [answer?.quotation?.vehicle]);

  const shouldAnswer = useMemo(() => {
    return !answer?.answered;
  }, [answer?.answered]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onRowClick(answer);
      e.stopPropagation();
    },
    [answer, onRowClick],
  );

  const visualizedStatus = useMemo(() => {
    if (answer.visualized) return 'default';
    return 'notification';
  }, [answer.visualized]);

  const RowIcon = useMemo(
    () => (isSelected ? CaretUp : CaretDown),
    [isSelected],
  );

  const isAgentWatching = useMemo(() => {
    return !!answer?.agent_watch;
  }, [answer?.agent_watch]);

  const { changeWatchingState } = useWatchingState();
  const { sizeMobile } = useResponsive();
  const { updateAnswerQuotations } = useAnswerQuotation();

  const removeAgentFromAnswerQuotation = useCallback(async () => {
    const res = await changeWatchingState(
      answer?.id_answer_quotation ?? '',
      'off',
    );
    if (res) {
      updateAnswerQuotations(res);
    }
    setAgentModalOpen(false);
  }, [
    answer?.id_answer_quotation,
    changeWatchingState,
    updateAnswerQuotations,
  ]);

  return (
    <div>
      <Modal
        title="Vendedor Respondendo"
        isOpen={agentModalOpen}
        onClose={() => {
          setAgentModalOpen(false);
        }}
        footer={
          <div className={styles['modal-actions']}>
            <Button handleClick={removeAgentFromAnswerQuotation}>
              Desvincular
            </Button>
            <Button
              handleClick={() => {
                setAgentModalOpen(false);
              }}
              variant="ghost"
            >
              Cancelar
            </Button>
          </div>
        }
      >
        <p>
          O vendedor <b>{answer.agent?.user?.name ?? 'não identificado'}</b>{' '}
          está respondendo essa cotação
        </p>
      </Modal>
      <Table.Row
        className={`${styles['table-row']} ${
          isSelected ? styles.selected : ''
        }`}
        onClick={handleClick}
      >
        <Table.Cell alignStart>
          <div className={styles['first-line']}>
            <RowIcon size={20} />
            {quotationNumber}
            <Circle variant={visualizedStatus} />
          </div>
        </Table.Cell>
        {/* <Table.Cell alignStart>{quotationNumber}</Table.Cell> */}
        <Table.Cell alignStart>{workshop}</Table.Cell>
        <Table.Cell alignStart>{licensePlate}</Table.Cell>
        <Table.Cell alignStart={!sizeMobile}>{createdAt}</Table.Cell>
      </Table.Row>
      {isSelected && (
        <Table.Row className={styles['row-actions']}>
          {shouldAnswer ? (
            <>
              <Button handleClick={onAnswer}>
                <PaperPlaneRight size={20} weight="fill" />
                Responder
              </Button>

              {isAgentWatching && (
                <Button
                  handleClick={() => {
                    setAgentModalOpen(true);
                  }}
                >
                  <User size={20} weight="bold" />
                  Vendedor
                </Button>
              )}
            </>
          ) : (
            <Button handleClick={onView}>
              <Eye size={20} weight="bold" />
              Visualizar
            </Button>
          )}
        </Table.Row>
      )}
    </div>
  );
};
