import { useState } from 'react';
import { Modal } from '../../../../../../../../shared/Modal';
import { Button } from '../../../../../../../../shared/Button';
import { Textarea } from '../../../../../../../../shared/Textarea';
import { AnswerQuotation } from '../../../../../../../../@types/interface';
import styles from './reject-modal.module.css';

interface Props {
  isOpen: boolean;
  container: Element | DocumentFragment;
  onClose: () => void;
  onConfirm: (comment: string) => void;
  answer: AnswerQuotation | null;
}

export const RejectModal = ({
  isOpen,
  container,
  onClose,
  onConfirm,
  answer,
}: Props) => {
  const [comment, setComment] = useState('');

  return (
    <Modal
      isOpen={isOpen}
      title="Rejeitar Cotação?"
      onClose={onClose}
      container={container}
      footer={
        <div className={styles.actions}>
          <Button
            handleClick={onClose}
            variant="cancel"
            style={{
              fontSize: '1.5rem',
              width: '35%',
              padding: '0.6rem 0.85rem 0.6rem 0.75rem',
            }}
          >
            Revisar
          </Button>
          <Button
            handleClick={() => {
              onConfirm(comment);
            }}
            variant="register"
            style={{
              fontSize: '1.5rem',
              width: '35%',
            }}
          >
            Rejeitar
          </Button>
        </div>
      }
    >
      <div className={styles.container}>
        <span>
          Deseja Rejeitar a cotação <b>{answer?.quotation?.QUOTATION ?? ''}</b>?
        </span>
        <div className={styles.observation}>
          <span>Motivo(opcional): </span>
          <Textarea
            name="observation"
            value={comment}
            handleChange={e => {
              setComment(e.target.value);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
