import { ReactNode } from 'react';
import styles from './infoBlock.module.css';

interface InfoBlockProps {
  image: string;
  title: string;
  text?: string;
  reverse?: boolean;
  children?: ReactNode;
  highlight?: boolean;
}

export const InfoBlock: React.FC<InfoBlockProps> = ({
  image,
  title,
  text,
  reverse,
  children,
  highlight,
}) => {
  const directionStyle = reverse ? styles.cardNormal : styles.cardReverse;
  const highlightBackground = highlight ? styles.hightlightBackground : '';

  return (
    <article
      className={`${directionStyle} ${styles.card} ${highlightBackground}`}
    >
      <div className={styles.titleSection}>
        <h2>{title}</h2>
        <p>{text}</p>
        {children}
      </div>
      <img src={image} alt="serviços" className={styles.image} />
    </article>
  );
};
