import { SignOut } from '@phosphor-icons/react';
import { Bell } from 'phosphor-react';
import { useEffect } from 'react';
import styles from './home.module.css';
import { HeaderCard } from './components/HeaderCard';
import homeLogo from '../../../assets/icons/home-logo.svg';
import pattern1 from '../../../assets/img/pattern-1.svg';
import pattern2 from '../../../assets/img/pattern-2.svg';
import pattern3 from '../../../assets/img/pattern-3.svg';
import { COLORS } from '../../../styles/colors';
import { ActionCard } from '../../gasStation/Home/components/ActionCard';
import { useHomeData } from './useHomeData';
import { useHomeCards } from './useHomeCards';
import { removeObjectFromLocal } from '../../../utils/localStorage';
import { AnswerIdDetectionModal } from './components/AnswerIdDetectionModal';

export const Home = () => {
  const {
    handleSingOut,
    loadingData,
    navigate,
    isAnswerQuotationModalOpen,
    handleAnswerQuotation,
    handleCancelAnswerQuotation,
    hasUnseenAnswerQuotation,
    hasUnseenQuotation,
    amountNotifications,
    newQuotationsHome,
  } = useHomeData();

  const { content } = useHomeCards({
    newQuotationAnswers: hasUnseenAnswerQuotation,
    newQuotations: hasUnseenQuotation,
  });

  useEffect(() => {
    removeObjectFromLocal('@local-order-service');
  }, []);

  // const { quotationNotifications, generalNotifications } = useNotification();

  return (
    <>
      <AnswerIdDetectionModal
        isOpen={isAnswerQuotationModalOpen}
        onClose={handleCancelAnswerQuotation}
        onConfirm={handleAnswerQuotation}
      />
      <main className={styles.main}>
        <header className={styles.header}>
          <div className={styles['logo-wrapper']}>
            <img src={homeLogo} className={styles.logo} alt="autocenter logo" />
          </div>
          <div className={styles.statistics}>
            <div className={styles['statistics-wrapper']}>
              <HeaderCard
                icon={
                  <Bell
                    size={window.innerWidth > 480 ? 28 : 24}
                    weight="bold"
                  />
                }
                label="Atualizações de Cotação"
                value={newQuotationsHome}
                onClick={() => navigate('/quotation-notification')}
                loading={loadingData}
              />
              <HeaderCard
                icon={
                  <Bell
                    size={window.innerWidth > 480 ? 28 : 24}
                    weight="bold"
                  />
                }
                label="Notificações"
                value={amountNotifications}
                emphasis={!!(amountNotifications > 0)}
                onClick={() => navigate('/notifications')}
                loading={loadingData}
              />
            </div>
            <img src={pattern1} className={styles.pattern} alt="" />
          </div>
        </header>
        <section className={styles.actions}>
          {content &&
            content.map(item => (
              <ActionCard
                description={item.description}
                icon={item.icone}
                title={item.title}
                path={item.uri}
                notifications={item.notifications}
                key={item.title}
              />
            ))}
          <img src={pattern2} className={styles.pattern} alt="" />
          <img
            src={pattern3}
            className={`${styles.pattern} ${styles.mobile}`}
            alt=""
          />
        </section>
        <button
          type="button"
          className={styles.singout}
          onClick={handleSingOut}
        >
          <SignOut size={32} color={COLORS.RED} weight="bold" />
        </button>
      </main>
    </>
  );
};
