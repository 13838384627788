import { TableRow } from './TableRow';
import { TableRoot } from './TableRoot';
import { TableCell } from './TableCell';
import { TableHeader } from './TableHeader';

export const Table = {
  Root: TableRoot,
  Row: TableRow,
  Cell: TableCell,
  Header: TableHeader,
};
