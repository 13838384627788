import { Dispatch, SetStateAction } from 'react';
import { Image, MainComponent, Modal, Text, Icon } from './_modalSuccess';

import Success from '../../assets/img/success.svg';
import IconCloseBlack from '../../assets/icons/iconCloseBlack.svg';

interface Props {
  children: React.ReactNode;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
}

export const ModalSuccess = ({ children, setShowModal, showModal }: Props) => {
  return (
    <div>
      {showModal ? (
        <MainComponent>
          <Modal>
            <Text>{children}</Text>
            <Image src={Success} alt="" />
            <Icon
              src={IconCloseBlack}
              alt=""
              onClick={() => setShowModal(false)}
            />
          </Modal>
        </MainComponent>
      ) : null}
    </div>
  );
};
