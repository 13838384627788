import { ReactNode, useCallback, useEffect } from 'react';
import { HeaderLandingPage } from '../HeaderLandingPage/Header';
import { FooterLandingPage } from '../FooterLandingPage/Footer';
import Register from '../../Pages/Register';
import styles from './container.module.css';

interface Container {
  children: ReactNode;
  style?: React.CSSProperties;
  setTypeService?: (value: string) => void;
}

export const Container: React.FC<Container> = ({
  children,
  style,
  setTypeService,
}) => {
  const HeaderLP = useCallback(() => {
    return <HeaderLandingPage setTypeService={setTypeService} />;
  }, [setTypeService]);

  const defaultStyle: React.CSSProperties = {
    position: 'relative',
  };
  const JivoChat = () => {
    const scriptElement = document.createElement('script');
    scriptElement.src = '//code.jivosite.com/widget/xZEakwkBKu';
    scriptElement.async = true;
    document.body.appendChild(scriptElement);
  };

  useEffect(() => {
    JivoChat();
  }, []);

  return (
    <>
      <div style={{ ...style, ...defaultStyle }} className={styles.container}>
        <HeaderLP />
        <Register />
        {children}
      </div>
      <FooterLandingPage />
    </>
  );
};
