/* eslint-disable jsx-a11y/label-has-associated-control */
import { useMemo } from 'react';
import { ProductTable } from '../../../../../../../../shared/ProductTable';
import styles from './answer-row.module.css';
import {
  AnswerQuotation,
  ItemQuotation,
} from '../../../../../../../../@types/interface';
import { AnswerRowItem } from '../AnswerRowItem';

interface Props {
  quotationItem: ItemQuotation;
  answer: AnswerQuotation;
  index: number;
  setInvalid: (invalid: boolean) => void;
  isTrembleWarning: boolean;
  isAnswered: boolean;
}

export const AnswerRow = ({
  answer,
  quotationItem,
  index,
  setInvalid,
  isTrembleWarning,
  isAnswered,
}: Props) => {
  const answerItems = useMemo(
    () => answer?.items_answer_quotation ?? [],
    [answer?.items_answer_quotation],
  );

  const correspondingAnswers = useMemo(
    () =>
      answerItems?.filter(
        item => item.item_quotation_id === quotationItem.id_item_quotation,
      ) ?? [],
    [answerItems, quotationItem.id_item_quotation],
  );

  const description = useMemo(
    () => quotationItem?.description ?? '----',
    [quotationItem?.description],
  );

  const reference = useMemo(
    () => quotationItem?.reference ?? '----',
    [quotationItem?.reference],
  );

  const quantity = useMemo(
    () => quotationItem?.quantity ?? '----',
    [quotationItem?.quantity],
  );

  return (
    <div className={styles.container}>
      <ProductTable.Row
        style={{
          gridTemplateColumns: '.5fr 2fr 1.5fr 1fr',
          background: '#E6E6E666',
          padding: '2rem 2rem',
        }}
      >
        <span
          style={{
            textAlign: 'start',
            paddingLeft: '0',
            fontWeight: '700',
          }}
          className={styles['row-field']}
        >
          {index + 1}
        </span>
        <span className={styles['row-field']}>{description}</span>
        <span className={styles['row-field']}>{reference}</span>
        {correspondingAnswers.length === 0 && (
          <div className={styles.notOffer}>Sem oferta</div>
        )}
        {correspondingAnswers.length > 0 && (
          <span className={styles['row-field']}>{quantity}</span>
        )}
      </ProductTable.Row>
      {correspondingAnswers.length > 0 && (
        <ul>
          <div className={styles['list-header']}>
            <span>Marca</span>
            <span>Referência</span>
            <span>Valor Unit.</span>
            <span>Resposta</span>
          </div>
          {correspondingAnswers.map(item => (
            <AnswerRowItem
              key={item.id_item_answer_quotation}
              defaultValue={
                parseFloat(quotationItem?.quantity) <= item?.quantity_available
                  ? `${quotationItem?.quantity}`
                  : `${item?.quantity_available}`
              }
              isAnswered={isAnswered}
              isTrembleWarning={isTrembleWarning}
              item={item}
              setInvalid={setInvalid}
            />
          ))}
        </ul>
      )}
    </div>
  );
};
