import * as yup from 'yup';
import { updateUserPassword } from './model';
import { UpdateUserPasswordInput } from './interfaces';
import { handleError } from '../../../utils/handleError';
import message from '../../../utils/message';

export const updateUserPasswordController = async (
  props: UpdateUserPasswordInput,
) => {
  try {
    const schema = yup.object({
      phone: yup.string().optional(),
      newPassword: yup.string().optional(),
      confirmPassword: yup.string().when('newPassword', {
        is: (newPassword: string) => !!newPassword,
        then: yup
          .string()
          .required('A confirmação de senha é obrigatória')
          .oneOf([yup.ref('newPassword'), null], 'As senhas não coincidem'),
        otherwise: yup.string().notRequired(),
      }),
    });
    await schema.validate(props);
    const res = await updateUserPassword({
      newPassword: props.newPassword,
      phone: props.phone,
    });
    message('Dados atualizados com sucesso', 'success');
    return res;
  } catch (error) {
    handleError(error);
  }
};
