/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Bell,
  ChatCircleDots,
  Envelope,
  MapPin,
  Warning,
  XCircle,
} from 'phosphor-react';
import styles from './root.module.css';
import {
  dateOffset,
  formatDate,
  formatTime,
} from '../../../../utils/dateFunctions';

interface Props {
  children?: React.ReactNode;
  datetime: Date;
  title: string;
  message?: string;
  variant: NotificationVariants;
  onClick: () => void;
}

type NotificationVariants =
  | 'alert'
  | 'error'
  | 'info'
  | 'answer-quotation'
  | 'quotation'
  | 'vehicle';

export const Root = ({
  children,
  datetime,
  message,
  title,
  variant,
  onClick,
}: Props) => {
  const handleVariantIcon = (value: NotificationVariants) => {
    switch (value) {
      case 'alert':
        return <Warning size={48} />;
      case 'vehicle':
        return <MapPin size={48} />;
      case 'error':
        return <XCircle size={48} />;
      case 'info':
        return <Bell size={48} />;
      case 'answer-quotation':
        return <ChatCircleDots size={48} weight="fill" color="#43A046" />;
      case 'quotation':
        return <Envelope size={48} weight="duotone" color="#2194F3" />;
      default:
        return <Warning size={48} />;
    }
  };
  const handleDateTime = (value: Date) => {
    const newDate = new Date(value);
    const formattedDate = formatDate(dateOffset(newDate));
    const formattedTime = formatTime(dateOffset(newDate));
    return `${formattedDate} às ${formattedTime}`;
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={`${styles.container} ${styles[variant]}`} onClick={onClick}>
      <div className={styles.wrapper}>
        <div className={styles['icon-wrapper']}>
          {handleVariantIcon(variant)}
        </div>
        <div className={styles['notification-wrapper']}>
          <h1 className={styles.title}>{title}</h1>
          {message && <p className={styles.message}>{message}</p>}
        </div>
        {children}
      </div>
      <span className={styles['date-time']}>{handleDateTime(datetime)}</span>
    </div>
  );
};
