import { AlertMessage } from '../../../../../../../../shared/AlertMessage';
import { Quotation } from '../../../../../../../../@types/interface';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onConfirm: () => void;
  quotation?: Quotation | null;
}

export const RedoQuotationModal = ({
  isOpen,
  onClose,
  isLoading = false,
  onConfirm,
  quotation = null,
}: Props) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      title="Refazer Cotação?"
      footer={
        <AlertMessage.Footer>
          {!isLoading && (
            <>
              <AlertMessage.CancelButton onClick={onClose}>
                Revisar
              </AlertMessage.CancelButton>
              <AlertMessage.ConfirmButton onClick={onConfirm}>
                Refazer Cotação
              </AlertMessage.ConfirmButton>
            </>
          )}
        </AlertMessage.Footer>
      }
    >
      <AlertMessage.Body>
        <p>
          Ao refazer a cotação{' '}
          <b>{quotation && `N° ${quotation?.QUOTATION ?? ''} `}</b> que consta
          em aberto será finalizada automaticamente, deseja continuar?
        </p>
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
