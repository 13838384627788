import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createQuotationController } from '../../../../controller';
import { addNotAnsweredQuotations } from '../../../../../../../../features/quotation/quotation-reply-slice';
import {
  getAssignedUrl,
  GetAssignedUrlResponse,
  sendBucketImage,
} from '../../../../../../../../services/api/bucket';

export interface Quote {
  description: string;
  reference: string;
  quantity: string;
}

interface CreateQuotationProps {
  items: Quote[];
  vehicleId: string;
  comment: string;
  imagesList: File[];
  hours?: string;
  type_payment?: string;
  images_url?: string[];
}

export interface AssignedUrlWithImage extends GetAssignedUrlResponse {
  file: File;
}

export const useCreateQuotation = () => {
  const [isCreating, setIsCreating] = useState(false);
  const dispatch = useDispatch();

  const createQuotation = async ({
    comment,
    items,
    vehicleId,
    imagesList,
    hours,
    type_payment,
  }: CreateQuotationProps) => {
    setIsCreating(true);

    const assignedImagesPromises = imagesList.map(async image => {
      const res = await getAssignedUrl(image.name, 'quotation');
      if (res) {
        return { ...res, file: image };
      }
    });
    const assignedImages = (await Promise.all(assignedImagesPromises)).filter(
      Boolean,
    ) as AssignedUrlWithImage[];

    const uploadPromises = assignedImages.map(assignedImage =>
      sendBucketImage(assignedImage.file, assignedImage.url),
    );

    await Promise.all(uploadPromises);

    const images_url = assignedImages.map(image => image.image_url);

    const createQuotationRequestBody: CreateQuotationProps = {
      items,
      vehicleId,
      comment: comment ?? '',
      imagesList,
      images_url,
    };
    if (hours) createQuotationRequestBody.hours = hours;
    if (type_payment) createQuotationRequestBody.type_payment = type_payment;

    const res = await createQuotationController({
      ...createQuotationRequestBody,
    });

    if (res) {
      dispatch(addNotAnsweredQuotations([res]));
    }
    setIsCreating(false);
    return res;
  };
  return {
    createQuotation,
    isCreating,
  };
};
